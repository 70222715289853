import { EditableColumnProps } from '@/component/Table/EditableTable';
import { SysLocale } from '@/service/system/SysLocaleService';

const columns: EditableColumnProps<SysLocale>[] = [
  {
    label: '语言',
    code: 'locale.lang',
    name: 'lang',
    sorter: false,
    ellipsis: true,
    editable: true,
    query: true,
    editType: 'radio',
    editOption: { type: 'locale.lang' },
    formatter: 'select',
    formatOption: { type: 'locale.lang' },
    editRule: { required: true, maxLength: 20 },
    align: 'center',
    width: 100
  },
  {
    label: '编码',
    code: 'locale.code',
    name: 'code',
    sorter: false,
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editOption: {},
    formatOption: { trim: true },
    editRule: { required: true, maxLength: 50 }
  },
  {
    label: '名称',
    code: 'locale.name',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    formatOption: { trim: true },
    editOption: {},
    editRule: { required: true, maxLength: 100 }
  },
  {
    label: '内容',
    code: 'locale.value',
    ellipsis: true,
    editable: true,
    name: 'value',
    width: 500,
    editOption: {},
    editRule: { required: true, maxLength: 100 }
  }
];

export default columns;
