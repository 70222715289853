import React from 'react';
import UserUtil from '@/util/UserUtil';

declare interface PermissionProps {
  authorities: string;
  children: any;
}

const Permission = ({ authorities, children }: PermissionProps) => (
  <>{!UserUtil.hasAnyRole(...authorities.split(',')) ? <></> : children}</>
);

export default Permission;
