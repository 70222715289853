import { TabsAction, TabsProps } from '@/util/TabUtil';
import { LocaleAction, SwitchAction } from '../actions/action';
import LocalStorageUtil from '../../util/LocalStorageUtil';

export const locale = (state = 'zh-CN', action: LocaleAction) => (action.type === 'SET_LOCALE' ? action.locale : state);

const tabState: { activeKey?: string; list: TabsProps[] } = {
  activeKey: undefined,
  list: []
};
export const tabs = (state: TabsProps, { type, data }: TabsAction) => {
  if (data) {
    const { list } = tabState;
    const i = list.findIndex(({ id }) => id === data.id);

    switch (type) {
      case 'TAB_ADD':
        if (i === -1) {
          list.push(data);
          tabState.activeKey = data.id;
        } else {
          tabState.activeKey = list[i].id;
        }

        break;
      case 'TAB_DEL':
        list.splice(i, 1);
        if (data.id === tabState.activeKey) {
          const key = list.map(t => t.id).find((tab, n) => i === n);
          tabState.activeKey = key || list.map(t => t.id).find((tab, n) => i - 1 === n);
        }
        break;
      case 'TAB_SWITCH':
        tabState.activeKey = data.id;
    }
  }

  LocalStorageUtil.setItem('tabState', tabState);
  return { ...tabState };
};

export const fade = (state: TabsProps, { fade = 1 }: SwitchAction) => fade;
