import BaseService, { BaseEntity, BaseQuery } from '../base/BaseService';
import { API_URL } from '../url';

export interface SysDict extends Required<Pick<BaseEntity<SysDict>, 'id' | 'sort'>> {
  type: string;
  value: string;
  code: string;
  name: string;
  external1: string;
  external1Desc: string;
  external2: string;
  external2Desc: string;
}

export type SysDictQuery = Partial<SysDict & BaseQuery>;

class SysDictService extends BaseService<SysDict, SysDictQuery> {}

const dictService = new SysDictService(API_URL, 'sys-dict');

export default dictService;
