import { Key } from 'react';
import SelectUtil from '@/util/SelectUtil';
import { TagOutlined, TeamOutlined } from '@ant-design/icons/lib';
import BaseService, { BaseEntity, BaseQuery, PageBean, ResultBean, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';

export interface SysOffice extends BaseEntity<SysOffice> {
  name: string;
  code: string;
  icon: string;
  href: string;
  key: string;
  permission: string;
  type: number;
  status: boolean;
  areaId: string;
  areaName: string;
  proprietor: boolean;
}

export declare type SysOfficeQuery = Partial<SysOffice & BaseQuery & { department: boolean }>;

export declare interface OfficeSelectOption extends SelectOption<SysOffice, SysOfficeQuery> {
  department?: boolean;
}

class SysOfficeService extends BaseService<SysOffice, SysOfficeQuery> {
  findTreePage = (query?: SysOfficeQuery): Promise<ResultBean<PageBean<SysOffice>>> =>
    this.request('post', 'find-tree-page', query);

  selectProprietor = async (department = false, checkedKeys?: Key[]) =>
    this.select({ department, query: { proprietor: true }, checkedKeys });

  select = ({ department = false, validator, query = {}, checkedKeys }: OfficeSelectOption = {}) =>
    SelectUtil.pop({
      name: department ? '部门' : '公司',
      loadTree: () => this.findTreeData({ ...query, department }),
      checkedKeys,
      validator: office => {
        if (office.type !== 1 && department) {
          throw new Error(`只能选择${department ? '部门' : '公司'}`);
        }

        validator?.(office);

        return office;
      },
      icon: (office: SysOffice) => (office.type === 1 ? <TagOutlined /> : <TeamOutlined />)
    });
}

export default new SysOfficeService(API_URL, 'sys-office');
