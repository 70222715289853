import { ContractItem } from '@/service/repository/ContractItemService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import ProjectService from '@/service/repository/ProjectService';
import { TagFormat } from '@/util/FormatUtil';

const columns: EditableColumnProps<ContractItem>[] = [
  {
    label: '项目',
    name: 'projectId',
    ellipsis: true,
    editable: true,
    query: true,
    hidden: true,
    width: 200,
    span: 8,
    editType: 'pop-select',
    editOption: {
      prefix: 'project',
      popup: ProjectService.select,
      itemStyle: { width: '200px' }
    }
  },
  {
    label: '编号',
    name: 'no',
    code: 'no',
    ellipsis: true,
    editable: true,
    fixed: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '名称',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    fixed: true,
    width: 300,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '类型',
    name: 'type',
    code: 'type',
    ellipsis: true,
    editable: true,
    query: true,
    fixed: true,
    width: 80,
    align: 'center',
    formatter: 'select',
    formatOption: { type: 'contract.item.type' },
    editType: 'select',
    editOption: { type: 'contract.item.type' },
    editRule: { maxLength: 50 }
  },

  {
    label: '概算金额',
    name: 'estimateAmount',
    code: 'estimate.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '金额',
    name: 'amount',
    code: 'amount',
    ellipsis: true,
    editable: false,
    width: 140,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    align: 'right',
    editOption: {},
    editRule: {}
  },
  {
    label: '合同金额',
    name: 'contractAmount',
    code: 'contract.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '预算金额',
    name: 'budgetAmount',
    code: 'budget.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '执行金额',
    name: 'executeAmount',
    code: 'execute.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '材料价差',
    name: 'materialMarginAmount',
    code: 'material.margin.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '电费价差',
    name: 'electricMarginAmount',
    code: 'electric.margin.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '税费',
    name: 'taxAmount',
    code: 'tax.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '总金额',
    name: 'totalAmount',
    code: 'total.amount',
    ellipsis: true,
    editable: true,
    width: 140,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '执行部门',
    name: 'executorName',
    code: 'executor.name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '施工单位',
    name: 'devCompanyName',
    code: 'dev.company',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '状态',
    name: 'status',
    code: 'status',
    ellipsis: true,
    editable: true,
    query: true,
    formatter: 'select',
    formatOption: { type: 'contract.status' },
    render: (value, record) => (record.type === 2 ? TagFormat(value, 'contract.status') : ''),
    align: 'center',
    width: 100,
    editType: 'select',
    editOption: { type: 'contract.status' },
    editRule: { maxLength: 50 }
  },
  {
    label: '签约日期',
    name: 'signDate',
    code: 'sign.date',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '招标类型',
    name: 'bidType',
    code: 'bid.type',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    align: 'center',
    formatter: 'select',
    formatOption: { type: 'contract.bid.type' },
    editType: 'radio',
    editOption: { type: 'contract.bid.type', span: 21 },
    editRule: { maxLength: 50 }
  },
  {
    label: '招标模式',
    name: 'bidMode',
    code: 'bid.mode',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    formatter: 'select',
    formatOption: { type: 'contract.bid.mode' },
    editType: 'radio',
    editOption: { type: 'contract.bid.mode', span: 21 },
    align: 'center',
    editRule: { maxLength: 50 }
  },
  {
    label: '批复类型',
    name: 'qualifyType',
    code: 'qualify.type',
    ellipsis: true,
    editable: true,
    query: false,
    width: 100,
    formatOption: { type: 'contract.qualify.type' },
    align: 'center',
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '开始日期',
    name: 'startDate',
    code: 'start.date',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    editType: 'date',
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '结束日期',
    name: 'endDate',
    code: 'end.date',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    editType: 'date',
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '备注',
    name: 'remarks',
    code: 'remark',
    ellipsis: true,
    editable: true,
    query: false,
    width: 100,
    editOption: {},
    editRule: { maxLength: 50 }
  }
];

export default columns;
