import React, { memo, MutableRefObject } from 'react';
import { Cascader, Form } from 'antd';
import LocaleUtil from '@/util/LocaleUtil';
import { FormInstance } from 'antd/es/form';
import { BaseEntity } from '@/service/base/BaseService';
import WrappedTreeSelect from '@/component/Form/component/WrappedTreeSelect';
import WrappedTable from '@/component/Form/component/WrappedTable/WrappedTable';
import WrappedDateRange from '@/component/Form/component/WrappedDateRange';
import WrappedImage from '@/component/Form/component/WrappedImage';
import WrappedTime from '@/component/Form/component/WrappedTime';
import WrappedUpload from '@/component/Form/component/WrappedUpload';
import WrappedTimeRange from '@/component/Form/component/WrappedTimeRange';
import WrappedCascader from '@/component/Form/component/WrappedCascader';
import WrappedAssignee from '@/component/Form/component/WrappedAssignee';
import WrappedPopSelect from './component/WrappedPopSelect';
import WrappedSelect from './component/WrappedSelect';
import WrappedRadio from './component/WrappedRadio';
import WrappedSwitch from './component/WrappedSwitch';
import WrappedText from './component/WrappedText';
import WrappedTextArea from './component/WrappedTextArea';
import { EditableColumnProps } from '../Table/EditableTable';
import validator from './validator';
import WrappedDate from './component/WrappedDate';
import WrappedLabel from './component/WrappedLabel';
import WrappedCheckbox from './component/WrappedCheckbox';
import TableUtil from '../Table/Util/TableUtil';

export declare interface WrappedFormItemProps<T extends BaseEntity<T>> {
  column: EditableColumnProps<T>;
  value?: any;
  onChange?: (value: string, other?: any) => void;
  onFieldChange?: (record: Record<string, unknown>) => void;
  onFocus?: () => void;
  record?: T;
  defaultValue?: string;
  formRef: React.Ref<FormInstance> | MutableRefObject<FormInstance | undefined>;
  util?: TableUtil<T>;
  id?: string;
}

const items: Record<string, any> = {
  'pop-select': WrappedPopSelect,
  select: WrappedSelect,
  'tree-select': WrappedTreeSelect,
  radio: WrappedRadio,
  switch: WrappedSwitch,
  text: WrappedText,
  search: WrappedText,
  textarea: WrappedTextArea,
  date: WrappedDate,
  'date-range': WrappedDateRange,
  time: WrappedTime,
  'time-range': WrappedTimeRange,
  label: WrappedLabel,
  checkbox: WrappedCheckbox,
  cascader: WrappedCascader,
  table: WrappedTable,
  assignee: WrappedAssignee,
  upload: WrappedUpload,
  image: WrappedImage
};

const WrappedFormItem: React.FC<WrappedFormItemProps<any>> = ({
  onChange,
  formRef,
  util,
  record,
  ...rest
}: WrappedFormItemProps<any>) => {
  const { editType = 'text', editOption = {}, editRule = {}, name, code, label } = rest.column;
  const prevValue = record?.[name];
  const mapProps = {
    formRef,
    onChange: (currentValue: string, other: any) => {
      editOption.onChange?.(
        currentValue,
        prevValue,
        formRef as React.RefObject<FormInstance>,
        record,
        rest.column,
        other
      );
      onChange?.(currentValue);
    },
    record,
    ...rest
  };

  const { hideLabel, render, span, type } = editOption;
  const { validators = [] } = editRule;
  const WrappedCtrl = items[editType];
  const nonLabel = hideLabel;
  let labelColSpan = span !== undefined ? 24 - span : 8;
  let wrapperColSpan = span !== undefined ? span : 16;
  if (hideLabel) {
    labelColSpan = 0;
    wrapperColSpan = 24;
  }

  const layout = hideLabel
    ? {}
    : {
        labelCol: { span: labelColSpan },
        wrapperCol: { span: wrapperColSpan }
      };

  return (
    <Form.Item
      {...layout}
      name={name}
      hidden={type === 'hidden'}
      validateDebounce={500}
      colon={false}
      rules={validators.map(validator => ({ validator })).concat(validator.create(rest.column) as any) as any[]}
      label={nonLabel ? undefined : (LocaleUtil.get(code, label) as any)}
    >
      {editType === 'custom' ? (
        render?.(Object.assign(mapProps, { value: prevValue }) as any, record)
      ) : (
        <WrappedCtrl {...mapProps} />
      )}
    </Form.Item>
  );
};

export default memo(WrappedFormItem);
