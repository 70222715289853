import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import { isNull, USE_LOCALE } from '@/common/common';
import { useMount } from 'ahooks';
import { SelectOptionType } from '@/component/PopSelect/PopSelect';
import { WrappedFormItemProps } from '../WrappedFormItem';
import DictUtil from '../../../util/DictUtil';
import LocaleUtil from '../../../util/LocaleUtil';

const WrappedSelect = (props: WrappedFormItemProps<any>) => {
  const { value, column, onChange } = props;
  const { code, label, editOption } = column;
  const { type, getOptions, disabled, hidePlaceHolder } = editOption;
  const [list, setList] = useState<SelectOptionType[]>(DictUtil.get(type));
  const mapProps = useMemo(
    () => ({
      placeholder: hidePlaceHolder ? undefined : LocaleUtil.get(code, label),
      value: !isNull(value) ? value.toString() : undefined,
      onChange,
      disabled
    }),
    [hidePlaceHolder, code, label, value, onChange, disabled]
  );
  useMount(async () => {
    const loader = getOptions!;
    if (loader) {
      setList((await loader({})) || []);
    }
  });

  return (
    <Select {...mapProps} allowClear>
      {list.map(({ value, name, code }) => (
        <Select.Option key={value} value={value}>
          {code && USE_LOCALE ? LocaleUtil.get(code, name) : name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default WrappedSelect;
