import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import MixService, { Mix } from '@/service/repository/MixService';
import { EditableTable, WrappedForm } from '@/component';
import { ResultBean } from '@/service/base/BaseService';
import MixDetailService, { MixDetail } from '@/service/repository/MixDetailService';
import mixDetailCols from '../mixDetail/columns';
import columns from './columns';

export declare interface MixFormProps {
  record: Mix;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export default class UserForm extends React.PureComponent<MixFormProps, any> {
  formRef = React.createRef<FormInstance>();

  tableRef = React.createRef<EditableTable<MixDetail>>();

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await MixService.saveBy({
      mix: Object.assign(record, data),
      detailList: await this.tableRef.current?.util.serialize()
    });

    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;

    const props = {
      columns,
      record,
      span: 12,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };
    return (
      <>
        <WrappedForm {...props} />
        <EditableTable<MixDetail>
          ref={this.tableRef}
          service={MixDetailService}
          autoSave={false}
          columns={mixDetailCols}
          isEdit
          isTree={false}
          query={record.isNewRecord ? {} : { mixId: record.id }}
          nonPagination
          toolbar={{
            isSave: false,
            isReload: !record.isNewRecord
          }}
          loadData={MixDetailService.findListPage}
        />
      </>
    );
  }
}
