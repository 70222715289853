import SelectUtil from '@/util/SelectUtil';
import BaseService, { BaseEntity, BaseQuery, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';

export interface Estimate extends BaseEntity<Estimate> {
  projectId: string;
  projectName: string;
  no: string;
  name: string;
  amount: number;
  budgetAmount: string;
}

export declare type EstimateQuery = Partial<Estimate & BaseQuery>;

class EstimateService extends BaseService<Estimate, EstimateQuery> {
  select = ({ name = '概算', validator, query }: SelectOption<Estimate, EstimateQuery> = {}) =>
    SelectUtil.pop({ name, loadTree: this.findTreeData, validator, query });
}

export default new EstimateService(API_URL, 'repository/estimate');
