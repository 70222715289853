import { loadTreeDataWrapper } from '@/common/common';
import SelectUtil, { SelectOption } from '@/util/SelectUtil';
import SysAttachmentService from '@/service/system/SysAttachmentService';
import FileUtil from '@/util/FileUtil';
import { API_URL } from '../url';
import BaseService, { BaseEntity, BaseQuery, PageBean, ResultBean } from '../base/BaseService';

export interface SysUser extends BaseEntity<SysUser> {
  companyId: string;
  companyName: string;
  officeId: string;
  officeName: string;
  name: string;
  code: string;
  icon: string;
  signature: string;
  photo: string;
  permission: string;
  status: boolean;
  isUser: boolean;
  isAllUser: boolean;
}

export declare type SysUserQuery = Partial<SysUser & BaseQuery>;

export declare interface UserPasswordDTO {
  loginName?: string;
  oldPassword: string;
  newPassword: string;
}

type UserSelectOption = Partial<Omit<SelectOption<SysUser, SysUserQuery>, 'loadTree'>>;

class SysUserService extends BaseService<SysUser, SysUserQuery> {
  findProprietorTreeData = loadTreeDataWrapper(() =>
    this.request<ResultBean<PageBean<SysUser>>>('get', 'find-proprietor-tree')
  );

  select = ({ name = '用户', checkCascade, ...rest }: UserSelectOption = { name: '用户' }) =>
    SelectUtil.pop({
      name,
      loadTree: this.findTreeData,
      validator: (data: SysUser) => {
        if (!checkCascade && !data.isUser) {
          throw new Error('请选择用户');
        }
      },
      checkCascade,
      ...rest
    });

  proprietorSelect = ({ name = '用户', checkCascade, ...rest }: UserSelectOption = { name: '用户' }) =>
    SelectUtil.pop({
      name,
      loadTree: this.findProprietorTreeData,
      validator: user => {
        if (!checkCascade && !user.isUser) {
          throw new Error('请选择用户');
        }
      },
      ...rest
    });

  passwordCheck = (password: string) => this.request<ResultBean<boolean>>('get', `password/check?password=${password}`);

  passwordReset = (id: string) => this.request<ResultBean<boolean>>('get', `password/reset?id=${id}`);

  passwordChange = (userPasswordDTO: UserPasswordDTO) =>
    this.request<ResultBean<boolean>>('post', 'password/change', userPasswordDTO);

  getSignature = async (id: string) => {
    const { code, data } = await this.request<ResultBean<SysUser>>('get', id);
    if (code === 200 && data?.signature) {
      return SysAttachmentService.getArrayBuffer(data?.signature);
    }

    return { code: -1, msg: '失败' } as any;
  };

  getTemplate = () => FileUtil.download(this.getUrl('export/template'), 'get', '用户导入模板.xlsx');
}

export default new SysUserService(API_URL, 'sys-user');
