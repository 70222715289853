import React from 'react';
import { FormInstance } from 'antd/lib/form';
import UserService, { SysUser, UserPasswordDTO } from '@/service/system/SysUserService';
import { message } from 'antd';
import SystemService from '@/service/system/SystemService';
import columns from '@/view/system/user/model/passwordColumns';
import { WrappedForm } from '@/component';
import { cloneDeep, debounce } from 'lodash-es';
import { ResultBean } from '@/service/base/BaseService';

export declare interface PasswordProps {
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export declare interface PasswordState {
  user: SysUser | null;
}

export default class Password extends React.PureComponent<PasswordProps, PasswordState> {
  state = {
    user: null
  };

  formRef = React.createRef<FormInstance>();

  columns = cloneDeep(columns);

  constructor(props: any) {
    super(props);

    for (const column of this.columns.values()) {
      if (!column.editRule) {
        column.editRule = {};
      }

      switch (column.name) {
        case 'oldPassword':
          column.editRule.validators = [this.passwordCheck];
          break;
        case 'newPassword':
        case 'newPassword1':
          column.editRule.validators = [this.validator];
          break;
        default:
      }
    }
  }

  equal = false;

  passwordCheck = debounce(async (a: any, password: string) => {
    const { code, msg } = await UserService.passwordCheck(password);
    return code !== 200 ? Promise.reject(msg) : undefined;
  }, 1000);

  validator = () => {
    const newPassword = this.formRef.current?.getFieldValue('newPassword');
    const newPassword1 = this.formRef.current?.getFieldValue('newPassword1');
    const equal = newPassword === newPassword1;
    if (equal && !this.equal) {
      this.equal = equal;
      this.formRef.current?.validateFields(['newPassword', 'newPassword1']);
    } else {
      this.equal = equal;
    }

    return !equal ? Promise.reject(new Error('两次输入的密码不一致')) : Promise.resolve();
  };

  save = async () => {
    const { user } = this.state;
    const currentUser = await this.formRef.current?.validateFields();
    const bean = await UserService.passwordChange(Object.assign(user, currentUser) as UserPasswordDTO);

    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  async componentDidMount() {
    const { setHandler } = this.props;
    const { code, msg, data } = await SystemService.getCurrentUser();
    if (code !== 200) {
      return message.error(msg);
    }

    this.setState({ user: data });
    setHandler?.(this.save);
  }

  render() {
    const { user } = this.state;

    const props = {
      columns: this.columns,
      record: user,
      span: 24,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '30px 20px', width: '400px' }
    };

    return user === null ? <></> : <WrappedForm {...props} />;
  }
}
