import React from 'react';
import { message } from 'antd';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { AlertOutlined } from '@ant-design/icons';
import StockDetailAlertForm from '@/view/material/stockDetail/AlertForm';
import StockDetailService, { StockDetail, StockDetailQuery } from '../../../service/material/StockDetailService';
import columns from './model/columns';
import { Dialog, EditableTable, SearchBar, Toolbar } from '../../../component';
import TableUtil from '../../../component/Table/Util/TableUtil';

export declare interface StockDetailListProps {
  onClick: (record: StockDetail, event: any) => void;
}

export declare interface StockDetailListState {
  query: Record<string, unknown>;
  record?: StockDetail;
  refresh: boolean;
}

export default class List extends React.PureComponent<StockDetailListProps, StockDetailListState> {
  tableRef = React.createRef<EditableTable<StockDetail>>();

  state = {
    query: {},
    refresh: false
  };

  alert = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要修改的数据');
    }

    Dialog.open({
      title: '应急物资预警设置',
      children: <StockDetailAlertForm record={record} />,
      width: 500,
      afterOk: this.getUtil().refresh
    });
  };

  getUtil = (): TableUtil<StockDetail> => this.tableRef.current?.util as TableUtil<StockDetail>;

  onQuery = (query: StockDetailQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <AlertOutlined />,
      permission: 'ROLE_PROPRIETOR_MATERIAL_MANAGER',
      onClick: this.alert,
      code: 'add',
      title: '预警设置'
    }
  ];

  render() {
    return (
      <>
        <SearchBar<StockDetail> formStyle={{ width: '800px' }} columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<StockDetail>
          ref={this.tableRef}
          service={StockDetailService}
          columns={columns}
          loadData={StockDetailService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
