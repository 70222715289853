import { Model } from '@/service/process/ModelService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<Model>[] = [
  {
    label: '名字',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '键值',
    name: 'key',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '分类',
    name: 'category',
    ellipsis: true,
    editable: true,
    formatter: 'select',
    formatOption: { type: 'model.category' },
    editType: 'radio',
    editOption: { type: 'model.category' },
    width: 120,
    editRule: { required: true },
    align: 'center'
  },
  {
    label: '描述',
    name: 'description',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: {}
  }
];

export default columns;
