import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import { ResultBean } from '@/service/base/BaseService';
import TemplateService, { Template } from '@/service/template/TemplateService';
import { EditableTable, WrappedForm } from '@/component';
import BalanceStyleService, { BalanceStyle } from '@/service/template/BalanceStyleService';
import columns from './columns';
import balanceStyleColumns from '../balanceStyle/columns';

export declare interface TemplateFormProps {
  record: Template;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export default class UserForm extends React.PureComponent<TemplateFormProps, any> {
  formRef = React.createRef<FormInstance>();

  tableRef = React.createRef<EditableTable<BalanceStyle>>();

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await TemplateService.saveBy({
      template: Object.assign(record, data),
      styleList: await this.tableRef.current?.util.serialize()
    });

    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;

    const props = {
      columns,
      record,
      span: 12,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return (
      <>
        <WrappedForm {...props} />
        <EditableTable<BalanceStyle>
          ref={this.tableRef}
          service={BalanceStyleService}
          autoSave={false}
          columns={balanceStyleColumns}
          isEdit
          isTree
          query={record.isNewRecord ? {} : { templateId: record.id }}
          beforeAdd={data => {
            data.editable = false;
          }}
          toolbar={{
            expandNum: 4,
            isMove: true,
            isShift: true,
            isSave: false
          }}
          loadData={BalanceStyleService.findTree}
        />
      </>
    );
  }
}
