import { EditableColumnProps } from '../../Table/EditableTable';
import validator from './index';

export default (column: EditableColumnProps<any>) => {
  const { required = false } = column.editRule || {};
  const { label } = column;
  return {
    required,
    message: validator.message('required', label)
  };
};
