import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import UserService, { SysUser } from '@/service/system/SysUserService';
import { ResultBean } from '@/service/base/BaseService';
import { castTo } from '@/common/common';
import columns from './model/columns';
import { WrappedForm } from '../../../component';

export declare interface UserFormProps {
  record: SysUser;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export default class UserForm extends React.PureComponent<UserFormProps, any> {
  formRef = React.createRef<FormInstance>();

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await UserService.save(castTo<SysUser>(Object.assign(record, data)));
    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;

    const props = {
      columns,
      record,
      span: 12,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
