import axios from 'axios';
import { message } from 'antd';
import { codeMessage, HTTP_STATUS } from '@/common/response';
import { refreshWebsite } from '@/common/common';
import AuthService, { authorization } from '../service/system/AuthService';

const excludeResources = {
  login: '/login',
  auth: '/oauth/token',
  locale: '/sys-locale/get-locale',
  dict: '/sys-dict/find-list',
  activiti: '/service/,/editor-app/,/diagram-viewer/',
  token: '/oauth/'
};

const match = (url: string) => {
  for (const value of Object.values(excludeResources)) {
    for (const prefix of value.split(',').values()) {
      if (url.indexOf(prefix) > 0) {
        return true;
      }
    }
  }

  return false;
};

async function geToken(url?: string) {
  return authorization.accessToken!;
}

axios.interceptors.request.use(
  async (config: any) => {
    const { url } = config;
    if (match(url)) {
      return config;
    }

    const token = await geToken(url);

    if (token) {
      const { headers } = config;
      headers.xtoken = `${token}`;
      return config;
    }

    return config;
  },
  (error: string) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    console.error(error);
    message.error(codeMessage[504]);
    Promise.reject(error);
  }
);
