import React from 'react';
import { DeleteOutlined, IdcardOutlined, PlusOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import UserService, { SysUser, SysUserQuery } from '@/service/system/SysUserService';
import RoleService, { SysRole } from '@/service/system/SysRoleService';
import UserRoleService from '@/service/system/SysUserRoleService';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import { castTo } from '@/common/common';
import SearchTree from '@/component/Tree/SearchTree';
import TableUtil from '@/component/Table/Util/TableUtil';
import { cloneDeep } from 'lodash-es';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { buildUrl } from '@/service/url';
import QuantityService from '@/service/repository/QuantityService';
import FileUpload from '@/view/component/FileUpload/FileUpload';
import UploadResultList, { UploadResultListProps } from '@/view/system/uploadResult/List';
import columns from './model/columns';
import UserForm from './Form';

export declare interface UserListState {
  query: Record<string, unknown>;
  record?: SysUser;
}

export default class List extends React.PureComponent<any, UserListState> {
  tableRef = React.createRef<EditableTable<SysUser>>();

  state = {
    query: {}
  };

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || {};
    this.edit('用户新增', { ...record, id: null } as any);
  };

  edit = (title: string, record: SysUser) => {
    Dialog.open({
      title,
      children: <UserForm record={cloneDeep(record)} />,
      width: 800,
      afterOk: this.tableRef.current?.util.refresh
    });
  };

  delete = async () => {
    const util = this.tableRef.current?.util;
    const record = util?.getSelected();
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('用户删除', '确定要删除数据吗', async () => {
      const bean = await UserService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await util?.reload();
      }

      return bean;
    });
  };

  reset = async () => {
    const util = this.tableRef.current?.util;
    const record = util?.getSelected()!;
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('用户重置', '确定要重置该用户的密码吗？', async () => {
      const bean = await UserService.passwordReset(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await util?.reload();
      }

      return bean;
    });
  };

  grant = async () => {
    const record = this.tableRef.current?.util.getSelected()!;
    if (!record) {
      return message.error('未选中要授权的用户');
    }

    const user: SysUser = castTo<SysUser>(record);
    const userRoleList = await UserRoleService.listByUserId(user.id);
    const roleIds = new Set<string>(userRoleList?.map(({ roleId }) => roleId));

    Dialog.open({
      title: `用户授权-${user.name}`,
      width: '400px',
      children: (
        <SearchTree
          checkable
          style={{ height: '600px', overflow: 'auto' }}
          loadTree={RoleService.findRoleList}
          defaultCheckedKeys={Array.from(roleIds)}
        />
      ),
      ok: async (roleList: SysRole[]) => {
        const bean = await UserRoleService.grant({ user, roleList });
        if (bean.code === 200) {
          message.success(bean.msg);
          await this.getUtil()?.reload();
        }
      }
    });
  };

  import = () => {
    const props: UploadResultListProps = {
      uploadURL: buildUrl('api', '/sys-user/import/users'),
      getTemplate: UserService.getTemplate,
      uploadKey: 'user'
    };

    Dialog.open({
      title: '用户导入',
      children: <UploadResultList {...props} />,
      width: 800,
      styles: { body: { height: '400px' } },
      afterOk: this.getUtil().refresh
    });
  };

  getUtil = (): TableUtil<SysUser> => this.tableRef.current?.util as TableUtil<SysUser>;

  onQuery = (query: SysUserQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <IdcardOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.grant,
      code: 'grant',
      title: '授权'
    },
    {
      type: 'primary',
      icon: <UploadOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.import,
      code: 'import',
      title: '导入'
    },
    {
      type: 'primary',
      icon: <ReloadOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.reset,
      code: 'reset',
      title: '重置'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    const { query } = this.state;

    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<SysUser>
          ref={this.tableRef}
          service={UserService}
          autoSave
          columns={columns}
          query={query}
          onDoubleClick={record => this.edit('用户修改', record)}
          loadData={UserService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
