import React, { ChangeEvent, createRef, useCallback, useState } from 'react';
import { Input } from 'antd';
import { useMount } from 'ahooks';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { WrappedFormItemProps } from '../WrappedFormItem';

const WrappedTextArea: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => {
  const ref = createRef<TextAreaRef>();
  const { value, column, onChange } = props;
  const { editOption } = column;
  const { itemStyle = {}, disabled } = editOption;
  const { height = '24px' } = itemStyle;
  const [currentHeight, setHeight] = useState<string>(height as string);
  const resize = useCallback(
    (scrollHeight: number, clientHeight: number) => {
      if (scrollHeight > clientHeight) {
        setHeight(`${scrollHeight}px`);
      }
    },
    [setHeight]
  );
  const onCurrentChange = useCallback(
    ({ target: { value, scrollHeight, clientHeight } }: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(value);
      resize(scrollHeight, clientHeight);
    },
    [onChange, resize]
  );

  useMount(() => {
    const textarea = ref.current?.resizableTextArea?.textArea!;
    const next = textarea?.scrollHeight!;
    if (next) {
      resize(textarea?.scrollHeight!, textarea?.clientHeight!);
    }
  });

  return (
    <div style={{ paddingTop: '2px', paddingBottom: '2px', flex: 1 }}>
      <Input.TextArea
        disabled={disabled}
        value={value}
        ref={ref}
        onChange={onCurrentChange}
        style={{ ...itemStyle, height: currentHeight }}
      />
    </div>
  );
};

export default WrappedTextArea;
