import React from 'react';
import { Radio } from 'antd';
import { config } from '@/service/system/SystemService';
import { getProperty } from '@/common/common';
import LocaleUtil from '@/util/LocaleUtil';
import WrappedButton from '@/component/Button/Button';
import styles from './Toolbar.module.css';
import { ToolbarGroupProps, ToolbarOptionItem, ToolbarOptionProps, ToolbarProps, ToolbarState } from './index';

const Option = ({ permission, ...rest }: ToolbarOptionProps) => <WrappedButton {...rest} />;
const Group = (props: ToolbarGroupProps) => <Radio.Group {...props} />;

export declare interface ToolbarExtendProps extends Partial<ToolbarProps> {
  items?: ToolbarOptionItem[];
}

export default class Toolbar extends React.PureComponent<ToolbarExtendProps, ToolbarState> {
  static Option = Option;

  static Group = Group;

  static GroupOption = Radio.Button;

  filter = () => {
    const { children, items } = this.props;
    let list: any = items?.map(({ code, title, ...rest }) => (
      <Toolbar.Option key={title} {...rest}>
        {LocaleUtil.get(code, title)}
      </Toolbar.Option>
    ));
    if (!list) {
      list = children instanceof Array ? children : [children];
    }

    return list?.filter((t: any) => {
      const { permission = true } = getProperty(t, 'props');
      const type: symbol = getProperty(t, 'type');
      if (type.toString() === 'Symbol(react.fragment)') {
        return false;
      }

      const isBoolean = typeof permission === 'boolean';
      return (
        config.authorities?.findIndex(t =>
          isBoolean || permission === undefined ? permission : (permission as any).split(',')?.includes(t)
        ) !== -1
      );
    });
  };

  render() {
    const children = this.filter();
    if (!children || children.length === 0) {
      return <></>;
    }

    const { style, className = '' } = this.props;
    return (
      <div className={`${styles.toolbarWrapper} ${className}`} style={style}>
        {children}
      </div>
    );
  }
}
