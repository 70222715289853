import React, { useCallback } from 'react';
import { message, Select, SelectProps } from 'antd';
import classNames from 'classnames';
import './PopSelect.less';
import { isNull } from '@/common/common';

export type SelectOptionType = { name: string; value: string; code?: string };
export type SelectValueType = any | any[] | undefined;
export type ValueType = string | string[] | undefined;
export type SelectedType = any | any[] | undefined;

export declare interface PopSelectProps
  extends Omit<SelectProps<string | string[]>, 'onChange' | 'onSelect' | 'options'> {
  options?: SelectOptionType[];
  onSelect: (data?: SelectedType) => any;
  popup?: (value?: SelectValueType) => Promise<SelectedType> | undefined;
  className?: string;
  placeholder: string;
  disabled?: boolean;
  value?: SelectValueType;
  onChange?: (value: SelectValueType) => void;
}

const PopSelect: React.FC<PopSelectProps> = ({
  className,
  popup,
  onSelect,
  value,
  options,
  ...rest
}: PopSelectProps) => {
  const onDropdownVisibleChange = useCallback(
    async (open: boolean) => {
      if (open) {
        try {
          const data = await popup?.(value);
          if (!isNull(data)) {
            onSelect?.(data);
          }
        } catch (e) {
          message.error(e.toString());
        }
      }
    },
    [popup, value, onSelect]
  );
  return (
    <Select
      className={classNames(className, 'pop-select')}
      popupClassName="pop-select-dropdown"
      allowClear
      onDropdownVisibleChange={onDropdownVisibleChange}
      value={value}
      {...rest}
    >
      {options?.map(({ name, value }) => (
        <Select.Option key={value} value={value}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PopSelect;
