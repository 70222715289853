import React, { useState } from 'react';
import { LockOutlined, UserOutlined, MobileOutlined } from '@ant-design/icons/lib';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import { WrappedText } from '@/component';
import Button from '@/component/Button/Button';
import MobileCode from '@/view/system/login/MobileCode';

const columns: EditableColumnProps<any>[] = [
  {
    name: 'mobile',
    label: '手机号码',
    editType: 'text',
    editable: true,
    span: 24,
    editOption: {
      prefix: <MobileOutlined />,
      hideLabel: true
    },
    editRule: { required: true, maxLength: 11, minLength: 11 }
  },
  {
    name: 'code',
    label: '验证码',
    editType: 'custom',
    editable: true,
    span: 24,
    formatter: 'number',
    formatOption: { precision: 0, separator: '' },
    editOption: {
      prefix: <LockOutlined />,
      itemStyle: { borderBottomRightRadius: 0, borderTopRightRadius: 0, borderRight: 0, height: '40px' },
      render: (props, ...rest) => <MobileCode {...props} />,
      hideLabel: true
    },
    editRule: { required: true, maxLength: 6, minLength: 6 }
  }
];

export default columns;
