import React, { useMemo, useState } from 'react';
import WrappedFormItem from '@/component/Form/WrappedFormItem';
import { FormInstance } from 'antd/es/form';
import { EditableColumnProps } from '@/component/Table/EditableTable';

export declare interface WrappedCellProps {
  column: EditableColumnProps<any>;
  onChange?: (indexedName: string, value: string) => void;
  formRef: React.RefObject<FormInstance>;
  rowNum: number;
  value: string;
}

const Cell: React.FC<WrappedCellProps> = ({ column, onChange, formRef, value }: WrappedCellProps) => {
  const { name, span } = column;
  const [val, setVal] = useState(value);
  const props = useMemo(
    () => ({
      formRef,
      column,
      onChange: (value: any) => {
        setVal(value);
        onChange?.(name, value);
      },
      value: val
    }),
    [formRef, column, onChange, name, val]
  );
  return (
    <td colSpan={span} key={name}>
      <WrappedFormItem {...props} />
    </td>
  );
};

export default Cell;
