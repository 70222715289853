import React from 'react';
import { Dialog } from '@/component';
import SearchTree, { SearchTreeProps } from '@/component/Tree/SearchTree';

export declare interface SelectOption<T, Q> extends SearchTreeProps {
  name: string;
  validator?: (obj: T) => void;
  query?: Q;
  loadTree: (query?: Q) => Promise<T[]>;
}

class SelectUtil {
  pop = <T, Q>({ query, name, validator, loadTree, checkable, ...rest }: SelectOption<T, Q> = {} as any) => {
    const props: SearchTreeProps = {
      type: checkable ? 'tree' : 'directory',
      isFilter: true,
      height: 480,
      checkable,
      loadTree: () => loadTree(query),
      ...rest
    };
    return new Promise<any>(resolve => {
      Dialog.open({
        title: `请选择${name}`,
        children: <SearchTree {...props} />,
        ok: data => {
          if (!data) {
            return new Error(`没有选择${name}`);
          }

          if (checkable) {
            (data! as T[]).forEach(t => validator?.(t));
          } else {
            validator?.(data as T);
          }

          return data;
        },
        afterOk: bean => resolve(bean?.data)
      });
    });
  };
}

export default new SelectUtil();
