import { MixDetail } from '@/service/repository/MixDetailService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import MaterialService from '@/service/material/MaterialService';

const columns: EditableColumnProps<MixDetail>[] = [
  {
    label: '材料',
    name: 'materialName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editType: 'pop-select',
    editOption: {
      prefix: 'material',
      popup: MaterialService.select
    },
    editRule: { required: true }
  },
  {
    label: '单位',
    name: 'unit',
    ellipsis: true,
    editable: true,
    query: true,
    width: 80,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '数量',
    name: 'num',
    ellipsis: true,
    editable: true,
    width: 100,
    formatter: 'number',
    align: 'right',
    editOption: {},
    editRule: {}
  },
  {
    label: '损耗系数1',
    name: 'lossFactor1',
    ellipsis: true,
    editable: true,
    width: 100,
    formatter: 'number',
    align: 'right',
    editOption: {},
    editRule: {}
  },
  {
    label: '损耗系数2',
    name: 'lossFactor2',
    ellipsis: true,
    editable: true,
    width: 100,
    formatter: 'number',
    align: 'right',
    editOption: {},
    editRule: {}
  }
];

export default columns;
