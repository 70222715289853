import SelectUtil from '@/util/SelectUtil';
import FileUtil from '@/util/FileUtil';
import BaseService, { BaseEntity, BaseQuery, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';

export interface ContractItem extends BaseEntity<ContractItem> {
  businessId: string;
  businessName: string;
  projectId: string;
  projectName: string;
  no: string;
  name: string;
  contractNo: string;
  type: number;
  category: number;
  amount: number;
  estimateAmount: number;
  contractAmount: number;
  budgetAmount: number;
  executeAmount: number;
  materialMarginAmount: number;
  electricMarginAmount: number;
  taxAmount: number;
  totalAmount: number;
  executorId: string;
  executorName: number;
  devCompanyId: string;
  devCompanyName: number;
  status: number;
  signDate: string;
  bidType: number;
  bidMode: number;
  qualifyType: number;
  startDate: string;
  endDate: string;
  remarks: string;
}

export declare type ContractItemQuery = Partial<
  ContractItem &
    BaseQuery & {
      usePrefix: boolean;
      contractAmountStart: number;
      contractAmountEnd: number;
      signDateStart: string;
      signDateEnd: string;
    }
>;

class ContractItemService extends BaseService<ContractItem, ContractItemQuery> {
  select = ({ name = '合同选择', validator, query = {} }: SelectOption<ContractItem, ContractItemQuery> = {}) =>
    SelectUtil.pop({ name, loadTree: this.findTreeData, validator, query });

  export = (fileName: string, query: ContractItemQuery = {}) =>
    FileUtil.download(this.getUrl('export'), 'post', fileName, query);
}

export default new ContractItemService(API_URL, 'repository/contract-item');
