import SelectUtil from '@/util/SelectUtil';
import BaseService, { BaseEntity, BaseQuery, ResultBean, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';
import { BalanceStyle } from './BalanceStyleService';

export interface Template extends BaseEntity<Template> {
  no: string;
  name: string;
  type: number;
  remarks: number;
}

export declare type TemplateQuery = Partial<Template & BaseQuery>;

export interface TemplateBatchBean {
  template: Template;
  styleList?: BalanceStyle[];
}

class TemplateService extends BaseService<Template, TemplateQuery> {
  saveBy = (bean: TemplateBatchBean) => this.request<ResultBean<any>>('post', 'save', bean);

  select = ({ query, name = '结算模板', validator }: SelectOption<Template, TemplateQuery> = {}) =>
    SelectUtil.pop({ name, loadTree: this.findTreeData, validator, query });
}

export default new TemplateService(API_URL, 'template/template');
