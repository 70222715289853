import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons/lib';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<any>[] = [
  {
    name: 'username',
    label: '用户名/手机号码',
    editType: 'text',
    editable: true,
    span: 24,
    editOption: {
      prefix: <UserOutlined />,
      hideLabel: true
    },
    editRule: { required: true, maxLength: 24, minLength: 2 }
  },
  {
    name: 'password',
    label: '密码',
    editType: 'text',
    editable: true,
    span: 24,
    editOption: {
      type: 'password',
      prefix: <LockOutlined />,
      hideLabel: true
    },
    editRule: { required: true, maxLength: 24, minLength: 3 }
  }
];

export default columns;
