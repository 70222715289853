import React, { memo, useState } from 'react';
import ResizeObserver, { SizeInfo } from 'rc-resize-observer';

const ColGroup = ({ spanPerLine }: { spanPerLine: number }) => {
  const [tdWidth, setTdWidth] = useState(-1);
  const list = new Array(spanPerLine);
  for (let i = 0; i < list.length; i++) {
    list[i] = i;
  }
  const onResize = ({ width }: SizeInfo) => {
    // if (tdWidth === -1) {
    //   console.log(width, 'width');
    //   setTdWidth(new Decimal(width).divToInt(spanPerLine).toNumber());
    // }
  };

  return (
    <ResizeObserver onResize={onResize}>
      <colgroup className="table-first-row" style={{ height: 0 }}>
        {new Array(spanPerLine).fill(1).map((_, i) => (
          <col
            key={list[i].toString()}
            style={{
              width: tdWidth === -1 ? `${((1 / spanPerLine) * 100).toFixed(4)}%` : `${tdWidth}px`,
              minWidth: tdWidth === -1 ? `${((1 / spanPerLine) * 100).toFixed(4)}%` : `${tdWidth}px`,
              maxWidth: tdWidth === -1 ? `${((1 / spanPerLine) * 100).toFixed(4)}%` : `${tdWidth}px`,
              padding: 0,
              border: 0
            }}
          />
        ))}
      </colgroup>
    </ResizeObserver>
  );
};
export default memo(ColGroup, ({ spanPerLine }, nextProps) => nextProps.spanPerLine === spanPerLine);
