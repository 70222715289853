import React from 'react';
import SysUserRoleService, { SysUserRole, SysUserRoleQuery } from '@/service/system/SysUserRoleService';
import { EditableTable, SearchBar } from '@/component';
import TableUtil from '@/component/Table/Util/TableUtil';
import columns from './columns';

export declare interface RoleListState {
  query: SysUserRoleQuery;
  record?: SysUserRole;
  refresh: boolean;
}

export default class List extends React.PureComponent<any, RoleListState> {
  tableRef = React.createRef<EditableTable<SysUserRole>>();

  getUtil = (): TableUtil<SysUserRole> => this.tableRef.current?.util as TableUtil<SysUserRole>;

  onQuery = (query: SysUserRoleQuery) => this.getUtil().reload(query);

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <EditableTable<SysUserRole>
          ref={this.tableRef}
          service={SysUserRoleService}
          autoSave
          columns={columns}
          loadData={SysUserRoleService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
