import React, { memo, RefObject } from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/es/form/Form';
import ColGroup from '@/component/Form/component/WrappedTable/ColGroup';
import { FormInstance } from 'antd/lib/form';
import WrappedFormItem from './WrappedFormItem';
import { EditableColumnProps } from '../Table/EditableTable';
import './WrappedForm.less';

export declare interface GridFormProps<T> extends FormProps {
  columns: EditableColumnProps<T>[][];
  fillSpans: number[];
  record?: T;
  mode?: 'simple' | 'normal';
  span?: number;
  ref?: RefObject<FormInstance>;
  spanPerLine: number;
}

interface ItemProps<T> {
  column: EditableColumnProps<T>;
  record: T;
  formRef: React.Ref<FormInstance>;
}
const Item: React.FC<ItemProps<any>> = ({ column, formRef, record }: ItemProps<any>) => (
  <td
    key={column.name}
    colSpan={column.span}
    style={{ padding: column.editType === 'table' ? 0 : undefined }}
    rowSpan={column.rowSpan}
  >
    <WrappedFormItem formRef={formRef} column={column} key={column.name} record={record} />
  </td>
);

interface ItemListProps<T> {
  columns: EditableColumnProps<T>[];
  record: T;
  formRef: React.Ref<FormInstance>;
  fillSpan: number;
}

const ItemList = ({ columns, formRef, record, fillSpan }: ItemListProps<any>) => (
  <tr>
    {columns.map(column => (
      <Item formRef={formRef} column={column} record={record} key={column.name} />
    ))}
    <FillSpan span={fillSpan} />
  </tr>
);

const FillSpan = ({ span }: { span: number }) => (
  <>
    {span > 0 ? (
      new Array(span)
        .fill(1)
        .map((_, n) => (
          <td key={n.toString()} style={{ borderLeft: 0, borderRightWidth: n === span - 1 ? '1px' : 0 }} />
        ))
    ) : (
      <></>
    )}
  </>
);

const GridForm: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<GridFormProps<any>> & React.RefAttributes<FormInstance>
> = React.forwardRef((props: GridFormProps<any>, ref: React.Ref<FormInstance>) => {
  const { columns, fillSpans, record = {}, span, spanPerLine = 24, labelWrap = true, ...rest } = props;

  return (
    <Form
      initialValues={record}
      onValuesChange={values => Object.assign(record, values)}
      labelWrap={labelWrap}
      ref={ref}
      {...rest}
    >
      <table style={{ width: '100%' }} className="wrapped-form-table">
        <ColGroup spanPerLine={spanPerLine} />
        <tbody>
          {columns.map((subList, i) => (
            <ItemList columns={subList} formRef={ref} record={record} key={i.toString()} fillSpan={fillSpans[i]} />
          ))}
        </tbody>
      </table>
    </Form>
  );
});

export default memo(GridForm);
