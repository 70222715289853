import React, { RefObject } from 'react';
import classNames from 'classnames';
import { FormInstance } from 'antd/es/form';
import { BaseEntity } from '@/service/base/BaseService';
import { getProperty } from '@/common/common';
import { cloneDeep } from 'lodash-es';
import { useMount } from 'ahooks';
import TableUtil from './Util/TableUtil';
import WrappedFormItem from '../Form/WrappedFormItem';
import { EditableColumnProps } from './EditableTable';

export declare interface EditableCellProps<T extends BaseEntity<T>> {
  util: TableUtil<T>;
  record: T;
  editing: boolean;
  column: EditableColumnProps<T>;
  index: number;
  className?: string;
  formRef: RefObject<FormInstance>;
  beforeCellEdit?: (record: T, column: EditableColumnProps<T>) => void;
}

interface InnerEditableCellProps extends EditableCellProps<any> {
  children: React.ReactNode;
}

const InnerEditableCell: (props: InnerEditableCellProps) => any = (props: InnerEditableCellProps) => {
  const { index, beforeCellEdit, children, editing, record, column, ...rest } = props;
  const copyColumn = cloneDeep(column);
  useMount(() => {
    beforeCellEdit?.(record, copyColumn);
  });

  if (!copyColumn.editOption) {
    copyColumn.editOption = { hideLabel: true };
  } else {
    copyColumn.editOption.hideLabel = true;
  }

  return copyColumn.editable
    ? [getProperty(children, 0), <WrappedFormItem key="next" column={copyColumn} record={record} {...rest} />]
    : children;
};

const EditableCell: React.FC<InnerEditableCellProps> = (props: InnerEditableCellProps) => {
  const { editing, beforeCellEdit, children, formRef, record, column, className = '', ...restProps } = props;
  const clzName = classNames({
    __table_index: column?.name === '__index',
    __table_cell: !!column?.name,
    __table_none_cell: column?.name === 'tableRight'
  });

  return (
    <td {...restProps} className={`${clzName} ${className}`}>
      {editing ? <InnerEditableCell {...props}>{children}</InnerEditableCell> : children}
    </td>
  );
};
export default EditableCell;
