import { Template } from '@/service/template/TemplateService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<Template>[] = [
  {
    label: '编号',
    name: 'no',
    code: 'no',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '名字',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 250,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '类型',
    name: 'type',
    code: 'type',
    ellipsis: true,
    editable: false,
    query: true,
    width: 100,
    formatter: 'select',
    formatOption: { type: 'template.type' },
    editType: 'select',
    editOption: { type: 'template.type' },
    editRule: { maxLength: 50 },
    align: 'center'
  },
  {
    label: '创建日期',
    name: 'createDate',
    code: 'create.date',
    ellipsis: true,
    editable: false,
    width: 150,
    align: 'right',
    editOption: {},
    editRule: {}
  },
  {
    label: '备注',
    name: 'remarks',
    code: 'remark',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: {}
  }
];

export default columns;
