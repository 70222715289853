import { EditableColumnProps } from '../../Table/EditableTable';
import validator from './index';

export default (column: EditableColumnProps<any>) => {
  const { maxLength = -1 } = column.editRule || {};
  const { label } = column;

  return {
    validator: (_: any, value: string) => {
      const mailReg = new RegExp('^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$');

      const msg = validator.message('mail', label, maxLength);
      return value && !mailReg.test(value) ? Promise.reject(msg) : Promise.resolve();
    }
  };
};
