import { Dictionary } from '@/common/types';
import { TaskExt } from '@/service/process/TaskExtService';
import React from 'react';
import { WrappedTextArea } from '@/component';

declare interface ChangeTaskNode {
  label: string;
  name: string;
  auditor?: { key: string; name: string };
  manager: { key: string; name: string };
}

export const taskNodeList: ChangeTaskNode[] = [
  {
    label: '监理单位审核',
    name: 'supervise',
    auditor: { key: 'supAuditor', name: '监理工程师' },
    manager: { key: 'supManager', name: '总监理' }
  },
  {
    label: '工程部审核',
    name: 'projectDepartment',
    auditor: { key: 'mgAuditor', name: '专业工程师' },
    manager: { key: 'mgManager', name: '负责人' }
  },
  {
    label: '合同部审核',
    name: 'contractDepartment',
    auditor: { key: 'ngAuditor', name: '专业工程师' },
    manager: { key: 'ngManager', name: '负责人' }
  },
  {
    label: '副总经理审批',
    name: 'ngPresident',
    manager: { key: 'ngPresident', name: '签字' }
  },
  {
    label: '副总经理审批',
    name: 'caPresident',
    manager: { key: 'caPresident', name: '签字' }
  },
  {
    label: '总经理审批',
    name: 'president',
    manager: { key: 'president', name: '签字' }
  },
  {
    label: '董事长审批',
    name: 'chairman',
    manager: { key: 'chairman', name: '签字' }
  }
];

declare interface CommentProps {
  node: ChangeTaskNode;
  historic: Dictionary<TaskExt>;
}

export default class Comment extends React.PureComponent<CommentProps, any> {
  renderLabel = ({ key = '', name = '' }) => {
    const { historic = {} } = this.props;
    return name === '' ? (
      <></>
    ) : (
      <>
        <span>{name}：</span>
        <span
          style={{
            width: '120px',
            display: 'inline-block'
          }}
        >
          {historic[key]?.assigneeName}
        </span>
      </>
    );
  };

  renderDate = () => (
    <>
      <span>日期：</span>
      <span
        style={{
          width: '120px',
          display: 'inline-block'
        }}
      >
        {this.getCurrentTask()?.endDate?.substr(0, 10) || ''}
      </span>
    </>
  );

  getCurrentTask = () => {
    const {
      node: { auditor, manager },
      historic = {}
    } = this.props;
    return manager ? historic[manager.key] : auditor && historic[auditor?.key];
  };

  render() {
    const {
      node: { auditor = {}, manager }
    } = this.props;
    const props = {
      value: this.getCurrentTask()?.comment,
      column: {
        editOption: {
          disabled: true,
          itemStyle: { height: '100px', resize: 'none' }
        }
      }
    } as any;

    return (
      <div style={{ flex: 1 }}>
        <WrappedTextArea {...props} />
        <div style={{ float: 'right', marginTop: '5px' }}>
          {this.renderLabel(auditor)}
          {this.renderLabel(manager)}
          {this.renderDate()}
        </div>
      </div>
    );
  }
}
