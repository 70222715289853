import { SysArea } from '@/service/system/SysAreaService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<SysArea>[] = [
  {
    label: '名称',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { required: true, maxLength: 20 }
  },
  {
    label: '类型',
    name: 'type',
    code: 'type',
    ellipsis: true,
    editable: true,
    formatter: 'select',
    editType: 'select',
    width: 100,
    formatOption: { type: 'area.type' },
    editOption: { type: 'area.type' },
    editRule: { maxLength: 20 },
    align: 'center'
  },
  {
    label: '编码',
    name: 'code',
    code: 'code',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '排序号',
    ellipsis: true,
    editable: true,
    name: 'sort',
    code: 'sort',
    editOption: {},
    width: 100,
    align: 'right'
  }
];

export default columns;
