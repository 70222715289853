import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import './App.less';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import systemInit from '@/common/system';
import 'dayjs/locale/zh-cn';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import store from '@/redux/store/configure';
import LocaleUtil from '@/util/LocaleUtil';
import App from '@/App';
import { themeToken } from '@/theme';
import * as serviceWorker from './serviceWorker';

// echarts 注册必须组件
echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, LineChart]);

// 系统初始化;
Promise.all([systemInit()])
  .catch(() => {
    console.log('初始化失败');
  })
  .finally(() => {
    console.log('APP INIT');
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <Provider store={store}>
        <ConfigProvider
          locale={LocaleUtil.getAntdLocale()}
          theme={{
            ...themeToken
          }}
        >
          <App />
        </ConfigProvider>
      </Provider>
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
