import React from 'react';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons/lib';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { cloneDeep } from 'lodash-es';
import UploadResultList, { UploadResultListProps } from '@/view/system/uploadResult/List';
import { buildUrl } from '@/service/url';
import MaterialService, { Material, MaterialQuery } from '../../../service/material/MaterialService';
import columns from './columns';
import { Dialog, EditableTable, SearchBar, Toolbar } from '../../../component';
import MaterialForm from './Form';
import TableUtil from '../../../component/Table/Util/TableUtil';

export declare interface MaterialListProps {
  onClick: (record: Material, event: any) => void;
}

export declare interface MaterialListState {
  query: Record<string, unknown>;
  record?: Material;
  refresh: boolean;
}

export default class List extends React.PureComponent<MaterialListProps, MaterialListState> {
  tableRef = React.createRef<EditableTable<Material>>();

  state = {
    query: {},
    refresh: false
  };

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || {};
    this.edit('物资新增', { ...record, id: null } as any);
  };

  edit = (title: string, record: Material) => {
    Dialog.open({
      title,
      children: <MaterialForm record={cloneDeep(record)} />,
      width: 600,
      afterOk: this.tableRef.current?.util.refresh
    });
  };

  import = () => {
    const props: UploadResultListProps = {
      uploadURL: buildUrl('api', '/material/material/upload'),
      getTemplate: MaterialService.getTemplate,
      uploadKey: 'material'
    };

    Dialog.open({
      title: '基础物资导入',
      children: <UploadResultList {...props} />,
      width: 800,
      footer: null,
      styles: { body: { height: '400px' } },
      afterOk: this.getUtil().refresh
    });
  };

  getUtil = (): TableUtil<Material> => this.tableRef.current?.util as TableUtil<Material>;

  onQuery = (query: MaterialQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_MATERIAL_MANAGER',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <UploadOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_MATERIAL_MANAGER',
      onClick: this.import,
      code: 'import',
      title: '导入'
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} formStyle={{ width: '600px' }} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<Material>
          ref={this.tableRef}
          service={MaterialService}
          autoSave
          columns={columns}
          loadData={MaterialService.findPage}
          onDoubleClick={record => this.edit('物资修改', record)}
          isTree={false}
        />
      </>
    );
  }
}
