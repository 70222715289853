import React, { memo, useMemo } from 'react';
import TabUtil, { TabsProps } from '@/util/TabUtil';
import LocaleUtil from '@/util/LocaleUtil';
import classNames from 'classnames';

export declare interface ContentProps {
  active: boolean;
  activeKey: string;
  tabProps: TabsProps;
  fade: number;
}

const ContentWrapper: React.FC<ContentProps> = ({ tabProps: { href, data }, active, fade }: ContentProps) => {
  const locale = active ? LocaleUtil.getLanguage() : '';
  const children = TabUtil.get(href, { data, active, locale });
  const classes = useMemo(
    () =>
      classNames({
        'content-wrapper': true,
        fadeInLeft: fade === 1,
        fadeOutRight: fade === 0
      }),
    [fade]
  );

  return (
    <div className={classes}>
      <div className="content-wrapper-inner">{children}</div>
    </div>
  );
};

export default memo(ContentWrapper, ({ active }, nextProps) => nextProps.active === active);
