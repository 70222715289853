import React, { useState } from 'react';
import './App.less';
import Login from './view/system/login/Login';
import Home from './view/system/home/Home';

const App = () => {
  const [login, setLogin] = useState<boolean>(false);

  return login ? <Home /> : <Login setLogin={setLogin} />;
};

export default App;
