import React, { CSSProperties, ReactNode } from 'react';
import BaseService, { BaseEntity, BaseQuery } from '@/service/base/BaseService';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import './TableToolBar.less';
import { message } from 'antd';
import LocaleUtil from '@/util/LocaleUtil';
import { getProperty } from '@/common/common';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EnterOutlined,
  FilterOutlined,
  MenuOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
  RollbackOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { cloneDeep } from 'lodash-es';
import TableUtil from '../Util/TableUtil';
import { Dialog, Toolbar } from '../../index';
import SearchTree from '../../Tree/SearchTree';

export declare interface TableToolbarProps<T extends BaseEntity<T>> {
  style?: CSSProperties;
  className?: string;
  util: TableUtil<T>;
  service: BaseService<T, BaseQuery>;
  query?: Partial<T & BaseQuery>;
  expandNum?: number;
  isMove?: boolean;
  isShift?: boolean;
  isEdit?: boolean;
  isSave?: boolean;
  isAdd?: boolean;
  isReload?: boolean;
  isDelete?: boolean;
  isFilter?: boolean;
  editOnly?: boolean;
  customAdd?: (util: TableUtil<T>) => void;
}

interface TableToolBarButtonProps {
  name: string;
  defaultName?: string;
  active?: boolean;
  icon?: ReactNode;
}

export default class TableToolbar<T extends BaseEntity<T>> extends React.PureComponent<TableToolbarProps<T>, any> {
  state = {
    value: null
  };

  onClick = (e: RadioChangeEvent) => {
    const key = e.target.value;
    const { util } = this.props;
    const action: (key: string) => void = getProperty(this.actions, key);
    if (action) {
      action(key);
    } else {
      const level = parseInt(key);
      if (!Number.isNaN(level)) {
        util.expandByLevel(level);
      } else {
        console.log(key);
      }
    }
  };

  actions = {
    save: async () => {
      const { service, util } = this.props;
      const list = await util.serialize();
      if (list.length === 0) {
        return message.error(LocaleUtil.get('data.none.notice', '没有可以保存的数据'));
      }

      Dialog.success(
        LocaleUtil.get('data.modify.text', '数据保存'),
        LocaleUtil.get('data.modify.notice', '确认要保存修改吗？'),
        async () => {
          const bean = await service.saveBatch({ query: util.getTable().state.query, list });
          if (bean.code === 200) {
            await util.reload();
            message.success(bean.msg);
          }

          return bean;
        }
      );
    },
    add: async () => {
      const { util, customAdd } = this.props;
      const func = customAdd!;
      if (func) {
        func(util);
      } else {
        await util.create({} as T, true);
      }
    },
    delete: () => {
      const { util, service } = this.props;
      const record = util.getSelected()!;
      if (!record) {
        return message.error(LocaleUtil.get('data.unselect.notice', '未选择要删除的数据'));
      }

      Dialog.error(
        LocaleUtil.get('data.delete.text', '数据删除'),
        LocaleUtil.get('data.delete.notice', '确定要删除选中得数据吗？'),
        async () => {
          let bean = { code: 200, msg: '' } as any;
          if (!record.isNewRecord) {
            bean = await service.delete(record.id);
            if (bean.code !== 200) {
              return;
            }
          }

          await util.remove(record, util.getTable().props.strictlyRemove);
          return bean;
        }
      );
    },
    'table.move.up': () => this.translate('moveUp'),
    'table.move.down': () => this.translate('moveDown'),
    'table.shift.up': () => this.translate('shiftUp'),
    'table.shift.down': () => this.translate('shiftDown'),
    filter: async () => {
      const table = this.props.util.getTable();
      const originList = cloneDeep(table.props.columns);
      const columns = cloneDeep(originList).map(row => {
        row.id = row.name;
        row.name = row.label;
        return row;
      });

      const checkedKeys = table.originColumns.map(({ name }) => name);
      let ids: (string | number)[] = [];
      Dialog.open({
        title: `显示筛选`,
        width: '400px',
        children: (
          <SearchTree
            checkable
            style={{ height: '600px', overflow: 'auto' }}
            loadTree={() => Promise.resolve(columns)}
            defaultCheckedKeys={checkedKeys}
            onSelect={(checkedNodes: T[]) => {
              ids = checkedNodes.map(({ id }) => id);
            }}
          />
        ),
        afterOk: async () => {
          table.originColumns = originList.filter(({ name }) => ids.includes(name)).map(table.preStatement);
          table.forceUpdate();
        }
      });
    },
    'show.all': () => {
      const table = this.props.util.getTable();
      const originList = cloneDeep(table.props.columns);
      table.originColumns = originList.filter(({ hidden = false }) => !hidden).map(table.preStatement);
      table.forceUpdate();
    },
    flush: async () => {
      const { util } = this.props;
      if (!util.getStore().isEmpty()) {
        Dialog.warn(
          LocaleUtil.get('data.reload', '数据刷新'),
          LocaleUtil.get('data.reload.notice', '重新加载数据将会清空所有正在编辑或者添加的数据，确定要刷新吗？'),
          async () => {
            await util.reload();
            return Promise.resolve({ code: 200, useTime: 0, msg: '', data: null });
          }
        );
      } else {
        await util.reload();
      }
    }
  };

  translate = async (action: string) => {
    const { service, util, query } = this.props;
    const list = util.getBatchList();
    if (list && list.length > 0) {
      return message.error(LocaleUtil.get('is.editing', '正在编辑...'));
    }

    const ids = util.getSelectedList()?.map(({ id }) => id)!;
    if (ids.length === 0) {
      return message.error(LocaleUtil.get('none.data', '未选择有数据'));
    }

    const { code } = await service.translate({ action, query: query as any, ids });
    if (code === 200) {
      await util.reload();
    }
  };

  renderGroup = (list: TableToolBarButtonProps[]) => {
    const { value } = this.state;
    return (
      <Toolbar.Group onChange={this.onClick} permission size="small" buttonStyle="solid" value={value}>
        {list.map(({ name, defaultName, active = false, icon }) => (
          <Toolbar.GroupOption key={name} value={name} className={active ? 'muyu-radio-btn-red' : ''}>
            {icon || LocaleUtil.get(name, defaultName)}
          </Toolbar.GroupOption>
        ))}
      </Toolbar.Group>
    );
  };

  renderExpandGroup = () => {
    const { expandNum = 0 } = this.props;
    const list = new Array(expandNum).fill(0);

    return expandNum > 0 ? (
      this.renderGroup(list.map((a, i) => ({ name: (i + 1).toString(), defaultName: (i + 1).toString() })))
    ) : (
      <></>
    );
  };

  renderMoveGroup = () => {
    const { isEdit = false, isMove = false } = this.props;
    return isMove && isEdit ? (
      this.renderGroup([
        { name: 'table.move.up', icon: <ArrowUpOutlined /> },
        { name: 'table.move.down', icon: <ArrowDownOutlined /> }
      ])
    ) : (
      <></>
    );
  };

  renderShiftGroup = () => {
    const { isEdit = false, isShift = false } = this.props;
    return isShift && isEdit ? (
      this.renderGroup([
        { name: 'table.shift.up', icon: <RollbackOutlined /> },
        { name: 'table.shift.down', icon: <EnterOutlined /> }
      ])
    ) : (
      <></>
    );
  };

  renderEditGroup = () => {
    const { util, isEdit, isSave = true, isAdd = true, isDelete = true, editOnly } = this.props;
    const list: TableToolBarButtonProps[] = [];
    if (isAdd) {
      list.push({
        name: 'add',
        icon: <PlusOutlined />,
        active: util.getBatchList().filter(t => t.action === 'create').length > 0 && !isSave
      });
    }

    if (isDelete) {
      list.push({
        name: 'delete',
        icon: <MinusOutlined />,
        active: util.getBatchList().filter(t => t.action === 'remove').length > 0 && !isSave
      });
    }

    if (isSave) {
      list.push({
        name: 'save',
        icon: <SaveOutlined />,
        active: util.getBatchList().length > 0
      });
    }
    return isEdit && !editOnly ? this.renderGroup(list) : <></>;
  };

  renderFilterGroup = () => {
    const { isFilter = false, util } = this.props;
    const list: TableToolBarButtonProps[] = [];
    if (isFilter) {
      list.push({
        name: 'filter',
        icon: <FilterOutlined />,
        active: util.getTable().state.names !== undefined
      });

      list.push({
        name: 'show.all',
        icon: <MenuOutlined />,
        active: false
      });
    }

    return isFilter ? this.renderGroup(list) : <></>;
  };

  renderLoadingGroup = () => {
    const { isReload = false } = this.props;
    return isReload ? this.renderGroup([{ name: 'flush', icon: <ReloadOutlined />, defaultName: '刷新' }]) : <></>;
  };

  render() {
    const { style } = this.props;
    return (
      <Toolbar style={style} className="muyu-table-toolbar">
        {this.renderExpandGroup()}
        {this.renderMoveGroup()}
        {this.renderShiftGroup()}
        {this.renderEditGroup()}
        {this.renderFilterGroup()}
        {this.renderLoadingGroup()}
      </Toolbar>
    );
  }
}
