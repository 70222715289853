import React from 'react';
import { TaskExt } from '@/service/process/TaskExtService';
import { WrappedFormItemProps } from '../WrappedFormItem';

export type Assignee = Pick<TaskExt, 'base64Image' | 'assigneeName' | 'endDate'>;
const WrappedAssignee: React.FC<WrappedFormItemProps<any>> = ({ value }: WrappedFormItemProps<any>) => {
  const assignee = value as Assignee;
  const alt = '#';
  return assignee && assignee.endDate ? (
    <div className="form-assignee">
      <div>
        {assignee.base64Image ? (
          <img className="assignee-image" src={assignee.base64Image} alt={alt} />
        ) : (
          <span className="assignee-name">{assignee.assigneeName}</span>
        )}
        <span className="assignee-date">{assignee.endDate?.substr(0, 10)}</span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default WrappedAssignee;
