import React, { CSSProperties } from 'react';
import { Worker } from '@/service/safe/WorkerService';
import WrappedTabs from '@/component/Tabs/WrappedTabs';
import WorkerForm from './Form';
import WorkerRecordList from '../workerRecord/List';
import MonthSignList from '../workerSign/MonthSignList';

export declare interface AccountProps {
  record: Worker;
}

export default class Account extends React.PureComponent<AccountProps, any> {
  paneStyle: CSSProperties = { height: '100%', minHeight: '600px' };

  record = this.props.record;

  items = [
    { name: '基础资料', key: 'worker', render: () => <WorkerForm record={this.record} /> },
    {
      name: '进出场记录',
      key: 'record',
      render: () => <WorkerRecordList userId={this.record.userId} />
    },
    {
      name: '打卡记录',
      key: 'sign',
      render: () => <MonthSignList userId={this.record.userId} />
    }
  ];

  render() {
    return <WrappedTabs items={this.items} paneStyle={this.paneStyle} />;
  }
}
