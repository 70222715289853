import { message } from 'antd';
import { refreshWebsite } from '@/common/common';

export const codeMessage: any = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
};

export enum HTTP_STATUS {
  OK = 200,
  MULTIPLE_CHOICES = 300,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND,
  NOT_ACCIPET = 406,
  METHOD_FAILURE = 420,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502
}
export const checkStatus = (response: any, quietly?: boolean) => {
  const status = response && (response.status || response.code);
  if (response.code && response.code !== HTTP_STATUS.OK && !quietly) {
    message.error(response.msg);
  }

  if ((status && status >= HTTP_STATUS.OK && status < HTTP_STATUS.MULTIPLE_CHOICES) || !status) {
    return response;
  }
  if (status && status === HTTP_STATUS.METHOD_FAILURE && response.msg && response.msg === '获取当前用户失败') {
    localStorage.setItem('waring', '登录超时,请重新登录');
    refreshWebsite();
    return;
  }

  return response;
};
