import { WrappedText } from '@/component';
import Button from '@/component/Button/Button';
import React, { useCallback, useState } from 'react';
import { WrappedFormItemProps } from '@/component/Form/WrappedFormItem';
import SystemService from '@/service/system/SystemService';
import { message } from 'antd';

const MobileCode = (props: WrappedFormItemProps<any>) => {
  const { formRef } = props;
  const [currentNum, setNum] = useState(0);
  const getCode = useCallback(async () => {
    let val = 0;
    const timer = setInterval(() => {
      if (val >= 60) {
        setNum(0);
        clearInterval(timer);
      } else {
        setNum(val++);
      }
    }, 1000);
    try {
      // 该校验无效，因为formRef 为空
      // const values = (formRef as any)?.current.validateFields(['mobile'], { validateOnly: true });
      // console.log(values, formRef);
      const bean = await SystemService.getSmsCode(props.record.mobile);
      if (bean.code === 200) {
        message.success(`验证发已发送到：${props.record.mobile}`);
      }
    } catch (e) {
      console.error(e);
    }
  }, [setNum, props.record.mobile]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <WrappedText {...props} />
      <Button
        type="default"
        disabled={currentNum > 0}
        style={{
          height: '40px',
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          borderLeft: 0
        }}
        onClick={getCode}
      >
        {currentNum === 0 ? '获取验证码' : `请${60 - currentNum}秒后再试`}
      </Button>
    </div>
  );
};

export default MobileCode;
