import React, { CSSProperties } from 'react';
import './SearchBar.less';
import { Button } from 'antd';
import { FunnelPlotOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons/lib';
import { FormInstance } from 'antd/lib/form';
import { cloneDeep, debounce, throttle } from 'lodash-es';
import { getProperty, isNull } from '@/common/common';
import { BaseQuery } from '@/service/base/BaseService';
import { EditableColumnProps } from '../Table/EditableTable';
import LocaleUtil from '../../util/LocaleUtil';
import { Dialog, WrappedForm } from '../index';

export declare interface SearchFormProps<T> {
  columns: EditableColumnProps<T>[];
  onQuery?: (query: Partial<T & BaseQuery>) => void;
  groupNum?: number;
  defaultRecord?: T;
  formStyle?: CSSProperties;
}
export declare interface SearchFormState<T> {
  basicColumns: EditableColumnProps<T>[];
  columns: EditableColumnProps<T>[];
  record: T;
}

export default class SearchBar<T> extends React.PureComponent<SearchFormProps<T>, SearchFormState<T>> {
  private readonly formRef = React.createRef<FormInstance>();

  private readonly searchBarSize = this.props.groupNum || 4;

  constructor(props: SearchFormProps<T>) {
    super(props);

    const { columns } = this.props;
    const currentCols = cloneDeep(columns)
      .filter(({ query }) => query)
      .map(row => {
        const { editType, editOption, editRule } = row;

        row.editable = true;
        row.editType = editType === 'radio' ? 'select' : editType;
        row.editRule = { required: editRule?.queryRequired };

        editOption.disabled = false;
        editOption.span = 16;

        return row;
      });

    this.state = {
      columns: currentCols,
      basicColumns: columns.length > this.searchBarSize ? [...currentCols].splice(0, this.searchBarSize) : currentCols,
      record: this.props.defaultRecord || ({} as T)
    };

    if (columns.length < this.searchBarSize) {
      const more = {
        editType: 'custom',
        name: 'more',
        editOption: {
          render: this.renderMore
        }
      };

      this.state.basicColumns.push(more as any);
    }
  }

  renderMore = (): JSX.Element => {
    const { columns } = this.state;
    if (columns.length <= this.searchBarSize) {
      return <></>;
    }

    return (
      <Button style={{ marginRight: '20px' }} type="default" onClick={this.openForm} icon={<FunnelPlotOutlined />}>
        {LocaleUtil.get('search.bar.btn.advanced.text', '高级查询')}
      </Button>
    );
  };

  openForm = () => {
    const { columns, record } = this.state;

    Dialog.open({
      title: LocaleUtil.get('search.bar.btn.advanced.text', '高级查询'),
      children: <WrappedForm columns={columns} span={8} isGrid onValuesChange={this.onValuesChange} record={record} />,
      width: '1000px',
      afterOk: this.onSearch
    });
  };

  executeQuery = debounce(record => this.props.onQuery?.(record), 2000);

  onSearch = async () => {
    const values = {};
    const { basicColumns, record } = this.state;
    basicColumns.forEach(({ name }) => {
      const val = getProperty(record, name);
      if (val !== undefined) {
        Object.assign(values, { [name]: val });
      }
    });

    this.formRef.current?.resetFields();
    this.formRef.current?.setFieldsValue(values);
    await this.formRef.current?.validateFields();
    this.props.onQuery?.(cloneDeep(record));
  };

  componentDidMount() {
    return this.onSearch();
  }

  onReset = () => {
    this.setState({ record: {} as T }, () => {
      this.formRef.current?.resetFields();
      this.props.onQuery?.({} as any);
    });
  };

  private fresh = throttle(() => this.props.onQuery?.(this.state.record as any), 1000);

  onValuesChange = (values: any) => {
    const { record } = this.state;
    Object.assign(record, values);

    for (const [key, val] of Object.entries(record)) {
      if (isNull(val)) {
        delete (record as any)[key];
      }
    }

    console.log('onValuesChange', record);

    this.setState({ record: { ...record } as T }, this.fresh);
  };

  render() {
    const { basicColumns, record } = this.state;
    const { formStyle = {} } = this.props;
    return (
      <div className="muyu-search-form-wrapper">
        <WrappedForm
          columns={basicColumns}
          isGrid={false}
          layout="inline"
          span={6}
          record={record}
          ref={this.formRef}
          labelWrap={false}
          style={{ ...formStyle }}
          onValuesChange={this.onValuesChange}
        />
        {this.renderMore()}
        <div className="muyu-search-form-group">
          <Button type="primary" icon={<SearchOutlined />} onClick={this.onSearch}>
            {LocaleUtil.get('search.bar.btn.search.text', '查询')}
          </Button>
          <Button type="default" icon={<ReloadOutlined />} onClick={this.onReset}>
            {LocaleUtil.get('search.bar.btn.reset.text', '重置')}
          </Button>
        </div>
      </div>
    );
  }
}
