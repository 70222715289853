import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import MixService, { Mix, MixQuery } from '@/service/repository/MixService';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import TableUtil from '@/component/Table/Util/TableUtil';
import { ToolbarOptionItem } from '@/component/Toolbar';
import MixForm from './Form';
import columns from './columns';

export declare interface MixListProps {
  onClick: (record: Mix, event: any) => void;
}

export declare interface MixListState {
  query: Record<string, unknown>;
  record?: Mix;
  refresh: boolean;
}

export default class List extends React.PureComponent<MixListProps, MixListState> {
  tableRef = React.createRef<EditableTable<Mix>>();

  state = {
    query: {},
    refresh: false
  };

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || {};
    this.edit('配合比新增', { ...record, id: null } as any);
  };

  edit = (title: string, record: Mix) => {
    Dialog.open({
      title,
      children: <MixForm record={record} />,
      width: 1000,
      styles: { body: { height: '600px' } },
      afterOk: this.getUtil().refresh
    });
  };

  delete = async () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('概算删除', '确定要删除数据吗', async () => {
      const bean = await MixService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        this.getUtil().reload();
      }

      return bean;
    });
  };

  getUtil = (): TableUtil<Mix> => this.tableRef.current?.util as TableUtil<Mix>;

  onQuery = (query: MixQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<Mix>
          ref={this.tableRef}
          service={MixService}
          autoSave
          columns={columns}
          loadData={MixService.findPage}
          onDoubleClick={record => this.edit('配合比修改', record)}
          isTree={false}
        />
      </>
    );
  }
}
