import { TaskExt } from '@/service/process/TaskExtService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import ContractService from '@/service/repository/ContractService';

const columns: EditableColumnProps<TaskExt>[] = [
  {
    label: '合同名称',
    code: 'contractId',
    name: 'contractName',
    ellipsis: true,
    editable: true,
    query: true,
    hidden: true,
    width: 200,
    editType: 'pop-select',
    editOption: {
      popup: ContractService.select
    },
    editRule: { required: true }
  },
  {
    label: '编号',
    code: 'no',
    name: 'businessNo',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '名称',
    code: 'name',
    name: 'businessName',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '合同',
    code: 'contract',
    name: 'contractName',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '流程',
    code: 'process',
    name: 'procName',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '当前任务环节',
    code: 'task.name',
    name: 'taskName',
    ellipsis: true,
    editable: true,
    width: 120,
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '签收人',
    code: 'assignee',
    name: 'assigneeName',
    ellipsis: true,
    editable: true,
    width: 80,
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '开始日期',
    code: 'begin.date',
    name: 'beginDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 160,
    editType: 'date',
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '结束日期',
    code: 'end.date',
    name: 'endDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 160,
    editType: 'date',
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '状态',
    code: 'status',
    name: 'status',
    ellipsis: true,
    editable: true,
    width: 80,
    formatter: 'select',
    formatOption: { type: 'task.status' },
    editOption: {},
    editRule: {},
    align: 'center'
  }
];

export default columns;
