import React from 'react';
import PDFViewer from '@/component/FileViewer/component/PDFViewer';

declare interface FileViewerProps {
  url: string;
  query?: Record<string, unknown>;
  method?: 'get' | 'post';
  type: 'pdf' | 'jpg' | 'png' | 'gif';
  name?: string;
}

const FileViewer: React.FC<FileViewerProps> = ({ type, name, ...rest }: FileViewerProps) => {
  switch (type) {
    case 'pdf':
      return <PDFViewer {...rest} />;
    case 'jpg':
    case 'png':
    case 'gif':
      return <img width="100%" height="100%" src={rest.url} alt={name} />;
    default:
      return <div>{`不支持的文件类型：${type}`}</div>;
  }
};

FileViewer.defaultProps = {
  query: {},
  method: 'post',
  name: ''
};

export default FileViewer;
