import React from 'react';
import WrappedTabs from '@/component/Tabs/WrappedTabs';
import ClearanceBillList from './List';
import ClearanceSerialList from '../clearanceSerial/List';
import ClearanceProductList from '../clearanceProduct/List';

export declare interface AccountProps {}

export default class Account extends React.PureComponent<AccountProps, any> {
  items = [
    { name: '批次信息', key: 'serial', render: () => <ClearanceSerialList /> },
    { name: '提单信息', key: 'bill', render: () => <ClearanceBillList /> },
    { name: '清关品名', key: 'product', render: () => <ClearanceProductList /> }
  ];

  render() {
    return <WrappedTabs items={this.items} />;
  }
}
