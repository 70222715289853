import { Table } from 'antd';
import React from 'react';
import { EditableColumnProps } from '@/component/Table/EditableTable';

export interface TableSummaryProps<T> {
  columns: EditableColumnProps<T>[];
  summary: T;
}
const TableSummary: React.FC<TableSummaryProps<any>> = ({ columns, summary }) => (
  <Table.Summary fixed>
    <Table.Summary.Row>
      {columns.map((row: EditableColumnProps<any>, index: number) => {
        const val = summary?.[row.name] || '';
        const realValue = row.name === 'tableIndex' ? '' : row.render?.(val, summary as any, index);
        return (
          <Table.Summary.Cell key={row.name?.toString()} index={index}>
            <div style={{ textAlign: row.align, display: 'inline-block', width: '100%', fontWeight: 'bold' }}>
              {(realValue === undefined ? val : realValue) as any}
            </div>
          </Table.Summary.Cell>
        );
      })}
    </Table.Summary.Row>
  </Table.Summary>
);

export default TableSummary;
