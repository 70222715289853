import React, { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { WrappedFormItemProps } from '../WrappedFormItem';
import LocaleUtil from '../../../util/LocaleUtil';

const { SHOW_CHILD } = TreeSelect;

export declare interface WrappedTreeSelectProps<T> extends TreeSelectProps<T> {}
const WrappedTreeSelect: React.FC<WrappedFormItemProps<any>> = React.forwardRef(
  (props: WrappedFormItemProps<any>, ref: React.Ref<any>) => {
    const {
      record,
      defaultValue,
      value,
      column: { editOption, code, label, name },
      onChange
    } = props;
    const { disabled, treeData, loadData } = editOption;
    const [currentValue, setValue] = useState(defaultValue);
    const [currentTreeData, setData] = useState(treeData);

    useEffect(() => {
      if (value !== currentValue) {
        record[name] = value;
        setValue(value);
      }
    }, [value, currentValue, record, name]);

    const mapProps = {
      placeholder: LocaleUtil.get(code, label),
      disabled,
      treeData: currentTreeData,
      onChange: (value: any) => {
        setValue?.(value);
        onChange?.(value);
      },
      treeCheckable: true,
      multiple: true,
      showCheckedStrategy: SHOW_CHILD,
      treeDataSimpleMode: false,
      value: currentValue,
      ref,
      loadData: async (e: any) => {
        const data = await loadData?.(e);
        if (data) {
          setData(data as any);
        }
      }
    };

    return <TreeSelect {...mapProps} />;
  }
);

export default WrappedTreeSelect;
