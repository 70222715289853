import React from 'react';
import { FormInstance } from 'antd/lib/form';
import UserService, { SysUser } from '@/service/system/SysUserService';
import { castTo } from '@/common/common';
import { message } from 'antd';
import columns from '@/view/system/user/model/personalColumns';
import { WrappedForm } from '@/component';
import SystemService from '@/service/system/SystemService';
import { ResultBean } from '@/service/base/BaseService';

export declare interface PersonalProps {
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export declare interface PersonalState {
  user: SysUser | null;
}

export default class Personal extends React.PureComponent<PersonalProps, PersonalState> {
  state = {
    user: null
  };

  formRef = React.createRef<FormInstance>();

  save = async () => {
    const { user } = this.state;
    const currentUser = await this.formRef.current?.validateFields();
    const bean = await UserService.save(castTo<SysUser>(Object.assign(user, currentUser)));

    if (bean.code === 200) {
      message.success(bean.msg);
    }

    this.setState({ user: bean.data });

    return bean;
  };

  async componentDidMount() {
    const { setHandler } = this.props;
    const { code, msg, data } = await SystemService.getCurrentUser();
    if (code !== 200) {
      return message.error(msg);
    }

    this.setState({ user: data });
    setHandler?.(this.save);
  }

  render() {
    const { user } = this.state;

    const props = {
      columns,
      record: user,
      span: 12,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '30px 20px' }
    };

    return user === null ? <></> : <WrappedForm {...props} />;
  }
}
