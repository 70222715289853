import { theme, ThemeConfig } from 'antd';

export const websiteTheme = 'dark';

export const themeToken: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: '#1abc9c',
    fontSize: 12
  },
  components: {
    Table: {
      cellFontSize: 12,
      cellFontSizeMD: 12,
      cellFontSizeSM: 12,
      cellPaddingBlock: 16,
      cellPaddingBlockMD: 12,
      cellPaddingBlockSM: 4
    },
    Modal: { titleFontSize: 14 },
    Tabs: {
      fontSize: 14
    },
    Input: {
      inputFontSize: 12
    },
    Menu: {
      fontSize: 14,
      padding: 4
    }
  }
};
