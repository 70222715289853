import React from 'react';
import MenuService, { SysMenu } from '@/service/system/SysMenuService';
import { EditableTable, Toolbar } from '@/component';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import DictUtil from '@/util/DictUtil';
import LocaleService from '@/service/system/SysLocaleService';
import columns from './columns';

export declare interface MenuListProps {
  onClick: (record: SysMenu, event: any) => void;
}

export declare interface MenuListState {
  record?: SysMenu;
}

export default class List extends React.PureComponent<MenuListProps, MenuListState> {
  tableRef = React.createRef<EditableTable<SysMenu>>();

  export = () => MenuService.export(`菜单清单.xlsx`);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.export,
      code: 'export',
      title: '导出'
    }
  ];

  render() {
    return (
      <>
        <Toolbar items={this.items} />
        <EditableTable<SysMenu>
          ref={this.tableRef}
          service={MenuService}
          autoSave
          columns={columns}
          isEdit
          isTree
          multiSelect="legacy"
          toolbar={{ expandNum: 8, isMove: true, isShift: true, isReload: true }}
          loadData={MenuService.findTree}
        />
      </>
    );
  }
}
