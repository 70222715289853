import { EditableColumnProps } from '../../Table/EditableTable';
import validator from './index';

export default (column: EditableColumnProps<any>) => {
  const { integer } = column.editRule || {};
  const { label } = column;

  return {
    validator: (rule: any, value: any) => {
      const rep = integer ? /^[0-9]*$/ : /^([-+])?\d+(\.\d+)?$/;
      const msg = integer ? validator.message('integer', label) : validator.message('number', label);
      return rep.test(value) ? Promise.resolve(msg) : Promise.reject(msg);
    }
  };
};
