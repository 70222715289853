import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormatUtil from '@/util/FormatUtil';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import Decimal from 'decimal.js';

export declare interface WrappedFooterProps {
  onSummary?: (summary: Record<string, Decimal>) => void;
  columns: EditableColumnProps<any>[];
  records: Record<string, string>[];
  footer?: boolean | Record<string, string>;
}

const Footer = ({ columns, footer, records, onSummary }: WrappedFooterProps) => {
  const names = useMemo(() => columns.filter(({ summarize }) => summarize).map(({ name }) => name), [columns]);
  const getSummary = useCallback(() => {
    const summary = typeof footer === 'object' ? footer : ({} as Record<string, any>);
    for (const name of names.values()) {
      summary[name] = records
        .map(record => FormatUtil.number(record?.[name]))
        .map(a => new Decimal(a))
        .reduce((a, b) => a.plus(b));
    }

    return summary;
  }, [names, records, footer]);

  const [summary, setSummary] = useState(getSummary());

  useEffect(() => {
    const summary = getSummary();
    setSummary({ ...summary });
  }, [records, setSummary, getSummary]);

  useEffect(() => {
    onSummary?.(summary);
  }, [summary, onSummary]);
  return !footer ? (
    <></>
  ) : (
    <tfoot>
      <tr>
        {columns.map(({ name, span, formatter, align, formatOption, summarize = false }) => (
          <td colSpan={span} key={name} style={{ textAlign: align, height: '34px', color: 'rgba(0, 0, 0, 0.25)' }}>
            {summarize ? FormatUtil.format(summary[name].toString(), formatter, formatOption) : summary[name]}
          </td>
        ))}
      </tr>
    </tfoot>
  );
};

export default Footer;
