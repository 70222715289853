import { Dictionary } from '@/common/types';
import FileUtil from '@/util/FileUtil';
import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';

export interface SysLocale extends BaseEntity<SysLocale> {
  lang: string;
  code: string;
  name: string;
  value: string;
}

export declare type SysLocaleQuery = Partial<SysLocale & BaseQuery>;

class SysLocaleService extends BaseService<SysLocale, SysLocaleQuery> {
  getLocale = (query: SysLocaleQuery): Promise<ResultBean<Dictionary<string>>> =>
    this.request('post', 'get-locale', query);

  export = (fileName: string, query: SysLocaleQuery) =>
    FileUtil.download(this.getUrl('export'), 'post', fileName, query);
}

export default new SysLocaleService(API_URL, 'sys-locale');
