import { useMount } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';

let mapId = 0;

export const CustomMap = ({ value, onChange, record }: any) => {
  const containerId = `map_container${mapId++}`;
  const [currentRadius, setRadius] = useState(record?.radius);
  const [currentPoint, setPoint] = useState({ lat: record?.locationLatitude, lng: record?.locationLongitude });
  const [map, setMap] = useState<any>(null);
  const level = 14;
  const addCircle = useCallback(
    (point: any, radius) => {
      if (point.lng && point.lat && map) {
        const { BMapGL } = window as any;
        const mk = new BMapGL.Marker(point);
        map.clearOverlays();
        map.addOverlay(mk);
        map.panTo(point);
        map.centerAndZoom(point, level);
        const circle = new BMapGL.Circle(new BMapGL.Point(point.lng, point.lat), radius, {
          strokeColor: '#FF9966',
          strokeWeight: 2,
          strokeOpacity: 0.8,
          fillColor: '#FFFFCC'
        });
        console.log(point, radius);
        map?.addOverlay(circle);
      }
    },
    [map]
  );

  useEffect(() => {
    onChange?.(value, currentPoint);
  }, [value, currentPoint, onChange]);

  useEffect(() => {
    if (value !== currentRadius && value) {
      setRadius(value);
    }
  }, [value, currentRadius]);

  useEffect(() => {
    addCircle(currentPoint, currentRadius);
  }, [addCircle, currentPoint, currentRadius]);

  useMount(() => {
    const { BMapGL } = window as any;
    const map = new BMapGL.Map(containerId, {
      enableRotate: false,
      enableTilt: false
    });

    setMap(map);
    map.centerAndZoom(new BMapGL.Point(currentPoint.lat || 104.177781, currentPoint.lng || 30.574869), level); // 初始化地图,设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true);
    map.setZoom(level);

    const geolocation = new BMapGL.Geolocation();
    geolocation.getCurrentPosition((r: any) => {
      if (r) {
        map.panTo(r.point);
        map.centerAndZoom(r.point, level);

        console.log(`您的位置：${r.point.lng},${r.point.lat}`);
      }
    });

    const menu = new BMapGL.ContextMenu();
    menu.addItem(
      new BMapGL.MenuItem(
        '选择此处',
        (point: any) => {
          const { lat, lng } = point;
          setPoint({ lat, lng });
        },
        {
          width: 100,
          id: `map_context_menu`
        }
      )
    );
    map.addContextMenu(menu);
  });

  return <div id={containerId} style={{ width: '580px', height: '360px' }} />;
};
