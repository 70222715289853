import { ResultBean } from '@/service/base/BaseService';
import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { WrappedHttp } from '@/common/http';

class FileUtil {
  http: WrappedHttp = new WrappedHttp();

  ab2json = async (u: ArrayBuffer): Promise<ResultBean<ArrayBuffer>> =>
    new Promise(resolve => {
      if (u.byteLength === 0) {
        resolve({ code: -1, msg: '文件长度为0' } as any);
      }

      const b = new Blob([u]);
      const r = new FileReader();
      r.readAsText(b, 'utf-8');
      r.onload = () => {
        try {
          resolve(JSON.parse(r.result as string));
        } catch (error) {
          resolve({ code: 200, msg: '成功', data: u } as any);
        }
      };
    });

  getArrayBuffer = async (url: string, method: string, data?: Record<string, unknown>) => {
    const buffer = await this.http.request({
      url,
      method: method.toLowerCase(),
      responseType: 'arraybuffer',
      data
    });

    return this.ab2json(buffer);
  };

  download = async (url: string, method: string, fileName: string, obj?: Record<string, unknown>) => {
    const { code, msg, data } = await this.getArrayBuffer(url, method, obj);
    if (code !== 200) {
      return message.error(msg);
    }

    const link = document.createElement('a');
    const blob = new Blob([data]);
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  upload = (
    url: string,
    file: RcFile[] | RcFile,
    data: Record<string, string | Blob | any>,
    onUploadProgress?: (progressEvent: any) => void
  ) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    if (file instanceof Array) {
      file.forEach(f => formData.append('file', f));
    } else {
      formData.append('file', file);
    }

    return this.http.request({
      url,
      method: 'post',
      data: formData,
      onUploadProgress
    });
  };
}

export default new FileUtil();
