import React, { Dispatch, SetStateAction, useState } from 'react';
import './Loading.less';
import ReactDOM, { Root } from 'react-dom/client';

let root: any = null;
let loadingRoot: Root | null = null;

interface LoadingPropsType {
  size?: 'small' | 'normal' | 'large' | number;
  parent?: () => HTMLElement;
}

let setTipFunc: Dispatch<SetStateAction<any>> | null = null;
const Loading: React.FC<LoadingPropsType> = (props: LoadingPropsType) => {
  const { size = 'normal' } = props;
  const [tip, setTip] = useState(null);
  setTipFunc = setTip;
  let width;
  switch (size) {
    case 'small':
      width = 16;
      break;
    case 'normal':
      width = 32;
      break;
    case 'large':
      width = 48;
      break;
    default:
      width = size as number;
  }

  return (
    <div className="ant-modal-mask">
      <div className="loadingWrapper">
        <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
          <svg
            className="spinner"
            id="cloudos-loading-spinner"
            style={{ width: `${width}px`, height: `${width}px` }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-1 -1 2 2"
          >
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.16863"
              transform="rotate(0)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.2"
              transform="rotate(30)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.21961"
              transform="rotate(60)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.25882"
              transform="rotate(90)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.32157"
              transform="rotate(120)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.38039"
              transform="rotate(150)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.43137"
              transform="rotate(180)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.54118"
              transform="rotate(210)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.65098"
              transform="rotate(240)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.76078"
              transform="rotate(270)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="0.90196"
              transform="rotate(300)"
            />
            <rect
              x="0.45625"
              y="-0.09099"
              width="0.54375"
              height="0.18198"
              rx="0.09099"
              ry="0.09099"
              fill="rgb(0,0,0)"
              fillOpacity="1"
              transform="rotate(330)"
            />
          </svg>
          <div style={{ fontSize: '14px', fontWeight: 'bold', marginTop: `${width + 5}px` }}>{tip}</div>
        </div>
      </div>
    </div>
  );
};

let count = 0;
const filter = (status: boolean) => {
  const value = status ? 'blur(3px) opacity(0.8) brightness(0.8)' : 'revert';
  const app = document.getElementById('root')!;
  if (app) {
    app.style.filter = value;
  }

  const modals = document.getElementsByClassName('ant-modal')!;
  for (let i = 0; i < modals.length; i++) {
    const modal = modals[i] as HTMLDivElement;
    modal.style.filter = value;
  }
};
const open = () => {
  if (root == null) {
    root = document.createElement('div');
    document.body.appendChild(root);

    loadingRoot = ReactDOM.createRoot(root);
    loadingRoot?.render(<Loading />);
    filter(true);
  }

  count++;
};

const close = () => {
  setTimeout(() => {
    count--;
    if (count <= 0) {
      loadingRoot?.unmount();
      loadingRoot = null;

      if (root != null) {
        document.body.removeChild(root);
        root = null;
      }

      filter(false);
    }
  }, 100);
};

const setTip = (tip: string) => setTipFunc?.(tip);
const timeout = (delay: number) =>
  new Promise(resolve => {
    setTimeout(() => resolve(0), delay);
  });

const wrapped: any = (msg: string, func: any) =>
  async function (...args: any[]) {
    let data: any;
    const promise = (() => func(...args))();
    try {
      const display = await Promise.race([promise, timeout(100)]);
      if (!display) {
        open();
        data = await Promise.all([promise, timeout(200)]);
      } else {
        data = await Promise.all([promise]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      close();
    }

    return data[0];
  };

export default { open, close, setTip, wrapped, filter };
