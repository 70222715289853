import { EditableColumnProps } from '../../Table/EditableTable';
import validator from './index';

export default (column: EditableColumnProps<any>) => {
  const { maxLength = -1 } = column.editRule || {};
  const { label } = column;

  return {
    validator: (_: any, value: string) => {
      const msg = validator.message('maxLength', label, maxLength);
      return value && value?.length > maxLength ? Promise.reject(msg) : Promise.resolve();
    }
  };
};
