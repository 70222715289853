import { Mix } from '@/service/repository/MixService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<Mix>[] = [
  {
    label: '名称',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: {},
    editRule: { required: true, maxLength: 50 }
  },
  {
    label: '类型',
    name: 'type',
    code: 'type',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    formatter: 'select',
    formatOption: { type: 'mix.type' },
    editType: 'select',
    editOption: { type: 'mix.type' },
    align: 'center',
    editRule: { required: true, maxLength: 50 }
  },
  {
    label: '级配',
    name: 'grade',
    code: 'mix.grade',
    ellipsis: true,
    editable: false,
    width: 100,
    editOption: {},
    editRule: {}
  },
  {
    label: '坍落度',
    name: 'slump',
    code: 'slump',
    ellipsis: true,
    editable: true,
    width: 100,
    align: 'left',
    editOption: {},
    editRule: {}
  },
  {
    label: '水灰比',
    name: 'waterCementRatio',
    code: 'water.cement.ratio',
    ellipsis: true,
    editable: true,
    width: 180,
    formatter: 'number',
    formatOption: { zeroEmpty: true },
    align: 'right',
    editOption: {},
    editRule: {}
  },
  {
    label: '砂率',
    name: 'sandRatio',
    code: 'sand.ratio',
    ellipsis: true,
    editable: true,
    width: 80,
    formatter: 'number',
    formatOption: { zeroEmpty: true },
    align: 'right',
    editOption: {},
    editRule: {}
  },
  {
    label: '备注',
    name: 'comment',
    code: 'remark',
    ellipsis: true,
    editable: true,
    width: 300,
    editOption: {},
    editRule: {}
  }
];

export default columns;
