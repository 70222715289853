import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createGetOptions, createOnSelect, isNull } from '@/common/common';
import { isString } from 'lodash-es';
import { WrappedFormItemProps } from '../WrappedFormItem';
import PopSelect, { SelectedType, SelectOptionType, SelectValueType, ValueType } from '../../PopSelect/PopSelect';
import LocaleUtil from '../../../util/LocaleUtil';

export interface WrappedPopSelectProps {
  popup: (record?: any, value?: SelectValueType) => Promise<SelectedType>;
  onSelect: (dst: any, src?: SelectedType) => void;
  getOptions: (value: any, record?: any, data?: SelectedType) => Promise<SelectOptionType[]>;
  prefix?: string;
  key?: string;
}

const WrappedPopSelect: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => {
  const { column, record, value, onChange } = props;
  const { editOption, code, label, name } = column;

  const { getOptions, onSelect, prefix, popup, disabled, mode, key = 'id' } = editOption;
  const [selected, setSelected] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentGetOptions = useCallback(getOptions ?? createGetOptions(`${prefix}Id`, `${prefix}Name`), [
    getOptions,
    prefix
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentOnSelect = useCallback(onSelect ?? createOnSelect({ id: `${prefix}Id`, name: `${prefix}Name` }), [
    onSelect,
    prefix
  ]);

  const [options, setOptions] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    currentGetOptions(value, record, selected).then(setOptions);
  }, [record, currentGetOptions, value, selected]);

  const mapper = useCallback((value: any) => (isString(value) || isNull(value) ? value : value[key]), [key]);
  const filter = useCallback(
    (val: ValueType) => {
      let values: any;
      if (mode === 'multiple') {
        values = value?.filter((data: any) => val?.includes(mapper(data)));
        setOptions(options.filter(({ value }) => val?.includes(value)));
      } else {
        setOptions([]);
      }
      return values;
    },
    [value, mode, mapper, setOptions, options]
  );
  const currentValue = useMemo(() => (mode === 'multiple' ? options?.map(({ value }) => value) : options?.[0]?.value), [
    mode,
    options
  ]);
  const mapProps = {
    options,
    popup: async (value?: SelectValueType) => popup?.(record, value),
    placeholder: LocaleUtil.get(code, label),
    onSelect: (data?: SelectedType) => {
      currentOnSelect?.(record, data);
      setSelected(data);
      onChange?.(record[name]);
    },
    onChange: (value: SelectValueType) => {
      if (value === undefined) {
        currentOnSelect?.(record, undefined);
      }
      onChange?.(filter(value));
    },
    disabled,
    mode,
    value: currentValue
  };

  return <PopSelect {...mapProps} />;
};

export default WrappedPopSelect;
