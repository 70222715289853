import React, { useCallback, useEffect, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import Header from '@/component/Form/component/WrappedTable/Header';
import Footer from '@/component/Form/component/WrappedTable/Footer';
import FormatUtil, { FormatOption } from '@/util/FormatUtil';
import './Table.less';
import RowList from '@/component/Form/component/WrappedTable/RowList';
import ColGroup from '@/component/Form/component/WrappedTable/ColGroup';
import { isBlank } from '@/common/common';
import { useUpdateEffect } from 'ahooks';
import Decimal from 'decimal.js';

export declare interface WrappedTableProps {
  header?: boolean;
  footer?: Record<string, any> | boolean;
  readonly?: boolean;
  formRef?: React.Ref<FormInstance>;
  columns: EditableColumnProps<any>[];
  value?: string;
  onChange?: (value?: string) => void;
  rowNum?: number;
  onSummary?: (summary: Record<string, Decimal>) => void;
  lineSpan?: number;
  setRef: (ref: React.Ref<any>) => void;
}

export type Data = Record<string, any>;
export type DataList = Data[];
export const LINE_SPLITTER = '&l;';

export const FIELD_SPLITTER = '&f;';

export const format = (value: string, formatter = 'text', fmtOption?: FormatOption) =>
  ['text', 'number', 'currency'].includes(formatter) ? FormatUtil.format(value as string, formatter, fmtOption) : value;

const Table = ({
  value = '',
  columns,
  rowNum = 2,
  onChange,
  header,
  footer,
  lineSpan = 24,
  readonly,
  onSummary,
  setRef
}: WrappedTableProps) => {
  const minRowSize = rowNum || 2;
  const unformat = useCallback(
    (): any =>
      value.split(LINE_SPLITTER)?.map(str => {
        const list = str.split(FIELD_SPLITTER);
        const record = {} as Data;
        for (const [index, { name, formatter, formatOption }] of columns.entries()) {
          if (!isBlank(list[index])) {
            record[name] = format(list[index], formatter, formatOption);
          }
        }
        return record;
      })!,
    [value, columns]
  );
  const [records, setRecords] = useState<Record<string, string>[]>(unformat());
  const [formatedValues, setFormatedValues] = useState<string[]>(value.split(LINE_SPLITTER));
  const [size, setSize] = useState(Math.max(minRowSize, records.length));
  const onValueChange = useCallback(
    (rowNum: number, record: Record<string, string>, formatedValue: string) => {
      records[rowNum] = record;
      formatedValues[rowNum] = formatedValue;
      if (isBlank(formatedValue?.replaceAll(FIELD_SPLITTER, '')?.replaceAll(' ', ''))) {
        records.splice(rowNum, 1);
        formatedValues.splice(rowNum, 1);
      }
      setRecords([...records]);
      setFormatedValues([...formatedValues]);
    },
    [records, setRecords, formatedValues, setFormatedValues]
  );

  useUpdateEffect(() => {
    onChange?.(formatedValues.join(LINE_SPLITTER));
  }, [formatedValues]);

  useEffect(() => {
    if (!readonly) {
      if (records.length >= size) {
        setSize(records.length + 1);
      } else if (records.length < size - 1) {
        setSize(size - 1);
      }
    } else {
      setSize(records.length);
    }
  }, [records, size, readonly]);

  return (
    <table style={{ width: '100%' }} className="wrapped-table">
      <ColGroup spanPerLine={lineSpan} />
      <Header columns={columns} hidden={!header} />
      <tbody>
        <RowList
          size={size}
          setRef={setRef}
          columns={columns}
          readonly={readonly}
          records={[...records]}
          onChange={onValueChange}
        />
      </tbody>
      <Footer columns={columns} onSummary={onSummary} records={records} footer={footer} />
    </table>
  );
};

export default Table;
