import React from 'react';
import { CheckOutlined, DeleteOutlined, RollbackOutlined, StopOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import { ToolbarOptionItem } from '@/component/Toolbar';
import ProcessExtService, { ProcessExt, ProcessExtQuery } from '../../../service/process/ProcessExtService';
import columns from './columns';
import { Dialog, EditableTable, SearchBar, Toolbar } from '../../../component';
import ProcessExtForm from './Form';
import TableUtil from '../../../component/Table/Util/TableUtil';

export declare interface ProcessExtListProps {
  onClick: (record: ProcessExt, event: any) => void;
}

export declare interface ProcessExtListState {
  query: Record<string, unknown>;
  record?: ProcessExt;
  refresh: boolean;
}

export default class List extends React.PureComponent<ProcessExtListProps, ProcessExtListState> {
  tableRef = React.createRef<EditableTable<ProcessExt>>();

  state = {
    query: {},
    refresh: false
  };

  modify = (record: ProcessExt) => {
    Dialog.open({
      title: '模型修改',
      children: <ProcessExtForm record={record} />,
      width: 600,
      afterOk: this.getUtil().refresh
    });
  };

  toModel = async () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中流程');
    }

    Dialog.confirm('转为模型', `确定要流程【${record.name}】转为模型吗？`, async () => {
      const bean = await ProcessExtService.toModel(record.key);
      if (bean.code === 200) {
        message.success(bean.msg);
      }

      return bean;
    });
  };

  start = async () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要启用的数据');
    }

    Dialog.confirm('流程启用', `确定要启用流程【${record.name}】吗？`, async () => {
      const bean = await ProcessExtService.save(Object.assign(record, { status: 1 }));
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  stop = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要停用的数据');
    }

    Dialog.confirm('流程停用', `确定要停用流程【${record.name}】吗？`, async () => {
      const bean = await ProcessExtService.save(Object.assign(record, { status: 0 }));
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  delete = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('流程删除', `确定要删除流程【${record.name}】吗？`, async () => {
      const bean = await ProcessExtService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  getUtil = (): TableUtil<ProcessExt> => this.tableRef.current?.util as TableUtil<ProcessExt>;

  onQuery = (query: ProcessExtQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <RollbackOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.toModel,
      code: 'toModel',
      title: '转为模型'
    },
    {
      type: 'yellow',
      icon: <CheckOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.start,
      code: 'start',
      title: '启用'
    },
    {
      type: 'primary',
      icon: <StopOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.stop,
      code: 'stop',
      title: '停用',
      danger: true
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<ProcessExt>
          ref={this.tableRef}
          service={ProcessExtService}
          autoSave
          columns={columns}
          loadData={ProcessExtService.findPage}
          onDoubleClick={this.modify}
          isTree={false}
        />
      </>
    );
  }
}
