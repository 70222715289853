import { BaseEntity } from '@/service/base/BaseService';
import { cloneDeep } from 'lodash-es';
import { Action } from '@/component/Table/Util/TableUtil';

interface DataRecord<T> {
  action: Action;
  key?: string;
  editing?: boolean;
  data: T;
  originalData: T;
}
export class DataStore<T extends BaseEntity<T>> {
  private records: DataRecord<T>[] = [];

  private getKey = ({ key, id, name }: T) => key || id || name || '';

  private push = (action: Action, data: T, editing: boolean) => {
    const key = this.getKey(data);
    const index = this.records.findIndex(t => t.key === key);
    console.warn(action, data, editing);
    if (index === -1) {
      this.records.push({ action, key, data, editing, originalData: cloneDeep(data) });
    } else if (action === 'remove' && this.records[index].action === 'create') {
      this.records.splice(index, 1);
    } else {
      this.records[index].editing = editing;
    }
  };

  create = (record: T, editing: boolean) => this.push('create', record, editing);

  modify = (record: T) => this.push('modify', record, !this.isEditing(record));

  remove = (record: T) => {
    const index = this.records.findIndex(t => t.data === record);
    if (index !== -1) {
      this.records.splice(index, 1);
    }
  };

  isEditing = (record: T) => !!this.records.find(({ data, editing }) => data === record && editing);

  findEditedList = () => this.records.filter(({ editing }) => editing).map(t => t.data);

  get = () => this.records;

  getList = () => this.records.map(t => t.data);

  clear = () => {
    this.records = [];
  };

  isEmpty = () => this.records.length === 0;
}
