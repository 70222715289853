import BaseService, { BaseEntity, BaseQuery, BaseServiceProps } from '../base/BaseService';
import { API_URL } from '../url';

export interface SysRole extends BaseEntity<SysRole> {
  name: string;
  code: string;
  icon: string;
  href: string;
  permission: string;
  status: boolean;
}

export type SysRoleQuery = BaseQuery;

export type SysRoleServiceProps = BaseServiceProps;

class SysRoleService extends BaseService<SysRole, SysRoleQuery> {
  findRoleList = async (query: SysRoleQuery = {}) => {
    const { code, data } = await this.findList(query);
    return code === 200 ? data : [];
  };
}

const roleService = new SysRoleService(API_URL, 'sys-role');

export default roleService;
