import React from 'react';
import { WrappedFormItemProps } from '@/component/Form/WrappedFormItem';

const FormHeader = ({ record: { no }, name }: WrappedFormItemProps<any> & { name: string }) => (
  <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>
    {name}
    <span style={{ fontWeight: 'normal', fontSize: '12px', fontStyle: 'italic', fontFamily: '宋体' }}>（NO:{no}）</span>
  </h2>
);

export default FormHeader;
