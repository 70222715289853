class LocalStorageUtil {
  setItem = (key: string, value: any, expired?: number): void => {
    const second = expired!;
    localStorage[key] = JSON.stringify({
      value,
      expired: expired ? Date.now() + second * 1000 : -1
    });
  };

  removeItem = (key: string): void => {
    localStorage.removeItem(key);
  };

  getItem = <T>(key: string): T | undefined => {
    const json: any = localStorage.getItem(key);
    let value: T | undefined;
    if (json) {
      const data: { value?: T; expired: number } = JSON.parse(json);
      if (data.expired !== -1 && Date.now() > data.expired) {
        this.removeItem(key);
      } else {
        value = data.value;
      }
    }

    return value;
  };
}

export default new LocalStorageUtil();
