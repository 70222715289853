import React, { Dispatch, SetStateAction } from 'react';
import { Button, message } from 'antd';
import { FormProps } from 'antd/es/form/Form';
import { API_URL } from '@/service/url';
import { FormInstance } from 'antd/lib/form';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import { cloneDeep } from 'lodash-es';
import WrappedVerifyCode from '@/component/Form/component/WrappedVerifyCode';
import systemInit from '@/common/system';
import UserUtil from '@/util/UserUtil';
import WrappedTabs from '@/component/Tabs/WrappedTabs';
import mobileColumns from '@/view/system/login/mobileColumns';
import { ResultBean } from '@/service/base/BaseService';
import MapUtil from '@/util/MapUtil';
import { systemName } from '@/common/common';
import columns from './columns';
import WrappedForm from '../../../component/Form/WrappedForm';
import './login.less';
import auth, { AuthResult, SmsUser, User } from '../../../service/system/AuthService';
import system from '../../../service/system/SystemService';
import Locale from '../../../component/Locale/Locale';
import LocaleUtil from '../../../util/LocaleUtil';

interface LoginProps extends FormProps {
  setLogin: Dispatch<SetStateAction<boolean>>;
}

interface LoginState {
  refresh: boolean;
  columns: EditableColumnProps<any>[];
  mobileColumns: EditableColumnProps<any>[];
}

export default class Login extends React.PureComponent<LoginProps, LoginState> {
  state = {
    refresh: false,
    columns: cloneDeep(columns)
  };

  formRef = React.createRef<FormInstance>();

  formMobileRef = React.createRef<FormInstance>();

  // verifyRef = React.createRef<WrappedVerifyCode>();

  verify: EditableColumnProps<any> = {
    name: 'verifyCode',
    label: '验证码',
    editType: 'custom',
    editable: true,
    span: 24,
    editOption: {
      hideLabel: true,
      render: ({ ref, ...rest }) => <WrappedVerifyCode size={5} url={`${API_URL}/system/captcha`} {...rest} />
    },
    editRule: { required: true }
  };

  usernameLogin = async () => {
    const user = await this.formRef.current?.validateFields();
    if (user) {
      const bean: ResultBean<AuthResult | number> = await auth.login(user as User);
      if (bean && bean.code === 500) {
        const { columns } = this.state;
        const num = bean.data as number;
        if (num >= 3) {
          if (columns.length < 3) {
            columns.push(this.verify as any);
            this.setState({ columns: [...columns] });
          }
        }

        message.error(bean.msg);
      }
    }
  };

  mobileLogin = async () => {
    const user = await this.formMobileRef.current?.validateFields();
    if (user) {
      const bean: ResultBean<AuthResult> = await auth.loginByMobile(user as SmsUser);
      if (bean && bean.code === 500) {
        message.error(bean.msg);
      }
    }
  };

  login = async () => {
    try {
      await this.usernameLogin();
      await this.systemInit(true);
    } catch (e) {
      if (e && e.errorFields && e.errorFields instanceof Array) {
        const msg = e.errorFields.map(({ errors }: any) => errors?.join(',')).join(',');
        message.error(msg);
      }
    }
  };

  systemInit = async (success: boolean) => {
    const { setLogin } = this.props;
    if (success) {
      await systemInit();
      await UserUtil.init();
      MapUtil.init();
      const bean = await system.getConfig();
      if (bean && bean.code === 200) {
        setLogin(true);
      }
    } else {
      this.setState({ refresh: true });
    }
  };

  async componentDidMount() {
    let success = false;
    try {
      success = await auth.refresh();
    } catch (e) {
      console.log(e);
    }

    await this.systemInit(success);
    await this.onEnterUp();
  }

  componentWillUnmount() {
    document.onkeyup = null;
  }

  async onEnterUp() {
    document.onkeyup = (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        this.login();
      }
    };
  }

  render() {
    const { refresh, columns } = this.state;
    if (!refresh) {
      return <></>;
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div className="login-bg">
          <Locale />
        </div>
        <div className="loginWrapper">
          <h2>{LocaleUtil.get('title', systemName)}</h2>
          <WrappedForm ref={this.formRef} isGrid columns={columns} record={{ username: 'lisa', password: '123' }} />
          <Button type="primary" size="large" onClick={this.login} block>
            {LocaleUtil.get('login.btn.text', '登录')}
          </Button>
        </div>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            zIndex: 999,
            bottom: '10px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', color: '#aaa', fontSize: '12px' }}>
            <div style={{ marginRight: '10px' }}>Copyright@2024-至今 成都墨安科技有限公司 版权所有</div>
            <a
              style={{ color: '#aaa', textDecoration: 'none', fontSize: '12px', marginRight: '10px' }}
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noreferrer"
            >
              蜀ICP备2024073030号
            </a>
            <img src="./备案图标.png" alt="_blank" style={{ width: '16px', height: '16px', marginRight: '4px' }} />
            <a
              style={{ color: '#aaa', textDecoration: 'none', fontSize: '12px' }}
              href="https://beian.mps.gov.cn/#/query/webSearch?code=51011202000738"
              rel="noreferrer"
              target="_blank"
            >
              川公网安备51011202000738
            </a>
          </div>
        </div>
      </div>
    );
  }
}
