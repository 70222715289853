import { SwitchActionEnum } from '@/redux/actions/action';

const switchMiddleware = ({ dispatch }: any) => (next: any) => (action: any) => {
  if (['TAB_ADD', 'TAB_DEL', 'TAB_SWITCH'].includes(action?.type)) {
    dispatch({ type: 'SWITCH', fade: SwitchActionEnum.FADE_OUT });
    setTimeout(() => {
      next(action);
      dispatch({ type: 'SWITCH', fade: SwitchActionEnum.FADE_IN });
    }, 200);
  } else {
    return next(action);
  }
};

export default switchMiddleware;
