import React, { RefObject } from 'react';
import { ColumnProps } from 'antd/es/table';
import { FormInstance } from 'antd/es/form/Form';
import { TableProps } from 'antd/lib/table';
import './EditableTable.less';
import { Key } from 'antd/es/table/interface';
import classNames from 'classnames';
import { message, Table } from 'antd';
import { cloneDeep } from 'lodash-es';
import ResizeObserver, { SizeInfo } from 'rc-resize-observer';
import LocaleUtil from '@/util/LocaleUtil';
import TableSummary from '@/component/Table/TableSummary';
import TableUtil from './Util/TableUtil';
import TableToolbar, { TableToolbarProps } from './TableToobar/TableToolBar';
import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import { EditOption, ValidatorRule } from '../Form/WrappedForm';
import FormatUtil, { FormatOption } from '../../util/FormatUtil';
import ResizableTitle from './EditableTableHeaderCell';
import BaseService, { BaseEntity, BaseQuery, PageBean, ResultBean } from '../../service/base/BaseService';

export declare interface EditableColumnProps<T> extends Omit<ColumnProps<T>, 'hidden'> {
  label: string;
  code?: string;
  id?: string;
  name: string;
  editType?:
    | 'text'
    | 'textarea'
    | 'search'
    | 'select'
    | 'pop-select'
    | 'radio'
    | 'checkbox'
    | 'switch'
    | 'date'
    | 'time'
    | 'time-range'
    | 'date-range'
    | 'label'
    | 'custom'
    | 'image'
    | 'cascader'
    | 'upload'
    | 'assignee'
    | 'tree-select'
    | 'table';
  hidden?: boolean | (() => boolean);
  editable?: boolean | (() => boolean);
  query?: boolean | (() => boolean);
  summarize?: boolean;
  span?: number;
  rowSpan?: number;
  sort?: number;
  formatter?: 'text' | 'textarea' | 'select' | 'number' | 'currency' | 'date' | 'percent' | 'switch';
  formatOption?: FormatOption;
  editOption: EditOption;
  editRule?: ValidatorRule;
  width?: number;
  ref?: any;
  onlyLeafWritten?: boolean; // 仅仅叶子可以修改
  index?: number;
}

class EntityService<T extends BaseEntity<T>, Q extends BaseQuery> extends BaseService<T, Q> {}

export declare interface GroupHeader {
  startColumnName: string;
  numOfColumns: number;
  title: string;
  level: number;
}

export declare interface EditableTableProps<T extends BaseEntity<T>> extends Omit<TableProps<T>, 'footer' | 'columns'> {
  columns: EditableColumnProps<T>[];
  groupHeaders?: GroupHeader[];
  onClick?: (record?: T, event?: any) => void;
  onDoubleClick?: (record: T, event: any) => void;
  onContextMenu?: (record: T, event: any) => void;
  onMouseEnter?: (record: T, event: any) => void;
  onMouseLeave?: (record: T, event: any) => void;
  service: EntityService<T, any>;
  autoSave?: boolean;
  isTree: boolean;
  isEdit?: boolean;
  editAfterAdd?: boolean;
  shrinkToFit?: boolean;
  multiSelect?: 'legacy' | 'row';
  nonPagination?: boolean;
  hiddenIndex?: boolean;
  strictlyRemove?: boolean; // 在严格模式下删除时，如果删除掉p的子节点导致p没有children那么会将p一起删除，主要用于工程量签证
  beforeEdit?: (record: T) => boolean;
  beforeAdd?: (record: T) => Promise<any> | void;
  beforeRemove?: (record: T) => Promise<any> | void;
  afterAdd?: (record: T) => void;
  beforeCellEdit?: (record: T, column: EditableColumnProps<T>) => void;
  query?: Partial<T & BaseQuery>;
  pageSize?: number;
  type?: 'remote' | 'local';
  loadData: (query: any) => Promise<ResultBean<PageBean<T>>>;
  loadComplete?: (list: T[]) => void;
  getSelectedKeys?: (dataSource: T[]) => Promise<Key[]>;
  toolbar?: Partial<Omit<TableToolbarProps<T>, 'isEdit'>>;
}

export declare interface EditableTableState<T extends BaseEntity<T>> {
  expandedRowKeys: Key[];
  selectedRowKeys: Key[];
  dataSource: T[];
  useTime: number;
  totalTime: number;
  total: number;
  pageSize: number;
  current: number;
  query?: Record<string, any>;
  tableHeight: number;
  resized: boolean;
  names?: string[];
  summary?: T;
}

export default class EditableTable<T extends BaseEntity<T> = any> extends React.PureComponent<
  EditableTableProps<T>,
  EditableTableState<T>
> {
  readonly state = {
    expandedRowKeys: [],
    selectedRowKeys: [],
    dataSource: [],
    useTime: 0,
    totalTime: 0,
    total: 0,
    pageSize: this.props.pageSize || 30,
    query: this.props.query || {},
    current: 1,
    tableHeight: 400,
    resized: false,
    names: undefined,
    summary: undefined
  };

  private indexDefaultWidth = 30;

  public util: TableUtil<T> = new TableUtil(this);

  public originColumns: EditableColumnProps<T>[];

  private tableRef = React.createRef<HTMLDivElement>();

  public map: Map<T, RefObject<FormInstance>> = new Map<T, RefObject<FormInstance>>();

  private currentColumns: EditableColumnProps<T>[] = [];

  private getIndexColumn = (startIndex: number, width: number) => ({
    label: '#',
    name: 'tableIndex',
    align: 'center',
    fixed: 'left',
    ellipsis: false,
    editable: false,
    sorter: false,
    width: width + this.indexDefaultWidth,
    render: (_text: string, _record: T, index: number) => (index !== undefined ? startIndex + index + 1 : '')
  });

  readonly defaultColumnProps: TableProps<T> = {
    rowKey: ({ id, name }) => id || name || '',
    bordered: true,
    size: 'small',
    onRow: record => ({
      record,
      editing: this.util.isEditing(record),
      onClick: event => this.onClick(record, event),
      onDoubleClick: event => this.onDoubleClick(record, event),
      onRowMount: (formRef: RefObject<FormInstance>) => this.map.set(record, formRef)
    }),
    rowClassName: record =>
      classNames({
        'wrapped-table-row-highlight': this.util.isSelected(record),
        'wrapped-table-row-editing': this.util.isEditing(record)
      })
  };

  constructor(props: EditableTableProps<T>) {
    super(props);
    this.originColumns = cloneDeep(this.props.columns)
      .filter(({ hidden = false }) => !hidden)
      .map(this.preStatement);
  }

  preStatement = (row: EditableColumnProps<T>) => {
    const { render, formatter, formatOption = {}, editType } = row;
    if (editType === 'radio') {
      row.editType = 'select';
    }

    if (!render) {
      row.render = value => FormatUtil.format(value, formatter, formatOption);
    }
    return row;
  };

  onClick = async (record: T, event: React.MouseEvent) => {
    this.props.onClick?.(record, event);
    this.util.setSelected(record, event);
  };

  onDoubleClick = async (record: T, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { onDoubleClick, isEdit = false, beforeEdit } = this.props;
    if (onDoubleClick) {
      return this.props.onDoubleClick?.(record, event);
    }
    const el: HTMLElement = event.nativeEvent.target as HTMLElement;
    if (isEdit && ['TR', 'TD'].includes(el.nodeName) && beforeEdit?.(record) !== false) {
      if (this.util.isEditing(record)) {
        Object.assign(record, await this.map.get(record)?.current?.validateFields());
      }

      this.util.modify(record);
    }
  };

  columns = (): any => {
    const { isTree, beforeCellEdit, hiddenIndex = false } = this.props;
    const { pageSize = 15, current = 1, names } = this.state;
    const columns =
      names === undefined
        ? this.originColumns
        : this.originColumns.filter(({ name }) => (names! as string[]).includes(name));
    const cols = columns.map((column, index) => ({
      ...column,
      dataIndex: column.name,
      title: LocaleUtil.get(column.code, column.label),
      index,
      onHeaderCell: (column: EditableColumnProps<T>) => ({
        width: column.width,
        onResize: this.handleResize(index)
      }),
      onCell: (record: T, rowIndex: number) => {
        const { onCell } = column;
        const cellProps = onCell?.(record, rowIndex) || {};
        return {
          record,
          index,
          column,
          beforeCellEdit,
          util: this.util,
          editing: this.util.isEditing(record),
          ...cellProps
        };
      }
    }));

    const start = (current - 1) * pageSize;
    const indexWidth = start >= 100 ? (start.toString().length - 2) * 12 : 0;
    const list = isTree || hiddenIndex ? cols : [this.getIndexColumn(start, indexWidth), ...cols];

    return this.group(list as any);
  };

  group = (columns: EditableColumnProps<T>[]) => {
    const { groupHeaders = [] } = this.props;
    let cols = cloneDeep(columns);
    const headers = groupHeaders.sort((a, b) => a.level - b.level);
    for (const header of headers.values()) {
      const [index, column] = this.getGroupStart(cols, header.startColumnName);

      if (index !== -1) {
        if (column == null) {
          cols = this.groupColumns(cols, header, index);
        } else {
          column.children = this.groupColumns(column.children as any, header, index) as any;
        }
      }
    }
    return cols;
  };

  getGroupStart = (columns: EditableColumnProps<T>[], name: string): any => {
    for (const [index, column] of columns.entries()) {
      const list = column.children!;
      if (list) {
        const [i] = this.getGroupStart(list, name);
        if (i !== -1) {
          return [i, column];
        }
      }
      if (column.name === name) {
        return [index, null];
      }
    }

    return [-1, null];
  };

  groupColumns = (columns: EditableColumnProps<T>[], header: GroupHeader, start: number): EditableColumnProps<T>[] => {
    const cols: EditableColumnProps<T>[] = [];
    const children: EditableColumnProps<T>[] = [];
    const end = header.numOfColumns + start - 1;

    columns.forEach((column, i) => {
      if (i < start || i > end) {
        cols.push(column);
      } else {
        children.push(column);
        if (i === end) {
          cols.push({
            title: header.title,
            children,
            index: start
          } as any);
        }
      }
    });

    return cols;
  };

  expandColumns = (columns: EditableColumnProps<T>[]) => {
    const cols: EditableColumnProps<T>[] = [];
    for (const column of columns.values()) {
      const list: Array<any> = column.children!;
      if (!column.children || list?.length === 0) {
        cols.push(column);
      } else {
        this.expandColumns(list)?.forEach(t => cols.push(t));
      }
    }

    return cols;
  };

  // latestQuery === undefined 使用上次的参数查询，否则使用最新的参数
  onChange = async (latestQuery?: any, callback?: () => void) => {
    const { loadData, loadComplete, multiSelect, getSelectedKeys, query } = this.props;
    const { pageSize, current, selectedRowKeys } = this.state;
    const currentTime = Date.now();
    try {
      const currentQuery = {
        pageSize,
        current,
        ...query,
        ...(latestQuery || this.state.query)
      };

      const { code, data, useTime } = await loadData(currentQuery);
      let keys: Key[] = selectedRowKeys;
      if (multiSelect) {
        keys = (await getSelectedKeys?.(data.list || [])) || [];
      }

      if (code === 200) {
        this.setState(
          {
            dataSource: data.list || [],
            summary: data.summary,
            useTime,
            totalTime: Date.now() - currentTime,
            total: data.total,
            pageSize: data.pageSize,
            current: data.pageNum,
            query: { ...currentQuery },
            selectedRowKeys: keys
          },
          () => {
            callback?.();
            loadComplete?.(data.list || []);
          }
        );
      }
    } catch (e) {
      console.error(e);
      message.error('刷新数据错误');
    }
  };

  renderButton = (href: string, title: string) => (
    <button type="button" title="2" style={{ marginRight: 0 }} className="ant-pagination-item">
      <a href={href}>{title}</a>
    </button>
  );

  itemRender = (current: number, type: string, originalElement: any) => {
    const href = '#';
    switch (type) {
      case 'next':
        return this.renderButton(href, '下一页');
      case 'prev':
        return this.renderButton(href, '上一页');
      default:
        return originalElement;
    }
  };

  totalRender = (total: number) => {
    const { useTime, totalTime, selectedRowKeys } = this.state;
    return (
      <span>
        {selectedRowKeys.length > 0 ? (
          <>
            已选中
            <span style={{ color: 'red' }}>&nbsp;{selectedRowKeys.length}&nbsp;</span>
            条记录&nbsp;&nbsp;，
          </>
        ) : (
          <></>
        )}
        本页
        <span style={{ color: 'red' }}>&nbsp;{this.state.dataSource.length || 0} &nbsp;</span>
        条记录， 共计
        <span style={{ color: 'green' }}>&nbsp;{total}&nbsp;</span>
        条记录，
      </span>
    );
  };

  onExpandedRowsChange = (expandedRowKeys: readonly Key[]) => {
    this.setState({ expandedRowKeys: [...expandedRowKeys] });
  };

  expandByLevel = (level: number) => {
    const { dataSource } = this.state;
    this.setState({ expandedRowKeys: this.getExpandKeys(dataSource, level) });
  };

  setDataSource = (dataSource: T[], callback?: () => void) => {
    const { current } = this.state;
    this.setState(
      {
        dataSource: [...dataSource],
        current: current || 1
      },
      callback
    );
  };

  expandByKey = (key: never) => {
    const { expandedRowKeys = [] } = this.state;
    if (!expandedRowKeys.includes(key)) {
      this.setState({ expandedRowKeys: [...expandedRowKeys, key] });
    }
  };

  getExpandKeys = (list: T[], level: number): Key[] => {
    const keys: Key[] = [];

    list.forEach(data => {
      const currentLevel = data?.level || 0;
      if (currentLevel < level) {
        keys.push(data?.id || '');
      }

      const children: T[] = data.children || [];
      if (children.length > 0) {
        keys.push(...this.getExpandKeys(children, level));
      }
    });

    return keys;
  };

  async componentDidMount() {
    const { type = 'remote' } = this.props;
    if (type === 'remote') {
      await this.onChange();
    }
  }

  handleResize = (index: number) => (e: any, { size }: any) => {
    console.log(index, size);
    this.originColumns[index].width = size.width;
    this.forceUpdate();
  };

  tableOnChange = (query: any) => {
    const { pageSize, current, pageNum, ...rest } = this.state.query as any;
    this.setState({ query: { pageSize: query.pageSize, current: query.current, ...rest } }, () => this.onChange());
  };

  onSelectChange = (selectedRowKeys: Key[]) => {
    this.setState({ selectedRowKeys });
  };

  onResize = ({ height, width }: SizeInfo) => {
    const div = this.tableRef?.current;
    const { nonPagination, multiSelect } = this.props;
    if (!this.state.resized && height !== 0) {
      const minFooterHeight = nonPagination ? 0 : 30;
      const toolbarHeight = div?.querySelector('.muyu-table-toolbar')?.clientHeight || 24;
      const headerHeight = div?.querySelector('.ant-table-header')?.clientHeight || 30;
      const footerHeight = div?.querySelector('.ant-table-summary')?.clientHeight || minFooterHeight;
      this.setState({ tableHeight: height - toolbarHeight - headerHeight - footerHeight, resized: true });
    }
    let totalWidth = this.currentColumns.map(({ width }) => width).reduce((a = 0, b = 0) => a + b) || 0;
    if (multiSelect) {
      totalWidth += 20;
    }

    if (width !== 0 && totalWidth < width && this.currentColumns.length > 0) {
      const body = div?.querySelector('.ant-table-body') as HTMLElement;
      const table = body?.querySelector('table') as HTMLElement;
      const bodyHeight = table?.clientHeight || 0;
      const bodyScrollHeight = table?.scrollHeight || 0;
      const scrollWidth = bodyScrollHeight > bodyHeight ? (body?.offsetWidth || 0) - (body?.clientWidth || 0) : 10;
      const indexWidth = this.currentColumns[0].name === 'tableIndex' ? this.currentColumns[0].width || 0 : 0;
      const restWidth = width - indexWidth - 10 - scrollWidth;
      const restTotalWidth = totalWidth - indexWidth;
      const scale = restWidth / restTotalWidth;
      this.originColumns.forEach(column => {
        if (column.name !== 'tableIndex' && column.width !== undefined) {
          column.width *= scale;
        }
      });

      this.forceUpdate();
    }
  };

  render() {
    const {
      toolbar,
      service,
      query,
      isEdit,
      isTree = false,
      type = 'remote',
      multiSelect,
      nonPagination,
      expandable = {}
    } = this.props;
    const { expandedRowKeys, selectedRowKeys, dataSource, total, pageSize, current, tableHeight, summary } = this.state;
    const disabled = nonPagination!;
    const pageDisable = disabled !== undefined ? disabled : isTree;

    const pagination = {
      total,
      showQuickJumper: true,
      itemRender: this.itemRender,
      showTotal: this.totalRender,
      defaultPageSize: pageSize,
      current,
      pageSize,
      pageSizeOptions: ['20', '30', '50', '100', '200']
    };

    const expandableProps = {
      expandedRowKeys,
      onExpandedRowsChange: this.onExpandedRowsChange,
      expandRowByClick: false,
      ...expandable
    };

    const components = {
      header: {
        cell: ResizableTitle
      },
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };
    this.currentColumns = this.columns();
    const props: TableProps<T> = {
      ...this.defaultColumnProps,
      dataSource,
      rowSelection:
        multiSelect === 'row'
          ? {
              selectedRowKeys,
              onChange: this.onSelectChange,
              preserveSelectedRowKeys: true
            }
          : undefined,
      onChange: type === 'remote' ? this.tableOnChange : undefined,
      pagination: pageDisable ? false : (pagination as any),
      expandable: expandableProps,
      components,
      columns: this.currentColumns as any,
      size: 'small',
      scroll: {
        y: tableHeight
      },
      className: classNames({
        'tree-table': isTree,
        'table-legacy-multi-select': multiSelect === 'legacy',
        'table-none-pagination': pageDisable
      }),
      summary:
        summary && dataSource?.length > 0
          ? () => <TableSummary summary={summary} columns={this.expandColumns(this.currentColumns)} />
          : undefined
    };

    return (
      <ResizeObserver onResize={this.onResize}>
        <div
          style={{ flex: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
          className="muyu-editable-table"
          ref={this.tableRef}
        >
          <TableToolbar service={service} isEdit={isEdit} util={this.util} {...toolbar} query={{ ...(query as any) }} />
          <Table<T> {...props} />
        </div>
      </ResizeObserver>
    );
  }
}
