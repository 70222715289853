import { Act } from '@/service/process/ProcessService';
import BaseService, { BaseEntity, BaseQuery, PageBean, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';

export interface TaskExt extends BaseEntity<TaskExt> {
  no: string;
  name: string;
  contractId: string;
  contractName: string;
  procName: string;
  procDefKey: string;
  procInstId: string;
  taskId: string;
  taskName: string;
  taskDefKey: string;
  assigneeId: string;
  assigneeName: string;
  base64Image: string;
  assigneeRoleName: string;
  assigneeSignatureUrl: string;
  businessId: string;
  businessNo: string;
  businessName: string;
  beginDate: string;
  endDate: string;
  claimDate: string;
  duration: string;
  status: number;
  comment: string;
}

export interface TaskDTO<T> {
  businessId: string;
  businessNo: string;
  businessName: string;
  taskId: string;
  taskName: string;
  nextClaimId?: string;
  nextClaimName?: string;
  nextTaskDefKey?: string;
  event: number;
  comment?: string;
  vars?: Record<string, unknown>;
  data: T;
}

export declare type TaskExtQuery = TaskExt & BaseQuery;

export declare interface TaskDelegateDTO {
  taskId: string;
  userId: string;
  comment: string;
}

class TaskExtService extends BaseService<TaskExt, TaskExtQuery> {
  claim = (taskId: string) => this.request<Promise<ResultBean<any>>>('get', `claim?taskId=${taskId}`);

  delegate = (delegateDTO: TaskDelegateDTO) => this.request<Promise<ResultBean<any>>>('post', `delegate`, delegateDTO);

  historicPage = (query: TaskExtQuery) =>
    this.request<ResultBean<PageBean<TaskExt>>>('post', `find-historic-page`, query);

  getRejectList = (procInstId: string, currentTaskDefKey: string) =>
    this.request<ResultBean<TaskExt[]>>(
      'get',
      `get-reject-list?procInstId=${procInstId}&currentTaskDefKey=${currentTaskDefKey}`
    );

  getAct = (businessId: string) => this.request<ResultBean<Act>>('get', `${businessId}`);

  toDoTaskCount = () => this.request<ResultBean<number>>('get', 'to-do-task-count');

  completedTaskCount = () => this.request<ResultBean<number>>('get', 'completed-task-count');

  processAssignee = (procInstId: string) =>
    this.request<ResultBean<number>>('get', `process-assignee?procInstId=${procInstId}`);
}

export default new TaskExtService(API_URL, 'process/task');
