import React from 'react';
import { TableColumn } from '@/service/system/TableColumnService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import WrappedSwitch from '@/component/Form/component/WrappedSwitch';

const columns: EditableColumnProps<TableColumn>[] = [
  {
    label: '名称',
    ellipsis: true,
    editable: true,
    name: 'comment',
    width: 100,
    editOption: {}
  },
  {
    label: '列名',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 150,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '数据库类型',
    name: 'jdbcType',
    ellipsis: true,
    editable: false,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '实体类型',
    name: 'javaType',
    ellipsis: true,
    editable: true,
    width: 180,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '实体名称',
    name: 'fieldName',
    ellipsis: true,
    editable: true,
    width: 150,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '可为空',
    ellipsis: true,
    editable: true,
    name: 'isNull',
    editType: 'switch',
    align: 'center',
    render: (status: boolean) => <WrappedSwitch disabled value={status} />,
    editOption: {},
    width: 60
  },
  {
    label: '可编辑',
    ellipsis: true,
    editable: true,
    name: 'isEdit',
    editType: 'switch',
    align: 'center',
    render: (status: boolean) => <WrappedSwitch disabled value={status} />,
    editOption: {},
    width: 60
  },
  {
    label: '可查询',
    ellipsis: true,
    editable: true,
    name: 'isQuery',
    editType: 'switch',
    align: 'center',
    render: (status: boolean) => <WrappedSwitch disabled value={status} />,
    editOption: {},
    width: 60
  },
  {
    label: '查询方式',
    ellipsis: true,
    editable: true,
    name: 'queryType',
    formatter: 'select',
    formatOption: { type: 'table.column.query.type' },
    editType: 'select',
    editOption: { type: 'table.column.query.type' },
    align: 'center',
    width: 120
  },
  {
    label: '显示方式',
    ellipsis: true,
    editable: true,
    name: 'showType',
    formatter: 'select',
    formatOption: { type: 'table.column.edit.type' },
    editType: 'select',
    editOption: { type: 'table.column.edit.type' },
    align: 'center',
    width: 120
  },
  {
    label: '字典类型',
    ellipsis: true,
    editable: true,
    name: 'dictType',
    align: 'left',
    editOption: {},
    width: 200
  },
  {
    label: '编辑方式',
    ellipsis: true,
    editable: true,
    name: 'editType',
    formatter: 'select',
    formatOption: { type: 'table.column.edit.type' },
    editType: 'select',
    editOption: { type: 'table.column.edit.type' },
    align: 'center',
    width: 120
  },
  {
    label: '对齐方式',
    ellipsis: true,
    editable: true,
    name: 'align',
    formatter: 'select',
    formatOption: { type: 'table.column.align.type' },
    editType: 'select',
    editOption: { type: 'table.column.align.type' },
    align: 'center',
    width: 120
  },
  {
    label: '长度',
    ellipsis: true,
    editable: true,
    name: 'length',
    align: 'right',
    editOption: {},
    width: 80
  },
  {
    label: '小数',
    ellipsis: true,
    editable: true,
    name: 'scale',
    align: 'right',
    editOption: {},
    width: 80
  },
  {
    label: '排序',
    ellipsis: true,
    editable: true,
    name: 'sort',
    editOption: {},
    width: 80
  }
];

export default columns;
