import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import { Dictionary } from '@/common/types';
import dayjs from 'dayjs';
import { systemName } from '@/common/common';
import LocalStorageUtil from './LocalStorageUtil';
import LocaleService from '../service/system/SysLocaleService';
import DictUtil from './DictUtil';

class LocaleUtils {
  key = 'zh-CN';

  locales: string[] = ['zh-CN', 'en-US'];

  locale: Dictionary<string> = {};

  useLocale = () => false;

  getLanguage = (): string => {
    const lang: string = LocalStorageUtil.getItem(this.key) || 'zh-CN';
    return this.locales.includes(lang) ? lang : 'zh-CN';
  };

  loadDefaultLocale = () => this.setLocale(this.getLanguage());

  setLocale = async (locale: string) => {
    const { code, data } = await LocaleService.getLocale({ lang: locale });
    if (code === 200) {
      this.locale = data;
      document.title = systemName;
      LocalStorageUtil.setItem(this.key, locale);
      LocalStorageUtil.setItem(locale, data);
    } else {
      const data = LocalStorageUtil.getItem(locale);
      if (data != null) {
        this.locale = data as any;
      }
    }
  };

  getByDict = (dictType: string, dictValue: string) => this.get(DictUtil.getByValue(dictType, dictValue).code);

  get = (key = '', defaultValue = '') => {
    if (this.useLocale()) {
      return this.locale[key] || defaultValue;
    }

    return defaultValue;
  };

  compile = (key = '', ...args: string[]) => this.template(this.locale[key], ...args);

  template = (tpl: string, ...args: any[]) => {
    let result = tpl;
    args.forEach((arg, i) => {
      result = result.replace(`{${i}}`, arg);
    });

    return result;
  };

  getAntdLocale = () => {
    const locale = this.getLanguage();
    switch (locale) {
      case 'en-US':
        dayjs.locale('en', require('dayjs/locale/es-us'));
        break;
      case 'zh-CN':
      default:
        dayjs.locale('zh-cn', require('dayjs/locale/zh-cn'));
    }
    return locale === 'zh-CN' ? zhCN : enUS;
  };
}

export default new LocaleUtils();
