import React from 'react';
import {
  ApartmentOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ForkOutlined,
  PlusOutlined,
  RocketOutlined,
  UploadOutlined
} from '@ant-design/icons/lib';
import { message } from 'antd';
import ModelService, { Model, ModelQuery } from '@/service/process/ModelService';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import TableUtil from '@/component/Table/Util/TableUtil';
import { openURL } from '@/common/common';
import { ToolbarOptionItem } from '@/component/Toolbar';
import FileUpload from '@/view/component/FileUpload/FileUpload';
import { cloneDeep } from 'lodash-es';
import ModelForm from './Form';
import columns from './columns';

export declare interface ModelListProps {
  onClick: (record: Model, event: any) => void;
}

export declare interface ModelListState {
  query: Record<string, unknown>;
  record?: Model;
  refresh: boolean;
}

export default class List extends React.PureComponent<ModelListProps, ModelListState> {
  tableRef = React.createRef<EditableTable<Model>>();

  state = {
    query: {},
    refresh: false
  };

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || {};
    this.edit('模型新增', { ...(record as Model), id: null } as any);
  };

  edit = (title: string, record: Model) => {
    Dialog.open({
      title,
      children: <ModelForm record={cloneDeep(record)} />,
      width: 600,
      afterOk: this.getUtil().refresh
    });
  };

  delete = async () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('模型删除', '确定要删除数据吗', async () => {
      const bean = await ModelService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  design = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要设计的模型');
    }

    openURL(`/modeler.html?modelId=${record.id}`, record.name);
  };

  deploy = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要发布的模型');
    }

    Dialog.confirm('流程发布', '确定要发布该流程吗', async () => {
      const bean = await ModelService.deploy(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
      }

      return bean;
    });
  };

  upgrade = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要升级的流程');
    }

    Dialog.confirm('流程升级', '确定要升级该流程吗', async () => {
      const bean = await ModelService.upgrade(record.key);
      if (bean.code === 200) {
        message.success(bean.msg);
      }

      return bean;
    });
  };

  import = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要导入的模型');
    }

    const props = {
      url: '/process/model/upload',
      data: { id: record.id }
    };

    Dialog.open({
      title: '模型导入',
      children: <FileUpload {...props} />,
      width: 500,
      afterOk: this.getUtil().refresh
    });
  };

  export = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要导出的模型');
    }

    return ModelService.export(record.id, `流程-【${record.name}】.bpmn20.xml`);
  };

  getUtil = (): TableUtil<Model> => this.tableRef.current?.util as TableUtil<Model>;

  onQuery = (query: ModelQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <ApartmentOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.design,
      code: 'design',
      title: '设计'
    },
    {
      type: 'yellow',
      icon: <UploadOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.import,
      code: 'import',
      title: '导入'
    },
    {
      type: 'yellow',
      icon: <DownloadOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.export,
      code: 'export',
      title: '导出'
    },
    {
      type: 'yellow',
      icon: <RocketOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.deploy,
      code: 'deploy',
      title: '发布'
    },
    {
      type: 'yellow',
      icon: <ForkOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.upgrade,
      code: 'upgrade',
      title: '升级'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<Model>
          ref={this.tableRef}
          service={ModelService}
          autoSave
          columns={columns}
          onDoubleClick={record => this.edit('模型修改', record)}
          loadData={ModelService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
