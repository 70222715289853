import React from 'react';
import { Dialog, Toolbar } from '@/component';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { DownloadOutlined, FilePdfOutlined, FileTextOutlined, PrinterOutlined } from '@ant-design/icons/lib';

export type PdfHandler = (title: string, url: string, query?: Record<string, unknown>) => void;
export type ExportHandler = (name: string) => any;

export default class ItemBuilder {
  private readonly pdfHandler: PdfHandler;

  private readonly exportHandler: ExportHandler;

  constructor(pdfHandler: PdfHandler, exportHandler: ExportHandler) {
    this.pdfHandler = pdfHandler;
    this.exportHandler = exportHandler;
  }

  public getItems = () => this.items;

  private items: ToolbarOptionItem[] = [
    {
      type: 'yellow',
      icon: <PrinterOutlined />,
      onClick: () =>
        Dialog.open({
          title: '核销打印',
          children: <Toolbar items={this.printItems} />,
          width: 800
        }),
      code: 'print',
      title: '打印'
    },
    {
      type: 'yellow',
      icon: <DownloadOutlined />,
      onClick: () =>
        Dialog.open({
          title: '核销导出',
          children: <Toolbar items={this.exportItems} />,
          width: 800
        }),
      code: 'export',
      title: '导出'
    }
  ];

  private printItems: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('汇总表', '/report/verification/summary'),
      code: 'summary',
      title: '汇总表'
    },
    {
      type: 'yellow',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('封面', '/report/verification/cover'),
      code: 'cover',
      title: '封面'
    },
    {
      type: 'yellow',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('封面', '/report/verification/comment'),
      code: 'comment',
      title: '说明'
    }
  ];

  private exportItems: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <FileTextOutlined />,
      onClick: () => this.exportHandler('summary'),
      code: 'summary',
      title: '汇总表'
    }
  ];
}
