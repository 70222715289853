import React from 'react';
import AreaService, { SysArea } from '@/service/system/SysAreaService';
import { Dialog, EditableTable, Toolbar } from '@/component';
import { buildUrl } from '@/service/url';
import QuantityService from '@/service/repository/QuantityService';
import FileUpload from '@/view/component/FileUpload/FileUpload';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { UploadOutlined } from '@ant-design/icons';
import TableUtil from '@/component/Table/Util/TableUtil';
import UserUtil from '@/util/UserUtil';
import columns from './columns';

export declare interface AreaListProps {
  onClick: (record: SysArea, event: any) => void;
}

export declare interface AreaListState {
  record?: SysArea;
}

export default class List extends React.PureComponent<AreaListProps, AreaListState> {
  tableRef = React.createRef<EditableTable<SysArea>>();

  getUtil = (): TableUtil<SysArea> => this.tableRef.current?.util as TableUtil<SysArea>;

  import = () => {
    const props = {
      url: buildUrl('api', '/sys-area/upload'),
      getTemplate: QuantityService.getTemplate,
      data: {}
    };

    Dialog.open({
      title: '工程量清单-导入',
      children: <FileUpload {...props} />,
      width: 500,
      afterOk: this.getUtil().refresh
    });
  };

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <UploadOutlined />,
      permission: 'ROLE_SUPER_ADMIN',
      onClick: this.import,
      code: 'import',
      title: '导入'
    }
  ];

  render() {
    const isEdit = UserUtil.hasAnyRole('ROLE_SUPER_ADMIN');
    return (
      <>
        <Toolbar items={this.items} />
        <EditableTable<SysArea>
          ref={this.tableRef}
          service={AreaService}
          autoSave
          columns={columns}
          isEdit
          isTree
          toolbar={{ expandNum: 8, isMove: isEdit, isShift: isEdit, isReload: true }}
          loadData={AreaService.findTree}
        />
      </>
    );
  }
}
