import React from 'react';
import { Layout, Tabs } from 'antd';
import './home.less';
import { SysMenu } from '@/service/system/SysMenuService';
import { connect } from 'react-redux';
import store, { StoreState } from '@/redux/store/configure';
import TabUtil, { TabsAction, TabsProps } from '@/util/TabUtil';
import LocaleUtil from '@/util/LocaleUtil';
import ContentWrapper from '@/view/system/home/content/ContentWrapper';
import { websiteTheme } from '@/theme';
import SideMenu from './sideMenu/SideMenu';
import Navigation from './navigation/Navigation';

const { Header, Sider, Content } = Layout;

export declare interface HomePageProps {
  tabs: { activeKey: string; list: TabsProps[] };
  fade: 0 | 1;
}

export declare interface HomePageState {
  collapsed: boolean;
  menuList?: SysMenu[];
  activeKey?: string;
}

class Home extends React.PureComponent<HomePageProps, HomePageState> {
  state = {
    collapsed: false,
    menuList: [],
    activeKey: undefined
  };

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  setMenuList = (menuList: SysMenu[]) => {
    this.setState({ menuList });
  };

  onChange = (activeKey: string) => {
    TabUtil.active(activeKey);
  };

  onEdit = (targetKey: any, action: 'add' | 'remove') => {
    if (action === 'remove') {
      const { tabs } = this.props;
      TabUtil.close(tabs.list.filter(t => t.id === targetKey)[0]);
    }
  };

  componentDidMount(): void {
    const data: TabsProps = {
      id: 'dashboard',
      href: '/system/dashboard/dashboard',
      name: '系统首页',
      code: 'index',
      closable: false
    };

    store.dispatch<TabsAction>({ type: 'TAB_ADD', data });
  }

  getPage = (props: TabsProps, active: boolean) =>
    TabUtil.get(props.href, { data: props.data, active, locale: active ? LocaleUtil.getLanguage() : '' });

  render() {
    const { collapsed, menuList } = this.state;
    const {
      tabs: { activeKey, list },
      fade
    } = this.props;

    return (
      <Layout>
        <Header
          style={{
            paddingRight: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '46px'
          }}
        >
          <Navigation setMenuList={this.setMenuList} />
        </Header>
        <Layout>
          <Sider theme={websiteTheme} collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
            <SideMenu menuList={menuList} />
          </Sider>
          <Content>
            <Tabs
              hideAdd
              animated
              type="editable-card"
              className="menu-tab"
              activeKey={activeKey}
              onChange={this.onChange}
              onEdit={this.onEdit}
              items={list.map(props => ({
                key: props.id,
                label: LocaleUtil.get(props.code || 'not.found', props.name),
                children: (
                  <ContentWrapper active={activeKey === props.id} activeKey={activeKey} tabProps={props} fade={fade} />
                ),
                closable: props.closable
              }))}
            />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  ({ tabs, fade }: StoreState) => ({ tabs, fade }),
  () => ({})
)(Home);
