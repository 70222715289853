import FormatUtil from '@/util/FormatUtil';
import Decimal from 'decimal.js';
import { EditableColumnProps } from '../../Table/EditableTable';
import validator from './index';

export default (column: EditableColumnProps<any>) => {
  const { min = -1 } = column.editRule || {};
  const { label } = column;

  return {
    validator: (rule: any, value: number) => {
      const valueString = value?.toString();
      if (valueString === undefined) {
        return Promise.resolve();
      }
      const msg = validator.message(
        'min',
        label,
        FormatUtil.format(min.toString(), column.formatter, { precision: 2 })
      );
      return new Decimal(valueString as any).lessThan(min) ? Promise.reject(msg) : Promise.resolve();
    }
  };
};
