import React from 'react';
import { Divider, message, Tag } from 'antd';
import { AppstoreOutlined, TagOutlined } from '@ant-design/icons/lib';
import { SysDict } from '@/service/system/SysDictService';
import DictUtil from '@/util/DictUtil';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import TabUtil from '@/util/TabUtil';
import ProcessService from '@/service/process/ProcessService';
import ContractService, { Contract } from '../../../service/repository/ContractService';
import { EditableTable, WrappedForm } from '../../../component';
import ProcessExtService, { ProcessExt } from '../../../service/process/ProcessExtService';
import './Start.less';

export declare interface StarterState {
  processList: ProcessExt[];
}

export default class Starter extends React.PureComponent<any, StarterState> {
  tableRef = React.createRef<EditableTable<ProcessExt>>();

  list: SysDict[] = DictUtil.get('process.type');

  state = {
    processList: []
  };

  async componentDidMount() {
    const { code, data } = await ProcessExtService.findList({ status: true });
    if (code === 200) {
      this.setState({ processList: data });
    }
  }

  render() {
    return (
      <div className="process-starter-wrapper">
        {this.list.map(({ name, value, type }) => {
          const { processList } = this.state;
          const subList: ProcessExt[] = processList.filter(({ type }: ProcessExt) => type.toString() === value);
          return subList && subList.length > 0 ? (
            <div key={name}>
              <Divider orientation="left">
                <AppstoreOutlined />
                <span style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  {DictUtil.getLocaledNameByValue(type, value)}
                </span>
              </Divider>
              <ItemForm list={subList.sort((a, b) => (a.sort || 1000) - (b.sort || 1000))} />
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    );
  }
}

declare interface ItemFormProps {
  list: ProcessExt[];
}

declare interface ItemFormState {
  columns: EditableColumnProps<ProcessExt>[];
}

class ItemForm extends React.PureComponent<ItemFormProps, ItemFormState> {
  state = {
    columns: []
  };

  static getDerivedStateFromProps(props: ItemFormProps) {
    return {
      columns: props.list.map(
        (process: ProcessExt) =>
          ({
            name: process.name,
            key: process.key,
            editable: true,
            editType: 'custom',
            span: 4,
            editOption: {
              render: () => <Item process={process} />
            }
          } as any)
      )
    };
  }

  render() {
    const { columns } = this.state;
    const props = {
      columns,
      record: {},
      isGrid: true,
      style: { padding: '10px 20px' }
    };

    return columns && columns.length > 0 ? <WrappedForm {...props} /> : <></>;
  }
}

declare interface ItemProps {
  process: ProcessExt;
}

declare interface ItemState {
  hide: boolean;
}

class Item extends React.PureComponent<ItemProps, ItemState> {
  state = {
    hide: true
  };

  hide = (hide: boolean) => this.setState({ hide });

  start = async () => {
    const {
      process: { permission, name, id, key }
    } = this.props;
    let contract: Contract | null = null;
    if (permission !== 3) {
      contract = await ContractService.select();
    }

    const { code, data, msg } = await ProcessService.build(key, contract?.id);
    if (code !== 200) {
      return message.error(msg);
    }

    if (!data.permission.start) {
      return message.error('权限不足');
    }

    TabUtil.open({ href: '/process/process/executor', name, id, data: data as any });
  };

  render() {
    const { key, name } = this.props.process;
    const { hide } = this.state;

    return (
      <div
        className="proc-keys"
        style={{ height: '2rem', display: 'flex', alignItems: 'center' }}
        onMouseEnter={() => this.hide(false)}
        onMouseLeave={() => this.hide(true)}
      >
        <TagOutlined />
        <span key={key} style={{ marginLeft: '10px', marginRight: '10px' }}>
          {name}
        </span>
        {!hide ? (
          <Tag color="#87d068" onClick={this.start}>
            启动
          </Tag>
        ) : null}
      </div>
    );
  }
}
