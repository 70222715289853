import React, { CSSProperties, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons/lib';
import WrappedUpload, { WrappedUploadProps } from '@/component/Upload/WrappedUpload';
import { API_URL } from '@/service/url';
import SysUserService, { SysUser } from '@/service/system/SysUserService';
import { authorization } from '@/service/system/AuthService';

export declare interface UserImageProps {
  user: SysUser;
  imageType: number;
  imageStyle?: CSSProperties;
}

export enum UserImageType {
  PHOTO,
  SIGNATURE
}

const UserImage: React.FC<UserImageProps> = ({
  user: { photo, signature, id },
  imageType,
  imageStyle = {}
}: UserImageProps) => {
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState(imageType === UserImageType.PHOTO ? photo : signature);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  );

  const onChange = async (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      const { code, data } = await SysUserService.get(id);
      if (code === 200) {
        const attachmentId = imageType === UserImageType.SIGNATURE ? data.signature : data.photo;
        if (attachmentId) {
          setImageURL(attachmentId);
        }
      }
    }
  };

  const uploadProps: WrappedUploadProps = {
    action: `${API_URL}/sys-user/upload`,
    accept: 'image/*',
    data: { id, imageType },
    listType: 'picture-card',
    showUploadList: false,
    name: 'file',
    headers: { xtoken: `${authorization.accessToken!}` },
    onChange
  };

  return (
    <WrappedUpload {...uploadProps}>
      {imageURL ? (
        <img
          src={`${API_URL}/sys-attachment/download?id=${imageURL}`}
          alt="avatar"
          style={Object.assign(imageStyle, { width: '100%' })}
        />
      ) : (
        uploadButton
      )}
    </WrappedUpload>
  );
};

export default UserImage;
