import React, { memo } from 'react';
import { TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { WrappedFormItemProps } from '../WrappedFormItem';

const WrappedTimeRange: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => {
  const { column, onChange, record } = props;
  const { editOption } = column;
  const { pattern = 'HH:mm:ss', disabled, prefix } = editOption;
  const values = [
    record[`${prefix}StartTime`] && dayjs(record[`${prefix}StartTime`], pattern),
    record[`${prefix}EndTime`] && dayjs(record[`${prefix}EndTime`], pattern)
  ];
  const mapProps = {
    value: values,
    onChange: (values: Dayjs[], [start, end]: string[]) => {
      onChange?.(start);
      record[`${prefix}EndTime`] = end;
    },
    disabled,
    format: pattern
  }!;

  return <TimePicker.RangePicker {...(mapProps as any)} style={{ width: '100%' }} />;
};

export default memo(WrappedTimeRange, ({ value }, nextProps) => value === nextProps.value);
