import { toTreeData } from '@/common/common';
import SelectUtil from '@/util/SelectUtil';
import FileUtil from '@/util/FileUtil';
import { API_URL } from '../url';
import BaseService, { BaseEntity, BaseQuery, SelectOption } from '../base/BaseService';

export interface Material extends BaseEntity<Material> {
  companyId: string;
  companyName: string;
  no: string;
  name: string;
  unit: string;
  specField1: string;
  specField2: string;
  mass: number;
  price: number;
  amount: number;
  type: number;
  category: number;
  budgetAmount: string;
}

export declare type MaterialQuery = Partial<Material & BaseQuery>;

class MaterialService extends BaseService<Material, MaterialQuery> {
  findTreeData = async (query?: MaterialQuery) => {
    const { code, data } = await this.findTree(query);
    return code === 200 ? toTreeData(data.list) : [];
  };

  select = ({ name = '材料', validator, query }: SelectOption<Material, MaterialQuery> = {}) =>
    SelectUtil.pop({
      name,
      loadTree: () => this.findTreeData(query),
      validator: material => {
        if (!material || material.children) {
          throw new Error(`没有选择${name}`);
        }

        validator?.(material);
      }
    });

  getTemplate = () => FileUtil.download(this.getUrl('export/template'), 'get', '物资导入模板.xlsx');
}

export default new MaterialService(API_URL, 'material/material');
