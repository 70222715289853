import React, { CSSProperties, memo, ReactNode, RefObject } from 'react';
import { FormProps } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import { cloneDeep } from 'lodash-es';
import { WrappedTableProps } from '@/component/Form/component/WrappedTable/Table';
import { WrappedTreeSelectProps } from '@/component/Form/component/WrappedTreeSelect';
import { PopSelectProps } from '@/component/PopSelect/PopSelect';
import NormalForm from '@/component/Form/NormalForm';
import GridForm from '@/component/Form/GridForm';
import { WrappedPopSelectProps } from '@/component/Form/component/WrappedPopSelect';
import { WrappedFormItemProps } from './WrappedFormItem';
import { EditableColumnProps } from '../Table/EditableTable';
import './WrappedForm.less';

type Validator = (rule: any, value: any, callback: (error?: string) => void) => Promise<void> | void;

export declare interface ValidatorRule {
  required?: boolean;
  queryRequired?: boolean;
  integer?: boolean;
  number?: boolean;
  max?: number;
  min?: number;
  mail?: boolean;
  maxLength?: number;
  minLength?: number;
  equal?: string;
  validators?: Validator[];
}

export declare interface WrappedFormProps<T> extends FormProps {
  columns: EditableColumnProps<T>[];
  record?: T;
  mode?: 'simple' | 'normal';
  span?: number;
  ref?: RefObject<FormInstance>;
  isGrid?: boolean;
  readonly?: boolean;
  strict?: boolean;
  spanPerLine?: number;
}

export declare type EditOption = Partial<
  DefaultEditOption &
    Omit<WrappedTreeSelectProps<any> & PopSelectProps, 'onChange' | 'popup' | 'onSelect' | 'getOptions'> &
    WrappedPopSelectProps
>;
interface DefaultEditOption {
  block?: boolean;
  prefix?: any;
  type?: string;
  hideLabel?: boolean;
  hidePlaceHolder?: boolean;
  span?: number;
  render?: (props: WrappedFormItemProps<any> & { ref: React.RefObject<any> }, record: any) => ReactNode;
  disabled?: boolean;
  pattern?: string;
  itemStyle?: CSSProperties;
  treeData?: any[];
  onSearch: (value: string, ref: React.RefObject<FormInstance>) => void;
  onChange?: (
    currentValue: string,
    prevValue: string,
    ref: React.RefObject<FormInstance>,
    record: any,
    column: EditableColumnProps<any>,
    other: any
  ) => void;
  tableOption?: WrappedTableProps;
  uploadUrl?: string; // image
  uploadData?: Record<string, any>; // image
  setRef: (ref: React.Ref<any>) => void;
}

const buildColumns = (props: WrappedFormProps<any>) => {
  const { columns, span = 24, readonly = false, isGrid, strict = false } = props;
  return cloneDeep(columns)
    .filter(({ editable }) => editable)
    .map(column => {
      const { editOption, align } = column;
      column.span = !isGrid ? span : column.span || span;
      column.align = align === 'center' ? 'left' : align;
      if (strict) {
        column.align = 'left';
      }

      if (readonly) {
        editOption.disabled = true;
      }
      return column;
    });
};

const summary = (columns: EditableColumnProps<any>[]): number =>
  columns && columns.length > 0 ? columns.map(({ span = 0 }) => span).reduce((a = 0, b = 0) => a + b || 0) : 0;

const parseGrid = (props: WrappedFormProps<any>, list: EditableColumnProps<any>[]) => {
  const { columns, spanPerLine = 24 } = props;
  const fillSpans = new Array(columns.length).fill(0);
  const rowSpans = new Array(columns.length).fill(0);

  const array: EditableColumnProps<any>[][] = [];
  let subList = [];

  for (let i = 0; i < list.length; i++) {
    const current = array.length;
    const column = list[i];
    const { span = 0, rowSpan = 1, label } = column;
    const sum = summary(subList) + span + rowSpans[current];

    if (Number.isNaN(sum)) {
      throw new Error(`${label} 未定义 span`);
    }

    if (sum > spanPerLine) {
      i--;
      array.push(subList);
      subList = [];
    } else {
      for (let j = 1; j < rowSpan; j++) {
        rowSpans[current + j] += span;
      }

      subList.push(column);
      fillSpans[current] = spanPerLine - summary(subList) - rowSpans[current];

      if (i === list.length - 1) {
        array.push(subList);
      }
    }
  }

  return [array, fillSpans];
};

const parse = (props: WrappedFormProps<any>) => {
  const { isGrid } = props;
  const columns = buildColumns(props);
  return isGrid ? parseGrid(props, columns) : [columns];
};

const WrappedForm: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<WrappedFormProps<any>> & React.RefAttributes<FormInstance>
> = React.forwardRef((props: WrappedFormProps<any>, ref: React.Ref<FormInstance>) => {
  const { columns, record = {}, span, isGrid = false, spanPerLine = 24, readonly, strict, ...rest } = props;
  const [list, fillSpans] = parse(props);
  if (list.length === 0) {
    return <>没有可编辑的列</>;
  }

  return isGrid ? (
    <GridForm ref={ref} columns={list} fillSpans={fillSpans} record={record} {...rest} spanPerLine={spanPerLine} />
  ) : (
    <NormalForm ref={ref} columns={list} record={record} {...rest} />
  );
});

export default memo(WrappedForm);
