import { BaseEntity } from '@/service/base/BaseService';

type KeyOf<T> = (t: T) => string;
type Callback<T> = (t: T) => void;
type Compare<T> = (a: T, b: T) => boolean;
type Filter<T> = (a: T) => boolean;
type MapTo<T, E> = (a: T) => E;

export default class TreeNode<T extends BaseEntity<T>> {
  root: T;

  children?: TreeNode<T>[] = [];

  public constructor(root: T) {
    this.root = root;
  }

  public static of<E extends BaseEntity<E>>(list: E[], builder?: () => E) {
    const root: E = builder?.() || TreeNode.builder();
    root.children = list;
    return new TreeNode<E>(root);
  }

  BFSTraverse = (cb: (t: T) => void) => this._BFSTraverse(this.root, cb);

  clone = () => {
    const root = { ...this.root };
    const node = new TreeNode(root);
    node.BFSTraverse(t => {
      const list = t.children!;
      if (list) {
        t.children = [...list];
      }
    });

    return node;
  };

  filter = (filter: Filter<T>) => {
    const node = this.clone();
    node._filter(filter, node.root);
    return node;
  };

  map = <E extends BaseEntity<E>>(mapTo: MapTo<T, E>) => {
    const node = this.clone();
    node.root = node._map(mapTo, node.root) as any;
    return node as any;
  };

  toMap = (tree: T, keyOf: KeyOf<T>) => {
    const map = new Map<string, T>();
    this.BFSTraverse(t => {
      map.set(keyOf(t), t);
    });
    return map;
  };

  merge = (list: T[], keyOf: KeyOf<T>, compare: Compare<T>, cb?: Callback<T>) => {
    const map = this.toMap(this.root, keyOf);
    this._merge(list, map, compare, cb);
  };

  private static builder = <E extends BaseEntity<E>>() => ({ id: '0', parentId: '', level: 0 } as E);

  private _BFSTraverse = (t: T, cb: (t: T) => void | boolean) => {
    cb(t);
    t.children?.forEach(c => this._BFSTraverse(c, cb));
  };

  private _filter = (filter: Filter<T>, t: T) => {
    const list = t.children || [];
    const result = [];
    for (const c of list) {
      const ret = filter(c) || this._filter(filter, c);
      if (ret) {
        result.push(c);
      }
    }

    t.children = result;
    return result.length > 0;
  };

  private _map = <E extends BaseEntity<E>>(mapTo: MapTo<T, E>, t: T) => {
    const e: E = mapTo(t);
    e.children = t.children?.map(c => this._map(mapTo, c)) as any;
    return e;
  };

  private _merge = (list: T[] = [], map: Map<string, T>, compare: Compare<T>, cb?: Callback<T>) => {
    for (const t of list) {
      const p = map.get(t.parentId as string)!;
      const i = p?.children?.findIndex(a => compare(a, t));
      if (i === -1) {
        this.setChild(t, p!);
        cb?.(t);
      } else {
        this._merge(t.children, map, compare, cb);
      }
    }
  };

  private setChild = (t: T, p: T) => {
    const list = p.children || [];
    const index = this.findIndex(t, list);
    list.splice(index, 0, t);
    p.children = list;
  };

  private findIndex = (t: T, list: T[]) => {
    for (const [i, d] of list.entries()) {
      if (d.sort! > t.sort!) {
        return i;
      }
    }

    return list.length;
  };
}
