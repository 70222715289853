import { ContractItem } from '@/service/repository/ContractItemService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import OfficeService from '@/service/system/SysOfficeService';
import EstimateService from '@/service/repository/EstimateService';
import ProjectService from '@/service/repository/ProjectService';
import SysUserService from '@/service/system/SysUserService';
import { Contract } from '@/service/repository/ContractService';
import EstimateApprovalService from '@/service/repository/EstimateApprovalService';

const columns: EditableColumnProps<ContractItem>[] = [
  {
    label: '基础信息',
    name: 'no',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 24,
    editType: 'label',
    editOption: { hideLabel: true }
  },
  {
    label: '合同编号',
    name: 'no',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 6,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '合同名称',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 300, required: true }
  },
  {
    label: '业主单位',
    name: 'companyName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'company',
      popup: () => OfficeService.selectProprietor(false)
    },
    editRule: { required: true }
  },
  {
    label: '执行部门',
    name: 'executorName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'executor',
      popup: ({ companyId }: Contract) => {
        if (!companyId) {
          throw new Error('业主单位不能为空');
        }

        return OfficeService.select({ department: true, query: { parentIds: companyId, type: 1 } });
      }
    },
    editRule: { required: true }
  },
  {
    label: '设计单位',
    name: 'designCompanyName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'designCompany',
      popup: () => OfficeService.select({ department: false, name: '设计单位', query: { type: 0 } })
    },
    editRule: { required: false }
  },
  {
    label: '工程名称',
    name: 'projectId',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'project',
      popup: ProjectService.select,
      onChange: (projectId, prevId, ref, record) => {
        if (!projectId || projectId !== prevId) {
          ref.current?.setFieldsValue({ estimateId: null });
          delete record.estimateId;
          delete record.estimateName;
        }
      }
    },
    editRule: { required: true }
  },
  {
    label: '投资概算',
    name: 'estimateId',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'estimate',
      popup: ({ projectId }: Contract) => {
        if (!projectId) {
          throw new Error('请先选择工程');
        }

        return EstimateService.select({
          query: { projectId },
          validator: estimate => {
            if (!estimate.leaf) {
              throw new Error('只能选择最低一级投资概算');
            }
          }
        });
      }
    },
    editRule: { required: true }
  },
  {
    label: '批准概算',
    name: 'estimateApprovalName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'estimateApproval',
      popup: ({ projectId }: Contract) => {
        if (!projectId) {
          throw new Error('请先选择工程');
        }

        return EstimateApprovalService.select({
          query: { projectId },
          validator: estimateApproval => {
            if (!estimateApproval.leaf) {
              throw new Error('只能选择最低一级批准概算');
            }
          }
        });
      }
    },
    editRule: {}
  },
  {
    label: '施工单位',
    name: 'devCompanyName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'devCompany',
      popup: () => OfficeService.select({ department: false, name: '施工单位', query: { type: 0 } }),
      onChange: (devCompanyId, prevId, ref) => {
        if (!devCompanyId || devCompanyId !== prevId) {
          ref.current?.setFieldsValue({ devManagerId: null, devManagerName: null });
        }
      }
    },
    editRule: { required: true }
  },
  {
    label: '施工项目经理',
    name: 'devManagerName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'devManager',
      popup: ({ devCompanyId }) => {
        if (!devCompanyId) {
          throw new Error('施工单位不能为空');
        }

        return SysUserService.select({ name: '施工项目经理', query: { companyId: devCompanyId } });
      }
    },
    editRule: {}
  },
  {
    label: '监理单位',
    name: 'supervisorName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'supervisor',
      popup: () => OfficeService.select({ department: false, name: '监理单位', query: { type: 0 } })
    },
    editRule: {}
  },
  {
    label: '咨询单位',
    name: 'consultName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editType: 'pop-select',
    editOption: {
      prefix: 'consult',
      popup: () => OfficeService.select({ department: false, name: '咨询单位', query: { type: 0 } })
    },
    editRule: { required: false }
  },
  {
    label: '序号',
    name: 'serial',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 32 }
  },
  {
    label: '工程标段',
    name: 'section',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 32 }
  },
  {
    label: '类别',
    name: 'category',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 32 }
  },
  {
    label: '类型',
    name: 'type',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    formatter: 'select',
    formatOption: { type: 'contract.type' },
    editType: 'select',
    editOption: { type: 'contract.type' },
    editRule: { required: true }
  },
  {
    label: '执行信息',
    name: '',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 24,
    editType: 'label',
    editOption: { hideLabel: true },
    editRule: { maxLength: 20 }
  },
  {
    label: '结算币种',
    name: 'currencyType',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    formatter: 'select',
    formatOption: { type: 'currency.type' },
    editType: 'select',
    editOption: { type: 'currency.type' },
    editRule: {}
  },
  {
    label: '合同金额',
    name: 'amount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    align: 'right',
    editOption: {},
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '预算金额',
    name: 'budgetAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '执行金额',
    name: 'executeAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '预付款',
    name: 'prePaymentAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '净支付金额（含税）',
    name: 'netPaymentAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '净支付金额（不含税）',
    name: 'netPaymentNonTaxAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '增值税',
    name: 'addedTaxAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '预扣所得税',
    name: 'withHoldingTaxAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '电费扣款',
    name: 'electricAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '材料扣款',
    name: 'materialAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '质保金',
    name: 'retentionAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '预留金',
    name: 'reserveAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '考核金',
    name: 'examineAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '其他应支付',
    name: 'otherPaymentAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '其他应扣款',
    name: 'otherWithholdAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '安全保证金',
    name: 'safeEarnestAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '其他保证金',
    name: 'otherEarnestAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: { disabled: true },
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '招标信息',
    name: 'no',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 24,
    editType: 'label',
    editOption: { hideLabel: true },
    editRule: {}
  },
  {
    label: '中标金额',
    name: 'bidAmount',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '资审方式',
    name: 'qualifyType',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'select',
    formatOption: { type: 'contract.qualify.type' },
    editType: 'select',
    editOption: { type: 'contract.qualify.type' },
    editRule: { maxLength: 50 }
  },
  {
    label: '状态',
    name: 'status',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'select',
    formatOption: { type: 'contract.status' },
    editType: 'select',
    editOption: { type: 'contract.status' },
    editRule: {}
  },

  {
    label: '保修期',
    name: 'warrantyDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: { maxLength: 50 }
  },
  {
    label: '签约日期',
    name: 'signDate',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: {}
  },
  {
    label: '工期',
    name: 'duration',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'number',
    formatOption: { precision: 0, zeroEmpty: true },
    editOption: {},
    editRule: { maxLength: 50, max: 10 ** 5, min: 0 }
  },
  {
    label: '签约地',
    name: 'signPlace',
    ellipsis: true,
    editable: false,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 32 }
  },
  {
    label: '开始日期',
    name: 'startDate',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: {}
  },
  {
    label: '结束日期',
    name: 'endDate',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: {}
  },
  {
    label: '招标编号',
    name: 'bidNo',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 32 }
  },
  {
    label: '招标文件',
    name: 'bidFile',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '招标日期',
    name: 'bidDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: {}
  },

  {
    label: '招标方式',
    name: 'bidType',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'select',
    formatOption: { type: 'contract.bid.type' },
    editType: 'select',
    editOption: { type: 'contract.bid.type' },
    editRule: {}
  },
  {
    label: '招标类型',
    name: 'bidMode',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'select',
    formatOption: { type: 'contract.bid.mode' },
    editType: 'select',
    editOption: { type: 'contract.bid.mode' },
    editRule: {}
  },
  {
    label: '开标日期',
    name: 'bidStartDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: { maxLength: 50 }
  },
  {
    label: '招标结果',
    name: 'bidResult',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '批复结果',
    name: 'bidResultReply',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 100 }
  },
  {
    label: '中标通知书',
    name: 'bidNoticeNo',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '批复日期',
    name: 'replyDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: {}
  },
  {
    label: '批复文号',
    name: 'applyDoc',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 32 }
  },
  {
    label: '申请日期',
    name: 'applyDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'date',
    formatOption: { pattern: 'YYYY-MM-DD' },
    editType: 'date',
    editOption: { pattern: 'YYYY-MM-DD' },
    editRule: {}
  },
  {
    label: '其他',
    name: 'others',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 24,
    editType: 'label',
    editOption: { hideLabel: true }
  },
  {
    label: '联系电话',
    name: 'telephone',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '汇率',
    name: 'exchangeRate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: { max: 10 ** 6, min: -(10 ** 6) }
  },
  {
    label: '调整系数',
    name: 'scaleRate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 6,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: { max: 10 ** 6, min: -(10 ** 6) }
  },
  {
    label: '备注',
    name: 'remarks',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 24,
    editType: 'textarea',
    editOption: { itemStyle: { height: '120px', resize: 'none' }, span: 22 },
    editRule: { maxLength: 20 }
  }
];

export default columns;
