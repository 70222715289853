import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';

export interface ProcessExt extends BaseEntity<ProcessExt> {
  name: string;
  key: string;
  description: string;
  permission: number;
  type: number;
  status: boolean;
}

export declare type ProcessExtQuery = Partial<ProcessExt & BaseQuery>;

class ProcessExtService extends BaseService<ProcessExt, ProcessExtQuery> {
  toModel = (key: string) => this.request<ResultBean<any>>('get', `to-model?key=${key}`);
}

export default new ProcessExtService(API_URL, 'process/process-ext');
