import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';
import { TableColumn } from './TableColumnService';

export interface Table extends BaseEntity<Table> {
  name: string;
  type: string;
  editType: number;
  genType: number;
  packageName: string;
  className: string;
  comment: string;
}

export declare type TableQuery = Partial<Table & BaseQuery>;

export interface TableBatchBean {
  table: Table;
  columnList?: TableColumn[];
}

class TableService extends BaseService<Table, TableQuery> {
  saveBy = (bean: TableBatchBean) => this.request<ResultBean<any>>('post', 'save', bean);

  rebuild = () => this.request<ResultBean<any>>('get', 'create-all-po');

  tableList = async () => {
    const { code, data } = await this.request('get', 'table-list');
    if (code === 200) {
      return data.map((name: string) => ({
        key: name,
        id: name,
        name,
        isLeaf: true
      }));
    }

    return [];
  };
}

export default new TableService(API_URL, 'table');
