import React from 'react';
import { Col, Divider, List, Pagination, Row } from 'antd';
import './Dashboard.less';
import {
  BellOutlined,
  ForkOutlined,
  NotificationOutlined,
  PayCircleOutlined,
  StarOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons/lib';
import TaskExtService, { TaskExt } from '@/service/process/TaskExtService';
import ProcessUtil from '@/util/ProcessUtil';
import DictUtil from '@/util/DictUtil';
import Item, { ItemProps } from '@/view/system/dashboard/Item';
import LocaleUtil from '@/util/LocaleUtil';
import BalanceService from '@/service/runtime/BalanceService';
import * as echarts from 'echarts/core';

export declare interface DashboardProps {
  active: boolean;
}
export declare interface DashboardState {
  list: TaskExt[];
  pageSize: number;
  pageNum: number;
  total?: number;
}

export default class Dashboard extends React.PureComponent<DashboardProps, DashboardState> {
  state = {
    list: [],
    pageSize: 10,
    pageNum: 1,
    total: 1
  };

  chartRef = React.createRef<HTMLDivElement>();

  items: ItemProps[] = [
    {
      title: LocaleUtil.get('to.do', '待处理'),
      icon: <StarOutlined style={{ color: 'white', fontSize: '4rem' }} />,
      color: '#00CCCC',
      handler: TaskExtService.toDoTaskCount
    },
    {
      title: LocaleUtil.get('processed.list', '已处理'),
      icon: <BellOutlined style={{ color: 'white', fontSize: '4rem' }} />,
      color: '#3399CC',
      handler: TaskExtService.completedTaskCount
    },
    {
      title: LocaleUtil.get('current.month.balance', '本月结算'),
      icon: <PayCircleOutlined style={{ color: 'white', fontSize: '4rem' }} />,
      color: '#6633CC',
      handler: BalanceService.currentMonthAmount
    },
    {
      title: LocaleUtil.get('total.balance', '累计结算'),
      icon: <UserOutlined style={{ color: 'white', fontSize: '4rem' }} />,
      color: '#CC3366',
      handler: BalanceService.totalAmount
    }
  ];

  options = {
    xAxis: {
      type: 'category',
      data: ['2021-01', '2021-02', '2021-03', '2021-04', '2021-05', '2021-06']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [1000, 6000, 4555, 7000, 2777, 9000],
        type: 'line',
        smooth: true
      }
    ]
  };

  loadTask = async (current = 1, pageSize = 10) => {
    const { code, data } = await TaskExtService.findPage({ pageSize, current } as any);
    if (code === 200) {
      const { list, pageNum, pageSize, total } = data;
      this.setState({ list, pageNum, pageSize, total });
    }
  };

  onChange = (page: number, pageSize?: number) => this.loadTask(page, pageSize);

  renderChart = () => {
    const div = this.chartRef.current!;
    if (div) {
      console.log(div.clientWidth, div.clientHeight);
      const chart = echarts.init(div);
      chart.setOption(this.options);
    }
  };

  componentDidMount() {
    this.renderChart();
    return this.loadTask();
  }

  componentDidUpdate(prevProps: Readonly<DashboardProps>, prevState: Readonly<DashboardState>, snapshot?: any) {
    if (this.props.active && prevProps.active !== this.props.active) {
      return this.loadTask();
    }
  }

  render() {
    const { list, pageSize, pageNum, total } = this.state;
    const href = '#';
    const dividerStyle = { marginTop: '10px', marginBottom: '10px' };
    return (
      <div style={{ paddingTop: '2em' }} className="dashboard">
        <Row style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <ForkOutlined style={{ color: '#666' }} />
            <span style={{ marginLeft: '2em', color: '#666', fontSize: '14px' }}>
              {LocaleUtil.get('statistic', '统计指标')}
            </span>
            <Divider orientation="left" style={dividerStyle} />
            <List
              grid={{ column: 4, gutter: 30 }}
              dataSource={this.items}
              renderItem={props => (
                <List.Item>
                  <Item {...props} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <TeamOutlined style={{ color: '#666' }} />
                <span style={{ marginLeft: '2em', color: '#666', fontSize: '14px' }}>
                  {LocaleUtil.get('to.do', '待处理')}
                </span>
              </div>
              <Divider orientation="left" style={dividerStyle} />
              {list.map(({ taskId, taskName, beginDate, procName, businessNo, businessId, status }) => (
                <a
                  href={href}
                  onClick={() => ProcessUtil.open(businessNo, businessId)}
                  key={taskId}
                  style={{ color: '#444' }}
                >
                  <Row className="dashboard-task">
                    <Col span={8}>
                      [{DictUtil.getByValue('task.status', status)?.name}]&nbsp;
                      {taskName}
                    </Col>
                    <Col span={8}>{procName}</Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                      {beginDate}
                    </Col>
                    <Divider orientation="left" style={{ marginTop: '-9px', marginBottom: '5px' }} />
                  </Row>
                </a>
              ))}
              <Pagination
                className="mini ant-table-pagination ant-table-pagination-right"
                defaultCurrent={1}
                current={pageNum}
                pageSize={pageSize}
                total={total}
                onChange={this.onChange}
              />
            </div>
          </Col>
          <Col span={12}>
            <NotificationOutlined style={{ color: '#666' }} />
            <span style={{ marginLeft: '2em', color: '#666', fontSize: '14px' }}>
              {LocaleUtil.get('latest.six.month', '最近6个月结算金额（￥）')}
            </span>
            <Divider orientation="left" style={dividerStyle} />
            <div ref={this.chartRef} style={{ height: '400px' }} />
          </Col>
        </Row>
      </div>
    );
  }
}
