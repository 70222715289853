import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';

export declare interface WrappedButtonProps extends Omit<ButtonProps, 'type'> {
  type: 'primary' | 'green' | 'yellow' | 'red' | 'ghost' | 'link' | 'default' | 'dashed' | undefined;
}

const WrappedButton: React.FC<WrappedButtonProps> = (props: WrappedButtonProps) => <Button {...(props as any)} />;

export default WrappedButton;
