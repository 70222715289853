import OfficeService, { SysOffice } from '@/service/system/SysOfficeService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import SysAreaService from '@/service/system/SysAreaService';
import { SelectOptionType } from '@/component/PopSelect/PopSelect';

const columns: EditableColumnProps<SysOffice>[] = [
  {
    label: '父级机构',
    name: 'parentId',
    ellipsis: true,
    editable: true,
    hidden: true,
    query: false,
    width: 200,
    editType: 'pop-select',
    editOption: {
      prefix: 'parent',
      getOptions: async ({ parentId }: SysOffice) => {
        let option: SelectOptionType | undefined;
        if (parentId && parentId !== '0') {
          const { code, data } = await OfficeService.get(parentId);
          if (code === 200) {
            option = {
              name: data.name,
              value: data.id
            };
          }
        }

        return option ? [option] : [];
      },
      popup: OfficeService.select
    },
    editRule: { required: true }
  },
  {
    label: '名称',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    formatOption: { trim: true },
    editOption: {},
    editRule: { required: true, maxLength: 100 }
  },
  {
    label: '区域',
    name: 'areaId',
    code: 'area',
    ellipsis: true,
    editable: true,
    hidden: false,
    query: false,
    width: 160,
    editType: 'cascader',
    editOption: {
      prefix: 'area',
      getOptions: SysAreaService.findTreeData
    },
    render: (_value, record) => record.areaName,
    editRule: {}
  },
  {
    label: '国际化编码',
    name: 'code',
    code: 'locale.code',
    ellipsis: true,
    editable: true,
    query: false,
    width: 200,
    editOption: { span: 16 },
    editRule: { maxLength: 50 }
  },
  {
    label: '类型',
    name: 'type',
    code: 'type',
    ellipsis: true,
    editable: true,
    width: 80,
    formatter: 'select',
    editType: 'radio',
    formatOption: { type: 'office.type' },
    editOption: { type: 'office.type' },
    editRule: { required: true },
    align: 'center'
  },
  {
    label: '级别',
    name: 'grade',
    code: 'grade',
    ellipsis: true,
    editable: true,
    width: 80,
    formatter: 'select',
    editType: 'radio',
    formatOption: { type: 'office.grade' },
    editOption: { type: 'office.grade' },
    align: 'center',
    editRule: { required: true }
  },
  {
    label: '地址',
    name: 'address',
    code: 'address',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { maxLength: 100 }
  },
  {
    label: '电话',
    name: 'phone',
    code: 'phone',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '是否业主',
    name: 'proprietor',
    code: 'proprietor',
    hidden: true,
    ellipsis: true,
    editable: true,
    width: 100,
    formatter: 'switch',
    editType: 'switch',
    formatOption: { type: 'sys.bool', zeroEmpty: true },
    editOption: { type: 'sys.bool' },
    editRule: { maxLength: 20 },
    align: 'center'
  },
  {
    label: '排序号',
    ellipsis: true,
    editable: true,
    hidden: true,
    name: 'sort',
    code: 'sort',
    width: 80,
    align: 'right',
    editOption: {}
  }
];

export default columns;
