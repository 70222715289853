import React from 'react';
import dictService, { SysDict, SysDictQuery } from '@/service/system/SysDictService';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import TableUtil from '@/component/Table/Util/TableUtil';
import { cloneDeep } from 'lodash-es';
import { ToolbarOptionItem } from '@/component/Toolbar';
import DictForm from './Form';
import columns from './columns';

export declare interface DictListState {
  query: Record<string, unknown>;
  record?: SysDict;
  refresh: boolean;
}

export default class List extends React.PureComponent<any, DictListState> {
  state = {
    query: {},
    refresh: false
  };

  tableRef = React.createRef<EditableTable<SysDict>>();

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || {};
    this.edit('字典新增', { ...record, id: null } as any);
  };

  edit = (title: string, record: SysDict) => {
    Dialog.open({
      title,
      children: <DictForm record={cloneDeep(record)} />,
      width: 800,
      afterOk: this.getUtil().refresh
    });
  };

  delete = async () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('字典删除', '确定要删除数据吗', async () => {
      const bean = await dictService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  getUtil = (): TableUtil<SysDict> => this.tableRef.current?.util as TableUtil<SysDict>;

  onQuery = (query: SysDictQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<SysDict>
          ref={this.tableRef}
          service={dictService}
          autoSave
          columns={columns}
          onDoubleClick={record => this.edit('字典修改', record)}
          loadData={dictService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
