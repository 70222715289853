import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons/lib';
import WrappedUpload, { WrappedUploadProps } from '@/component/Upload/WrappedUpload';
import { API_URL } from '@/service/url';
import { authorization } from '@/service/system/AuthService';
import { WrappedFormItemProps } from '@/component/Form/WrappedFormItem';
import { ResultBean } from '@/service/base/BaseService';
import { Attachment } from '@/service/system/SysAttachmentService';
import Button from '@/component/Button/Button';
import { Image } from 'antd';
import { imageFallBack } from '@/common/common';

const WrappedImage: React.FC<WrappedFormItemProps<any>> = ({
  value,
  onChange,
  column: {
    editOption: { itemStyle = {}, disabled = false, uploadUrl, uploadData = {} }
  }
}: WrappedFormItemProps<any>) => {
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const uploadButton = (
    <div>
      <Button type="default" icon={<UploadOutlined />} style={{ marginTop: 8 }}>
        上传
      </Button>
    </div>
  );

  const onImageChange = async (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      const result: ResultBean<Attachment[] | Attachment> = info.file.response;
      console.log('image upload result', result);
      if (result.code === 200) {
        const attachment = result.data instanceof Array ? result.data[0] : result.data;
        setCurrentValue(attachment.id);
        onChange?.(attachment.id, attachment);
      }
    }
  };

  const uploadProps: WrappedUploadProps = {
    action: uploadUrl || `${API_URL}/sys-attachment/temp-upload`,
    accept: 'image',
    data: uploadData,
    showUploadList: false,
    withCredentials: true,
    name: 'file',
    headers: { xtoken: authorization.accessToken! },
    onChange: onImageChange
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
      <Image
        src={`${API_URL}/sys-attachment/download?id=${currentValue}`}
        style={{ ...{ width: '120px', marginRight: '10px' }, ...itemStyle }}
        fallback={imageFallBack}
      />
      <WrappedUpload {...uploadProps}>{disabled ? <></> : uploadButton}</WrappedUpload>
    </div>
  );
};

export default WrappedImage;
