import React, { memo } from 'react';
import { Divider } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons/lib';
import { WrappedFormItemProps } from '../WrappedFormItem';

const WrappedLabel: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => (
  <span style={{ fontWeight: 'bold' }}>
    <AppstoreOutlined />
    <span style={{ marginLeft: '2em' }}>{props.column.label}</span>
    <Divider style={{ marginTop: '10px', marginBottom: '2px' }} />
  </span>
);

export default memo(WrappedLabel, ({ column: { label } }, nextProps) => label !== nextProps.column.label);
