import React, { ReactElement, useState } from 'react';
import Color from 'color';
import CountUp from 'react-countup';
import { ArrowRightOutlined } from '@ant-design/icons/lib';
import LocaleUtil from '@/util/LocaleUtil';
import { ResultBean } from '@/service/base/BaseService';
import { useMount } from 'ahooks';

export declare interface ItemProps {
  title: string;
  icon: ReactElement;
  color: string;
  handler: () => Promise<ResultBean<number>>;
}

const Item: React.FC<ItemProps> = ({ color, title, icon, handler }: ItemProps) => {
  const [num, setNum] = useState(0);

  useMount(async () => {
    const { data } = await handler();
    setNum(data);
  });

  return (
    <div className="my-index-count" style={{ background: color }}>
      <div className="my-count-header">
        {icon}
        <div>
          <div style={{ fontSize: '28px', textAlign: 'right', color: 'white' }}>
            <CountUp delay={2} end={num || 0} separator="," />
          </div>
          <div style={{ textAlign: 'right', color: 'white' }}>{title}</div>
        </div>
      </div>
      <div className="my-count-footer" style={{ background: Color(color).darken(0.1) as any }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <span style={{ marginRight: '5px', color: 'white', letterSpacing: '1px' }}>{LocaleUtil.get('more', '')}</span>
          {/* <div className="my-more"> */}
          {/*  <ArrowRightOutlined style={{ color: Color(color).darken(0.1) as any }} /> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
export default Item;
