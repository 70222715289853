import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';
import { MixDetail } from './MixDetailService';

export interface Mix extends BaseEntity<Mix> {
  projectId: string;
  projectName: string;
  no: string;
  name: string;
  amount: number;
  budgetAmount: string;
}

export declare type MixQuery = Mix & BaseQuery;

export interface MixBatchBean {
  mix: Mix;
  detailList?: MixDetail[];
}

class MixService extends BaseService<Mix, MixQuery> {
  saveBy = (bean: MixBatchBean) => this.request<ResultBean<any>>('post', 'save', bean);
}

export default new MixService(API_URL, 'repository/mix');
