import React, { createRef } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EnterOutlined,
  RollbackOutlined,
  SaveOutlined
} from '@ant-design/icons/lib';
import { ToolbarOptionItem } from '@/component/Toolbar';
import TabUtil from '@/util/TabUtil';
import TaskExtService, { TaskDTO } from '@/service/process/TaskExtService';
import { BaseEntity, ResultBean } from '@/service/base/BaseService';
import { Act } from '@/service/process/ProcessService';
import DictUtil from '@/util/DictUtil';
import { SysDict } from '@/service/system/SysDictService';
import { Dialog, Toolbar } from '@/component';
import RejectForm from '@/view/process/process/Reject';
import ApproveForm from '@/view/process/process/Approve';
import SysUserService, { SysUser } from '@/service/system/SysUserService';
import SiderAttachment from '@/view/component/SiderAttachment/List';
import ResizeObserver, { SizeInfo } from 'rc-resize-observer';
import './Executor.less';

export declare interface ExecutorProps {
  data: Act;
}

export declare interface ExecutorState {
  act: Act;
  otherToolbars: ToolbarOptionItem[];
}

export const EVENT_START = 0;
export const EVENT_PROCESS = 1;
export const EVENT_SAVE_ONLY = 2;

export const REJECT_TO_APPLICANT = '0';
export const REJECT_TO_PREVIOUS = '-1';
export const APPROVE_TO_NEXT = '1';
export const SUBMIT = '1';
export const APPROVE_TO_END = '100';

export declare type Execute = (bean: TaskDTO<any>) => Promise<ResultBean<BaseEntity<any>>>;
export type PartialTaskDTO = Partial<TaskDTO<any>>;

export default class Executor extends React.PureComponent<ExecutorProps, ExecutorState> {
  procName = this.props.data.task.procName;

  attachment = React.createRef<SiderAttachment>();

  list: SysDict[] = DictUtil.get('process.type');

  execute?: Execute = undefined;

  state = {
    act: this.props.data,
    otherToolbars: []
  };

  build = (data: PartialTaskDTO = {}) => {
    const {
      task: { taskId, taskName, procInstId, procDefKey },
      business: { id }
    } = this.state.act;
    return { taskId, taskName, procInstId, procDefKey, businessId: id, ...data } as any;
  };

  create = () =>
    Dialog.confirm(`【${this.procName}】新建`, `确定要新建【${this.procName}】流程吗?`, () =>
      this.run({ event: EVENT_START }, false)
    );

  save = () =>
    Dialog.confirm(`【${this.procName}】保存`, `确定要保存修改吗?`, () => this.run({ event: EVENT_SAVE_ONLY }, false));

  submit = () => {
    Dialog.success(`【${this.procName}】提交`, `确定要提交到下一级吗?`, async () =>
      this.run({ event: EVENT_PROCESS, vars: { action: SUBMIT } }, true)
    );
  };

  rollback = () =>
    Dialog.warn(`【${this.procName}】撤回`, `确定要将流程撤回吗?`, () =>
      this.run({ event: EVENT_PROCESS, vars: { action: REJECT_TO_APPLICANT } }, false)
    );

  reject = () =>
    Dialog.open({
      title: '流程驳回',
      children: <RejectForm act={this.state.act} run={this.run} />,
      width: 600,
      type: 'error',
      secondConfirm: true
    });

  approve = () =>
    Dialog.open({
      title: '流程批准',
      children: <ApproveForm act={this.state.act} run={this.run} />,
      width: 600,
      type: 'success',
      secondConfirm: true
    });

  run = async (task: PartialTaskDTO, shouldCheckNextClaimer: boolean) => {
    const {
      act: {
        permission: { needNextClaimer },
        business: { id }
      }
    } = this.state;
    if (needNextClaimer && shouldCheckNextClaimer) {
      const user: SysUser = await SysUserService.proprietorSelect();
      Object.assign(task, { nextClaimId: user.id, nextClaimName: user.name });
    }

    const bean = await this.execute?.(this.build(task));
    if (bean?.code === 200) {
      await this.flush(id);
    }
    return bean as any;
  };

  flush = async (businessId: string) => {
    const { code, data } = await TaskExtService.getAct(businessId);
    if (code === 200) {
      this.setState({ act: data });
    }
  };

  claim = () => {
    Dialog.confirm(`【${this.procName}】签收`, `确定要签收流程吗?`, async () => {
      const {
        task: { taskId },
        business: { id }
      } = this.props.data;
      const bean = await TaskExtService.claim(taskId);
      if (bean.code === 200) {
        await this.flush(id);
      }
      return bean;
    });
  };

  setToolbar = (otherToolbars: ToolbarOptionItem[]) => this.setState({ otherToolbars });

  setExecute = (execute: Execute) => {
    this.execute = execute;
  };

  onResize = ({ height }: SizeInfo) => {
    const el: HTMLElement = this.divRef?.current?.querySelector('.ant-tabs-tabpane-active') as any;
    const nav: HTMLElement = this.divRef?.current?.querySelector('.ant-tabs-nav') as any;
    const navHeight = nav.clientHeight;
    el.style.height = `${height - navHeight - 10}px`;
  };

  divRef = createRef<HTMLDivElement>();

  render() {
    const {
      href,
      business,
      permission: { start, claim, submit, modify, approve, reject, rollback, readonly }
    } = this.state.act;
    const items: ToolbarOptionItem[] = [
      {
        type: 'primary',
        icon: <EditOutlined />,
        permission: start,
        onClick: this.create,
        code: 'create',
        title: '新建'
      },
      {
        type: 'primary',
        icon: <CheckOutlined />,
        permission: claim,
        onClick: this.claim,
        code: 'claim',
        title: '签收'
      },
      { type: 'primary', icon: <SaveOutlined />, permission: modify, onClick: this.save, code: 'save', title: '保存' },
      {
        type: 'primary',
        icon: <EnterOutlined />,
        permission: submit,
        onClick: this.submit,
        code: 'submit',
        title: '提交'
      },
      {
        type: 'primary',
        icon: <CheckOutlined />,
        permission: approve,
        onClick: this.approve,
        code: 'approve',
        title: '同意'
      },
      {
        type: 'primary',
        icon: <CloseOutlined />,
        permission: reject,
        onClick: this.reject,
        code: 'reject',
        title: '驳回',
        danger: true
      },
      {
        type: 'primary',
        icon: <RollbackOutlined />,
        permission: rollback,
        onClick: this.rollback,
        code: 'rollback',
        title: '撤回',
        danger: true
      }
    ];

    return (
      <>
        <Toolbar style={{ marginTop: '10px' }} items={items.concat(this.state.otherToolbars)} />
        <ResizeObserver onResize={this.onResize}>
          <div ref={this.divRef} style={{ height: '100%', overflow: 'hidden' }}>
            {TabUtil.get(`/process/executor/${href}/executor`, {
              business,
              act: this.state.act,
              setToolbar: this.setToolbar,
              setExecute: this.setExecute
            })}
          </div>
        </ResizeObserver>
        <SiderAttachment
          ref={this.attachment}
          businessId={business.id}
          businessName={business.name}
          readonly={readonly}
        />
      </>
    );
  }
}
