import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Divider, message } from 'antd';
import UserUtil from '@/util/UserUtil';
import ProjectService, { Project } from '@/service/repository/ProjectService';
import { Dialog, EditableTable, Toolbar, WrappedForm } from '@/component';
import { ResultBean } from '@/service/base/BaseService';
import EstimateService, { Estimate } from '@/service/repository/EstimateService';
import { SaveOutlined } from '@ant-design/icons';
import LocaleUtil from '@/util/LocaleUtil';
import Permission from '@/component/Permission/Permission';
import columns from './columns';
import estimateCols from '../estimate/columns';

export declare interface ProjectFormProps {
  record: Project;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export declare interface ProjectFormState {}

export default class UserForm extends React.PureComponent<ProjectFormProps, ProjectFormState> {
  formRef = React.createRef<FormInstance>();

  tableRef = React.createRef<EditableTable<Estimate>>();

  save = async () => {
    Dialog.confirm('工程信息保存', '确定要保存工程信息更改吗？', async () => {
      const { record } = this.props;
      const data = await this.formRef.current?.validateFields();
      const bean = await ProjectService.saveBy({
        project: Object.assign(record, data),
        estimateList: await this.tableRef.current?.util.serialize()
      });

      if (bean.code === 200) {
        message.success(bean.msg);
      }

      await this.tableRef.current?.util.reload();
      return bean;
    });
  };

  render() {
    const { record } = this.props;
    const readonly = !UserUtil.hasAnyRole('ROLE_SUPER_ADMIN', 'ROLE_PROPRIETOR_CONTRACT_MANAGER');
    const props = {
      columns,
      record,
      isGrid: true,
      strict: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };
    return (
      <>
        <Permission authorities="ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER">
          <Toolbar>
            <Toolbar.Option type="primary" icon={<SaveOutlined />} onClick={this.save}>
              {LocaleUtil.get('save', '保存')}
            </Toolbar.Option>
          </Toolbar>
          <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />
        </Permission>
        <WrappedForm {...props} mode="normal" readonly={readonly} />
        <EditableTable<Estimate>
          ref={this.tableRef}
          service={EstimateService}
          autoSave={false}
          columns={estimateCols}
          isEdit={!readonly}
          isTree
          query={{ projectId: record.id }}
          toolbar={{ expandNum: 0, isSave: false }}
          loadData={EstimateService.findTree}
        />
      </>
    );
  }
}
