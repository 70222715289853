import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import RoleService, { SysRole } from '@/service/system/SysRoleService';
import { WrappedForm } from '@/component';
import { ResultBean } from '@/service/base/BaseService';
import { castTo } from '@/common/common';
import columns from './columns';

export declare interface RoleListProps {
  record: SysRole;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export default class RoleForm extends React.PureComponent<RoleListProps, any> {
  formRef = React.createRef<FormInstance>();

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await RoleService.save(castTo<SysRole>(Object.assign(record, data)));
    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;
    const props = {
      columns,
      record,
      span: 24,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
