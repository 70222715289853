import React from 'react';
import { TaskExt } from '@/service/process/TaskExtService';
import NotFound from '@/view/system/home/notFound/NotFound';
import FormatUtil from '@/util/FormatUtil';
import { Comment } from '@ant-design/compatible';
import { Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons/lib';
import LocaleUtil from '@/util/LocaleUtil';

export declare interface TaskExtListProps {
  list: TaskExt[];
}

export default class Historic extends React.PureComponent<TaskExtListProps, any> {
  render() {
    const { list } = this.props;
    if (!list) {
      return <NotFound />;
    }

    return (
      <div style={{ alignSelf: 'center', overflowY: 'auto', width: '1000px', padding: '10px' }} className="historic">
        <Timeline>
          {list.map(
            ({ taskId, taskName, assigneeName, assigneeRoleName, beginDate, endDate, duration, status, comment }) => (
              <Timeline.Item
                key={taskId || taskName}
                color={endDate ? 'green' : 'blue'}
                dot={!endDate ? <ClockCircleOutlined style={{ fontSize: '16px' }} /> : undefined}
              >
                <h4 style={{ fontWeight: 'bold' }}>{taskName}</h4>
                <Comment
                  author={
                    <span>
                      {assigneeRoleName}&nbsp;&nbsp;{assigneeName}
                    </span>
                  }
                  content={<p>{comment || LocaleUtil.get('none', '')}</p>}
                  datetime={
                    <div className="historic-status">
                      <span>
                        {beginDate}&nbsp;-&nbsp;{endDate || LocaleUtil.get('now', '现在')}
                      </span>
                      {duration ? <span>&nbsp;|&nbsp;{duration}</span> : <></>}
                      <span>
                        &nbsp;|&nbsp;{FormatUtil.format(status.toString(), 'select', { type: 'task.status' })}
                      </span>
                    </div>
                  }
                />
              </Timeline.Item>
            )
          )}
        </Timeline>
      </div>
    );
  }
}
