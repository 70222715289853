import React, { Key } from 'react';
import { Menu } from 'antd';
import * as icons from '@ant-design/icons';
import { SysMenu } from '@/service/system/SysMenuService';
import TabUtil from '@/util/TabUtil';
import { getProperty } from '@/common/common';
import LocaleUtil from '@/util/LocaleUtil';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { websiteTheme } from '@/theme';

export declare interface SideMenuProps {
  menuList: SysMenu[];
}

declare interface SideMenuState {
  openedKey?: Key;
}

export default class SideMenu extends React.PureComponent<SideMenuProps, SideMenuState> {
  state = {
    openedKey: this.props.menuList?.length > 0 ? this.props.menuList[0].id : ''
  };

  getMenu = (id: string, list?: SysMenu[]): any =>
    list?.find(t => t?.id === id) || list?.map(t => this.getMenu(id, t.children)).find(t => t?.id === id);

  onClick = ({ key }: any) => {
    TabUtil.open(this.getMenu(key, this.props.menuList));
  };

  onOpenChange = (keys: Key[]) => {
    const openedKey = keys[keys.length - 1];
    this.setState({ openedKey });
  };

  mapItem = (menuList: SysMenu[]): ItemType[] =>
    menuList.map(({ icon, id, code, name, children }) => {
      const Icon = getProperty<any>(icons, icon);
      const DefaultIcon = getProperty<any>(icons, 'TagOutlined');
      return {
        key: id,
        icon: Icon ? (
          <Icon style={{ fontSize: '14px', fontWeight: 'bold' }} />
        ) : (
          <DefaultIcon style={{ fontSize: '14px', fontWeight: 'bold' }} />
        ),
        label: LocaleUtil.get(code, name),
        children: children && this.mapItem(children)
      };
    });

  render() {
    const { menuList } = this.props;
    const { openedKey } = this.state;

    return (
      <Menu
        mode="inline"
        openKeys={[openedKey]}
        onOpenChange={this.onOpenChange}
        theme={websiteTheme}
        items={this.mapItem(menuList) as any}
        onClick={this.onClick}
      />
    );
  }
}
