import React from 'react';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import { WrappedForm } from '@/component';
import { ProcessExt } from '@/service/process/ProcessExtService';
import './Start.less';
import { Act } from '@/service/process/ProcessService';
import { ResultBean } from '@/service/base/BaseService';
import { APPROVE_TO_END, APPROVE_TO_NEXT, EVENT_PROCESS, PartialTaskDTO } from '@/view/process/process/Executor';
import { FormInstance } from 'antd/es/form';

declare interface ApproveFormProps {
  act: Act;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
  run: (task: PartialTaskDTO, shouldCheckNextClaimer: boolean) => Promise<any>;
}

declare interface ApproveFormState {
  columns: EditableColumnProps<ProcessExt>[];
}

export default class ApproveForm extends React.PureComponent<ApproveFormProps, ApproveFormState> {
  formRef = React.createRef<FormInstance>();

  columns: EditableColumnProps<any>[] = [
    {
      label: '处理结果',
      name: 'value',
      editable: true,
      query: true,
      formatter: 'text',
      formatOption: {},
      editType: 'select',
      editOption: {
        span: 20,
        getOptions: async () => {
          const { conditionList = [] } = this.props.act;
          return conditionList as any;
        }
      },
      editRule: { required: true, maxLength: 300 },
      span: 24
    },
    {
      label: '意见',
      name: 'comment',
      editable: true,
      query: true,
      formatter: 'text',
      formatOption: {},
      editType: 'textarea',
      editOption: { span: 20, itemStyle: { height: '140px', resize: 'none' } },
      editRule: { required: true, maxLength: 300 },
      span: 24
    }
  ];

  state = {
    columns: []
  };

  approve = async () => {
    const {
      run,
      act: { conditionList }
    } = this.props;
    const data = await this.formRef.current?.validateFields();
    if (!data) {
      throw new Error('数据不完整');
    }

    const { value } = data;
    let vars = {};
    let condition;
    for (const t of conditionList.values()) {
      if (t.value === value && t.key) {
        vars = { [t.key]: value };
        condition = t;
        break;
      }
    }

    return run(
      {
        nextTaskDefKey: data.taskDefKey,
        comment: data.comment,
        event: EVENT_PROCESS,
        vars: Object.assign(vars || {}, {
          action: value === APPROVE_TO_END ? APPROVE_TO_END : APPROVE_TO_NEXT
        })
      },
      condition?.dstTaskDefKey !== 'end'
    );
  };

  componentDidMount() {
    const { setHandler } = this.props;
    setHandler?.(this.approve);
  }

  render() {
    const props = {
      columns: this.columns,
      record: {},
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
