import FileUtil from '@/util/FileUtil';
import { SysLocaleQuery } from '@/service/system/SysLocaleService';
import BaseService, { BaseEntity, BaseQuery } from '../base/BaseService';
import { API_URL } from '../url';

export interface SysMenu extends BaseEntity<SysMenu> {
  name: string;
  code: string;
  icon: string;
  href: string;
  permission: string;
  status: boolean;
}

export declare type SysMenuQuery = Partial<SysMenu> & BaseQuery;

class SysMenuService extends BaseService<SysMenu, SysMenuQuery> {
  findMenuList = async (query: SysMenuQuery = {}) => {
    const { code, data } = await this.findTree(query);
    return code === 200 ? data.list : [];
  };

  export = (fileName: string) => FileUtil.download(this.getUrl('export'), 'post', fileName);
}

const menuService = new SysMenuService(API_URL, 'sys-menu');
export default menuService;
