import React, { useCallback, useMemo, useRef } from 'react';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import Row from '@/component/Form/component/WrappedTable/Row';
import { Form } from 'antd';
import { cloneDeep } from 'lodash-es';
import { useMount, useUpdateEffect } from 'ahooks';
import { FormInstance } from 'antd/es/form';

export declare interface WrappedRowListProps {
  columns: EditableColumnProps<any>[];
  records: Record<string, unknown>[];
  setRef: (ref: React.Ref<any>) => void;
  onChange: (index: number, record: Record<string, string>, formatedValue: string) => void;
  readonly?: boolean;
  size: number;
}

const RowList: React.FC<WrappedRowListProps> = ({
  size,
  columns,
  readonly,
  records,
  onChange,
  setRef
}: WrappedRowListProps) => {
  const getRealName = useCallback((name: string, i: number) => `${name}_${i}`, []);
  const list = useMemo(
    () =>
      new Array(size).fill(0).map((_, i) =>
        cloneDeep(columns).map(row => {
          row.name = getRealName(row.name, i);
          row.index = i;
          return row;
        })
      ),
    [size, columns, getRealName]
  );
  const initialValues = useMemo(() => {
    const data: Record<string, unknown> = {};
    for (let index = 0; index < size; index++) {
      columns.forEach(column => {
        data[getRealName(column.name, index)] = records[index]?.[column.name];
      });
    }
    return data;
  }, [records, getRealName, columns, size]);
  const ref = useRef<FormInstance>();
  const formProps: any = {
    initialValues,
    component: false
  };

  useMount(() => {
    setRef?.(ref);
  });

  useUpdateEffect(() => {
    ref?.current?.setFieldsValue(initialValues);
  }, [size]);

  return (
    <Form {...formProps} ref={ref}>
      {list.map((columns, i) => (
        <Row
          formRef={ref as any}
          key={`row_${i.toString()}`}
          rowNum={i}
          onChange={onChange}
          columns={columns}
          readonly={readonly}
          record={records[i]}
        />
      ))}
    </Form>
  );
};

export default RowList;
