import React, { Ref } from 'react';
import { Drawer, DrawerProps } from 'antd';

export declare interface WrappedDrawerProps extends DrawerProps {
  ref?: Ref<HTMLDivElement>;
}

const WrappedDrawer: React.FC<WrappedDrawerProps> = React.forwardRef(
  (props: WrappedDrawerProps, ref: Ref<HTMLDivElement>) => (
    <div ref={ref}>
      <Drawer {...props} />
    </div>
  )
);

export default WrappedDrawer;
