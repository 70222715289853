import BaseService, { BaseEntity, BaseQuery } from '../base/BaseService';
import { API_URL } from '../url';

export interface MixDetail extends BaseEntity<MixDetail> {
  mixId: string;
  mixName: string;
  materialId: string;
  materialName: string;
  unit: string;
  num: number;
  lossFactor1: number;
  lossFactor2: number;
}

export declare type MixDetailQuery = MixDetail & BaseQuery;

class MixDetailService extends BaseService<MixDetail, MixDetailQuery> {}

export default new MixDetailService(API_URL, 'repository/mix-detail');
