import { SysMenu } from '@/service/system/SysMenuService';
import NotFound from '@/view/system/home/notFound/NotFound';
import React from 'react';
import { parseQueryParam } from '@/common/common';
import store from '../redux/store/configure';

export declare interface TabsAction {
  type: 'TAB_ADD' | 'TAB_DEL' | 'TAB_SWITCH';
  data: TabsProps;
}

export declare interface TabsProps extends Pick<SysMenu, 'href' | 'name' | 'id'> {
  closable?: boolean;
  data?: Record<string, unknown> & any;
  code?: string;
}

class TabsUtil {
  open = (data: TabsProps) => store.dispatch<TabsAction>({ type: 'TAB_ADD', data });

  close = (data: TabsProps) => store.dispatch<TabsAction>({ type: 'TAB_DEL', data });

  active = (id: string) => store.dispatch<TabsAction>({ type: 'TAB_SWITCH', data: { id } as TabsProps });

  get = (href: string, props: Record<string, unknown> = {}) => {
    try {
      let url: any = href || '';
      const i: number = href?.lastIndexOf('/') || -1;
      if (i !== -1) {
        const chr = url.charAt(i + 1);
        url = url.substr(0, i + 1) + chr.toUpperCase() + url.substr(i + 2);
      }
      const pos = url.indexOf('?');
      let params = {};
      if (pos > 0) {
        url = url.substr(0, pos);
        params = parseQueryParam(href.substr(pos + 1));
      }
      const { query = {} } = props;
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
      const Page = require(`../view${url}`).default || NotFound;
      return <Page {...props} query={Object.assign(query, params)} params={params} />;
    } catch (e) {
      return <NotFound href={href} />;
    }
  };
}

export default new TabsUtil();
