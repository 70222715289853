import React, { ReactNode, RefObject } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { Form } from 'antd';
import { EditableColumnProps } from './EditableTable';

export declare interface EditableRowProps<T> {
  record: T;
  editing: boolean;
  column: EditableColumnProps<T>;
  index: number;
  onRowMount: (form: RefObject<FormInstance>) => void;
  children?: ReactNode | ReactNode[];
}

export declare interface EditableRowState {
  formRef?: RefObject<FormInstance>;
}

export default class EditableRow<T> extends React.PureComponent<EditableRowProps<T>, EditableRowState> {
  state: EditableRowState = {};

  formRef = React.createRef<FormInstance>();

  componentDidMount() {
    const { onRowMount, editing } = this.props;
    if (editing) {
      onRowMount(this.formRef);
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps: Readonly<EditableRowProps<T>>, prevState: Readonly<EditableRowState>, snapshot?: any) {
    const { onRowMount, editing } = this.props;
    if (editing) {
      onRowMount(this.formRef);
    }
  }

  onValuesChange = (values: any) => {
    Object.assign(this.props.record, values || {});
  };

  render() {
    const { onRowMount, children, record, editing, ...rest } = this.props;
    if (editing) {
      const list = children as React.ReactElement[];
      list.forEach(({ props: { additionalProps = {} } }) => Object.assign(additionalProps, { formRef: this.formRef }));

      return (
        <tr {...rest}>
          <Form component={false} onValuesChange={this.onValuesChange} ref={this.formRef} initialValues={record}>
            {children}
          </Form>
        </tr>
      );
    }

    return <tr {...rest}>{children}</tr>;
  }
}
