import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { TabsProps } from '@/util/TabUtil';
import { getProperty } from '@/common/common';
import switchMiddleware from '@/redux/middleware/switchMiddleware';
import { SwitchActionEnum } from '@/redux/actions/action';
import * as reducers from '../reducers';

const rootReducer = combineReducers(reducers);
const createStoreWithMiddleware = compose(applyMiddleware(thunk, switchMiddleware))(createStore);

const configureStore = (initialState?: any) => {
  const store = createStoreWithMiddleware(rootReducer, initialState);
  const hot = getProperty(module, 'hot');

  if (hot) {
    store.replaceReducer(rootReducer);
  }

  return store;
};

const store = configureStore();
export default store;

export declare interface StoreState {
  locale: string;
  fade: SwitchActionEnum;
  tabs: { activeKey: string; list: TabsProps[] };
}
