let apiURL;
let daemonURL;

const baseURL = `http://${window.location.hostname}`;
if (process.env.NODE_ENV === 'development') {
  apiURL = `${baseURL}:9990/api`;
  daemonURL = `${baseURL}:9991/daemon`;
} else {
  apiURL = `${window.location.href}api`;
  daemonURL = `${window.location.href}daemon`;
}

export const API_URL = apiURL;
export const DAEMON_URL = daemonURL;

export type SERVER_TYPE = 'api' | 'daemon';

export const buildUrl = (type: SERVER_TYPE = 'api', url: string): string => {
  switch (type) {
    case 'daemon':
      return `${DAEMON_URL}/${url}`;
    default:
    case 'api':
      return `${API_URL}/${url}`;
  }
};
