import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { Input } from 'antd';
import { useDebounce, useUpdateEffect } from 'ahooks';
import { WrappedFormItemProps } from '../WrappedFormItem';
import LocaleUtil from '../../../util/LocaleUtil';
import FormatUtil from '../../../util/FormatUtil';

const WrappedText: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => {
  const { column, value, record = {}, onChange, formRef } = props;
  const { name, editOption, align, code, label, formatter, formatOption, editType = 'text' } = column;
  const { itemStyle, disabled, hidePlaceHolder, type, prefix, onSearch } = editOption;
  const [currentValue, setCurrentValue] = useState(FormatUtil.format(value, formatter, formatOption));
  const [originalValue, setOriginalValue] = useState(value);
  const debounceValue = useDebounce(originalValue, { wait: 500 });
  const holder = useMemo(() => (hidePlaceHolder || disabled ? undefined : LocaleUtil.get(code, label)), [
    code,
    label,
    disabled,
    hidePlaceHolder
  ]);

  // 人工录入时，监听值变化
  const onCurrentChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(value);
      onChange?.(value);
    },
    [onChange]
  );

  const onSearchClick = useCallback((value: string) => onSearch?.(value, formRef as any), [onSearch, formRef]);

  // setFieldValue调用时，监听value变化，需要重新计算是否要更新组件内的值
  useUpdateEffect(() => {
    if (value !== originalValue) {
      setOriginalValue(value);
    }
  }, [value]);

  useUpdateEffect(() => {
    const formatedValue = FormatUtil.format(debounceValue, formatter, formatOption);
    setCurrentValue(formatedValue);
    onChange?.(value);
  }, [debounceValue]);

  const mapProps = {
    id: record.id ? `${name}_${record.id}` : undefined,
    placeholder: holder,
    value: currentValue as any,
    onChange: onCurrentChange,
    style: { textAlign: align, ...itemStyle },
    type,
    prefix,
    disabled
  };

  return editType === 'search' ? (
    <Input.Search {...mapProps} onSearch={onSearchClick} enterButton />
  ) : (
    <Input {...mapProps} />
  );
};

export default memo(WrappedText, ({ value }, nextProps) => value === nextProps.value);
