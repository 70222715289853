export declare interface LocaleAction {
  type: 'SET_LOCALE';
  locale: string;
}

export enum SwitchActionEnum {
  FADE_OUT,
  FADE_IN
}

export declare interface SwitchAction {
  type: 'SWITCH';
  fade: SwitchActionEnum;
}
