import React from 'react';
import { TaskExt } from '@/service/process/TaskExtService';
import Signature from '@/view/process/component/Signature';
import { Dictionary } from '@/common/types';
import { EditableColumnProps } from '@/component/Table/EditableTable';

declare interface SignatureProps {
  label: string;
  task?: TaskExt;
}

export declare interface SignatureNode {
  taskDefKey: string;
  taskName: string;
  span?: number;
}

export const buildSignatureItems = (list: SignatureNode[], historic: Dictionary<TaskExt> = {}) =>
  list.map(({ taskDefKey, taskName, span = 8 }) => ({
    label: taskName,
    name: taskDefKey,
    editable: true,
    editType: 'custom',
    editOption: {
      hideLabel: true,
      render: () => <UserSignature label={taskName} task={historic[taskDefKey]} />
    },
    span,
    width: 100
  })) as EditableColumnProps<any>[];

const UserSignature: React.FC<SignatureProps> = ({ task = {} as TaskExt, label }: SignatureProps) => {
  const { assigneeId, beginDate, assigneeName } = task;
  return (
    <div
      style={{
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '1px 5px'
      }}
    >
      <span style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>{label}</span>
      <span>
        <Signature id={assigneeId} name={assigneeName} />
        &nbsp;
      </span>
      <span style={{ alignSelf: 'flex-end' }}>{beginDate}&nbsp;</span>
    </div>
  );
};

UserSignature.defaultProps = {
  task: {} as any
};

export default UserSignature;
