import React, { CSSProperties } from 'react';
import { Project } from '@/service/repository/ProjectService';
import EstimateApprovalList from '@/view/repository/estimateApproval/List';
import WrappedTabs from '@/component/Tabs/WrappedTabs';
import ProjectForm from './Form';
import AttachmentList from '../../system/attachment/List';

export declare interface AccountProps {
  record: Project;
}

export default class Account extends React.PureComponent<AccountProps, any> {
  paneStyle: CSSProperties = { height: '100%', minHeight: '600px' };

  record = this.props.record;

  items = [
    { name: '项目', key: 'project', render: () => <ProjectForm record={this.record} /> },
    { name: '批准概算', key: 'estimate', render: () => <EstimateApprovalList project={this.record} /> },
    {
      name: '附件',
      key: 'attachment',
      render: () => <AttachmentList businessId={this.record.id} businessName={this.record.name} useSearchBar={false} />
    }
  ];

  render() {
    return <WrappedTabs items={this.items} paneStyle={this.paneStyle} />;
  }
}
