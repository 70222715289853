import React, { memo } from 'react';
import { SwitchProps } from 'antd/lib/switch';
import { Switch } from 'antd';
import { WrappedFormItemProps } from '@/component/Form/WrappedFormItem';
import LocaleUtil from '../../../util/LocaleUtil';

export declare interface WrappedSwitchProps extends SwitchProps {
  value?: boolean;
}

const WrappedSwitch: React.FC<WrappedFormItemProps<any>> = ({
  value,
  onChange,
  column = {} as any
}: WrappedFormItemProps<any>) => {
  const { editOption = {} } = column;
  const { disabled = false } = editOption;

  return (
    <Switch
      checked={value}
      disabled={disabled}
      onChange={onChange as any}
      checkedChildren={LocaleUtil.get('true', '是')}
      unCheckedChildren={LocaleUtil.get('false', '否')}
    />
  );
};

export default memo(WrappedSwitch);
