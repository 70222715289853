import { SysUser } from '@/service/system/SysUserService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<SysUser>[] = [
  {
    label: '登录名',
    name: 'loginName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editOption: { disabled: true },
    editRule: { required: true, maxLength: 20 }
  },
  {
    label: '编号',
    name: 'no',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: { disabled: true },
    editRule: { maxLength: 20 }
  },
  {
    label: '姓名',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: { disabled: true },
    editRule: { maxLength: 20 }
  },
  {
    label: '旧密码',
    name: 'oldPassword',
    ellipsis: true,
    editable: true,
    width: 400,
    editOption: { type: 'password' },
    editRule: { required: true, maxLength: 10 }
  },
  {
    label: '新密码',
    name: 'newPassword',
    ellipsis: true,
    editable: true,
    width: 400,
    editOption: { type: 'password' },
    editRule: { maxLength: 15, minLength: 3, required: true }
  },
  {
    label: '重复新密码',
    name: 'newPassword1',
    ellipsis: true,
    editable: true,
    width: 400,
    editOption: { type: 'password' },
    editRule: { maxLength: 15, minLength: 3, required: true }
  }
];

export default columns;
