import React from 'react';
import { Dialog, Toolbar } from '@/component';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined } from '@ant-design/icons/lib';
import { ExportHandler, PdfHandler } from '@/view/runtime/verification/ItemBuilder';

export default class ItemBuilder {
  private readonly pdfHandler: PdfHandler;

  private readonly exportHandler: ExportHandler;

  constructor(pdfHandler: PdfHandler, exportHandler: ExportHandler) {
    this.pdfHandler = pdfHandler;
    this.exportHandler = exportHandler;
  }

  public getItems = () => this.items;

  private items: ToolbarOptionItem[] = [
    {
      type: 'yellow',
      icon: <PrinterOutlined />,
      onClick: () =>
        Dialog.open({
          title: '结算打印',
          children: <Toolbar items={this.printItems} />,
          width: 800
        }),
      code: 'print',
      title: '打印'
    },
    {
      type: 'yellow',
      icon: <DownloadOutlined />,
      onClick: () =>
        Dialog.open({
          title: '结算导出',
          children: <Toolbar items={this.exportItems} />,
          width: 800
        }),
      code: 'export',
      title: '导出'
    }
  ];

  private printItems: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('支付报审表', '/report/balance/quantity'),
      code: 'quantity',
      title: '报审表（全部）'
    },
    {
      type: 'primary',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('支付报审表', '/report/balance/quantity', { exceptEmpty: true }),
      code: 'quantity',
      title: '报审表（有数据）'
    },
    {
      type: 'primary',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('签证量', '/report/balance/visa'),
      code: 'visa',
      title: '签证量'
    },
    {
      type: 'primary',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('汇总表', '/report/balance/summary'),
      code: 'summary',
      title: '汇总表'
    },
    {
      type: 'yellow',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('封面', '/report/balance/cover'),
      code: 'cover',
      title: '封面'
    },
    {
      type: 'yellow',
      icon: <FilePdfOutlined />,
      onClick: () => this.pdfHandler('意见', '/report/balance/comment'),
      code: 'comment',
      title: '说明'
    }
  ];

  private exportItems: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <FileExcelOutlined />,
      onClick: () => this.exportHandler('quantity'),
      code: 'quantity',
      title: '报审表'
    },
    {
      type: 'primary',
      icon: <FileExcelOutlined />,
      onClick: () => this.exportHandler('visa'),
      code: 'quantity',
      title: '计量汇总表'
    },
    {
      type: 'yellow',
      icon: <FileExcelOutlined />,
      onClick: () => this.exportHandler('summary'),
      code: 'summary',
      title: '汇总表'
    }
  ];
}
