import React, { RefObject } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import { cloneDeep } from 'lodash-es';
import ProjectService, { Project, ProjectQuery } from '@/service/repository/ProjectService';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import TableUtil from '@/component/Table/Util/TableUtil';
import { ToolbarOptionItem } from '@/component/Toolbar';
import SiderAttachment from '@/view/component/SiderAttachment/List';
import ProjectForm from './Account';
import columns from './columns';

export declare interface ProjectListProps {
  onClick: (record: Project, event: any) => void;
}

export declare interface ProjectListState {
  query: Record<string, unknown>;
  record?: Project;
  refresh: boolean;
}

export default class List extends React.PureComponent<ProjectListProps, ProjectListState> {
  tableRef: RefObject<EditableTable<Project>> = React.createRef();

  attachment = React.createRef<SiderAttachment>();

  state = {
    query: {},
    refresh: false
  };

  add = () => {
    const record: Project = cloneDeep(this.tableRef.current?.util.getSelected() || ({} as Project));
    delete (record as any).id;
    record.isNewRecord = true;

    this.edit('工程修改', record);
  };

  modify = () => {
    const record = this.tableRef.current?.util.getSelected()!;
    if (!record) {
      return message.error('未选中要修改的数据');
    }

    this.edit('工程修改', record);
  };

  edit = (title: string, record: Project) => {
    Dialog.open({
      title,
      children: <ProjectForm record={record} />,
      width: 1260,
      styles: { body: { height: '700px', padding: 0, paddingTop: '5px', paddingBottom: '5px' } },
      afterOk: this.getUtil().refresh
    });
  };

  delete = async () => {
    const record = this.getUtil().getSelected();
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('工程删除', '确定要删除数据吗', async () => {
      const bean = await ProjectService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  onClick = (record?: Project) => {
    const { id, name } = record || {};
    if (id && name) {
      setTimeout(() => {
        this.attachment.current?.reload({ businessId: id, businessName: name });
      }, 500);
    }
  };

  getUtil = (): TableUtil<Project> => this.tableRef.current?.util as TableUtil<Project>;

  onQuery = (query: ProjectQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<Project>
          ref={this.tableRef}
          service={ProjectService}
          autoSave
          columns={columns}
          onClick={this.onClick}
          onDoubleClick={record => this.edit('工程修改', record)}
          loadData={ProjectService.findPage}
          isTree={false}
        />
        <SiderAttachment ref={this.attachment} />
      </>
    );
  }
}
