import React from 'react';
import ProcessUtil from '@/util/ProcessUtil';
import TaskExtService, { TaskExt, TaskExtQuery } from '../../../service/process/TaskExtService';
import columns from './model/columns';
import { EditableTable, SearchBar } from '../../../component';
import TableUtil from '../../../component/Table/Util/TableUtil';

export declare interface TaskExtListProps {
  onClick: (record: TaskExt, event: any) => void;
}

export declare interface TaskExtListState {
  query: TaskExtQuery;
  record?: TaskExt;
  refresh: boolean;
}

export default class List extends React.PureComponent<TaskExtListProps, TaskExtListState> {
  tableRef = React.createRef<EditableTable<TaskExt>>();

  state = {
    query: {} as TaskExtQuery,
    refresh: false
  };

  getUtil = (): TableUtil<TaskExt> => this.tableRef.current?.util as TableUtil<TaskExt>;

  onQuery = (query: TaskExtQuery) => this.getUtil().reload(query);

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <EditableTable<TaskExt>
          ref={this.tableRef}
          service={TaskExtService}
          autoSave
          columns={columns}
          onDoubleClick={({ businessId, businessNo }) => ProcessUtil.open(businessNo, businessId)}
          loadData={TaskExtService.historicPage}
          isTree={false}
        />
      </>
    );
  }
}
