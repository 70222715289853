import React, { CSSProperties } from 'react';
import { Contract } from '@/service/repository/ContractService';
import ContractRoleForm from '@/view/repository/contractRole/Form';
import WrappedTabs from '@/component/Tabs/WrappedTabs';
import ContractForm from './Form';
import ContractMaterialList from '../contractMaterial/List';
import ContractDivideList from '../contractDivide/List';
import ContractMixList from '../contractMix/List';
import QuantityList from '../quantity/List';
import ContractStyleList from '../contractStyle/List';
import AttachmentList from '../../system/attachment/List';

export declare interface AccountProps {
  contract: Contract;
}

export default class Account extends React.PureComponent<AccountProps, any> {
  paneStyle: CSSProperties = { height: '100%', minHeight: '600px' };

  contract = this.props.contract;

  items = [
    { name: '合同主要信息', key: 'project', render: () => <ContractForm toolbar contract={this.contract} /> },
    { name: '工程量清单', key: 'quantity', render: () => <QuantityList contract={this.contract} /> },
    { name: '核销材料表', key: 'material', render: () => <ContractMaterialList contract={this.contract} /> },
    { name: '结算汇总表', key: 'style', render: () => <ContractStyleList contract={this.contract} /> },
    { name: '用户授权', key: 'user-role', render: () => <ContractRoleForm contract={this.contract} /> },
    { name: '合同费用分摊', key: 'divide', render: () => <ContractDivideList contract={this.contract} /> },
    { name: '材料核销配合比', key: 'material-mix', render: () => <ContractMixList contract={this.contract} /> },
    {
      name: '附件',
      key: 'attachment',
      render: () => (
        <AttachmentList businessId={this.contract.id} businessName={this.contract.name} useSearchBar={false} />
      )
    }
  ];

  render() {
    return <WrappedTabs items={this.items} paneStyle={this.paneStyle} />;
  }
}
