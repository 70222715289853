import React from 'react';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import { WrappedForm } from '@/component';
import { ProcessExt } from '@/service/process/ProcessExtService';
import './Start.less';
import { Act } from '@/service/process/ProcessService';
import TaskExtService from '@/service/process/TaskExtService';
import { ResultBean } from '@/service/base/BaseService';
import { EVENT_PROCESS, PartialTaskDTO, REJECT_TO_PREVIOUS } from '@/view/process/process/Executor';
import { FormInstance } from 'antd/es/form';

declare interface RejectFormProps {
  act: Act;
  run: (task: PartialTaskDTO, shouldCheckNextClaimer: boolean) => Promise<any>;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

declare interface RejectFormState {
  columns: EditableColumnProps<ProcessExt>[];
}

export default class RejectForm extends React.PureComponent<RejectFormProps, RejectFormState> {
  formRef = React.createRef<FormInstance>();

  columns: EditableColumnProps<any>[] = [
    {
      label: '驳回到',
      name: 'taskDefKey',
      editable: true,
      query: true,
      formatter: 'text',
      formatOption: {},
      editType: 'select',
      editOption: {
        span: 20,
        getOptions: async () => {
          const {
            task: { taskDefKey, procInstId }
          } = this.props.act;
          const { data } = await TaskExtService.getRejectList(procInstId, taskDefKey);
          return data?.map(({ taskDefKey, taskName }) => ({ name: taskName, value: taskDefKey })) as any;
        }
      },
      editRule: { required: true, maxLength: 300 },
      span: 24
    },
    {
      label: '意见',
      name: 'comment',
      editable: true,
      query: true,
      formatter: 'text',
      formatOption: {},
      editType: 'textarea',
      editOption: { span: 20, itemStyle: { height: '140px', resize: 'none' } },
      editRule: { required: true, maxLength: 300 },
      span: 24
    }
  ];

  state = {
    columns: []
  };

  reject = async () => {
    const { run } = this.props;
    const data = await this.formRef.current?.validateFields();
    if (!data) {
      throw new Error('数据不完整');
    }

    return run(
      {
        nextTaskDefKey: data.taskDefKey,
        comment: data.comment,
        event: EVENT_PROCESS,
        vars: {
          action: REJECT_TO_PREVIOUS
        }
      },
      false
    );
  };

  componentDidMount() {
    const { setHandler } = this.props;
    setHandler?.(this.reject);
  }

  render() {
    const props = {
      columns: this.columns,
      record: {},
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
