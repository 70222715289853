import { TaskDelegateDTO } from '@/service/process/TaskExtService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import SysUserService from '@/service/system/SysUserService';

const columns: EditableColumnProps<TaskDelegateDTO>[] = [
  {
    label: '流程名称',
    code: 'process',
    name: 'procName',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: { disabled: true, span: 20 },
    editRule: { required: true }
  },
  {
    label: '当前任务环节',
    code: 'task.name',
    name: 'taskName',
    ellipsis: true,
    editable: true,
    width: 120,
    editOption: { disabled: true, span: 20 },
    editRule: { required: true }
  },
  {
    label: '委托人',
    code: 'userId',
    name: 'userId',
    ellipsis: true,
    editable: true,
    width: 80,
    editType: 'pop-select',
    editOption: {
      prefix: 'user',
      popup: SysUserService.select,
      span: 20
    },
    editRule: { required: true }
  },
  {
    label: '委托意见',
    code: 'comment',
    name: 'comment',
    ellipsis: true,
    editable: true,
    width: 80,
    editType: 'textarea',
    formatOption: {},
    editOption: { itemStyle: { height: '100px', resize: 'none' }, span: 20 },
    editRule: { maxLength: 200, minLength: 1, required: true }
  }
];

export default columns;
