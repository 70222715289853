import LocaleUtil from '@/util/LocaleUtil';
import { EditableColumnProps } from '../../Table/EditableTable';

class Validator {
  private static readonly mapRules: Record<string, string> = {
    required: 'required',
    number: 'number',
    max: 'max',
    min: 'min',
    mail: 'mail',
    maxLength: 'maxLength',
    minLength: 'minLength'
  };

  private static getRuleFileName(key: string): string {
    let name = '';
    for (const [fileName, rules] of Object.entries(Validator.mapRules)) {
      if (rules.toString().includes(key)) {
        name = fileName;
        break;
      }
    }

    return name;
  }

  message = (key: string, ...args: any[]) => {
    const language = LocaleUtil.getLanguage();
    // eslint-disable-next-line import/no-dynamic-require
    const msg: Record<string, string> = require(`../message/${language}`);
    return LocaleUtil.template(msg[key], ...args);
  };

  create = (column: EditableColumnProps<any>) => {
    const { editRule = {} } = column;
    const rules: Record<string, unknown>[] = [];

    for (const key of Object.keys(editRule)) {
      const fileName: string = Validator.getRuleFileName(key);
      if (fileName !== '') {
        // eslint-disable-next-line import/no-dynamic-require
        const func: any = require(`./${fileName}`).default;
        rules.push(func(column));
      }
    }
    return rules;
  };
}

const validator = new Validator();
export default validator;
