import { Estimate } from '@/service/repository/EstimateService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<Estimate>[] = [
  {
    label: '编号',
    name: 'no',
    code: 'no',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '名字',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '概算金额',
    name: 'amount',
    code: 'estimate.amount',
    ellipsis: true,
    editable: true,
    width: 150,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    align: 'right',
    editOption: {},
    editRule: { max: 10 ** 13, min: 0 }
  },
  {
    label: '预算金额',
    name: 'budgetAmount',
    code: 'budget.amount',
    ellipsis: true,
    editable: true,
    width: 150,
    align: 'right',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: { max: 10 ** 13, min: 0 }
  },
  {
    label: '投资占比',
    name: 'percent',
    code: 'percent',
    ellipsis: true,
    editable: true,
    width: 100,
    formatter: 'percent',
    align: 'right',
    editOption: {},
    editRule: { max: 10, min: 0 }
  }
];

export default columns;
