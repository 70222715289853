import React from 'react';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import { FormInstance } from 'antd/es/form';
import Cell from '@/component/Form/component/WrappedTable/Cell';
import { cloneDeep } from 'lodash-es';

export declare interface WrappedRowItemProps {
  columns: EditableColumnProps<any>[];
  record?: Record<string, unknown>;
  onChange?: (name: string, value: string) => void;
  formRef: React.RefObject<FormInstance>;
  rowNum: number;
}

const CellList: React.FC<WrappedRowItemProps> = (props: WrappedRowItemProps) => {
  const { columns, record, ...rest } = props;
  return (
    <tr>
      {columns.map(column => {
        const col = cloneDeep(column);
        const value = (record?.[col.name] as string) || '';
        return <Cell key={col.name} column={col} value={value} {...rest} />;
      })}
    </tr>
  );
};

export default CellList;
