import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';
import { SysRole } from './SysRoleService';
import { SysMenu } from './SysMenuService';

export interface SysRoleMenu extends BaseEntity<SysRoleMenu> {
  roleId: string;
  roleName: string;
  menuId: string;
  menuName: string;
}

export declare type SysRoleMenuQuery = BaseQuery & Partial<SysRoleMenu>;

export interface SysUserRoleGrantVO {
  role: SysRole;
  menuList: SysMenu[];
}

class SysRoleMenuService extends BaseService<SysRoleMenu, SysRoleMenuQuery> {
  listByRoleId = async (roleId: string) => {
    const { code, data } = await this.findList({ roleId });
    return code === 200 ? data : null;
  };

  grant = (vo: SysUserRoleGrantVO): Promise<ResultBean<boolean>> => this.request('post', 'grant', vo);
}

const roleMenuService = new SysRoleMenuService(API_URL, 'sys-role-menu');
export default roleMenuService;
