import React from 'react';
import { EditableColumnProps } from '@/component/Table/EditableTable';

export declare interface WrappedHeaderProps {
  columns: EditableColumnProps<any>[];
  hidden?: boolean;
}

const Header = ({ columns, hidden }: WrappedHeaderProps) =>
  hidden ? (
    <></>
  ) : (
    <thead>
      <tr>
        {columns.map(({ label, span, name, align }) => (
          <th key={name} colSpan={span} style={{ textAlign: align }}>
            {label}
          </th>
        ))}
      </tr>
    </thead>
  );

export default Header;
