import FileUtil from '@/util/FileUtil';
import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';

export interface Model extends BaseEntity<Model> {
  name: string;
  key: string;
  description: string;
  category: string;
}

export declare type ModelQuery = Model & BaseQuery;

class ModelService extends BaseService<Model, ModelQuery> {
  deploy = (id: string) => this.request<ResultBean<any>>('get', `deploy?id=${id}`);

  upgrade = (key: string) => this.request<ResultBean<any>>('get', `upgrade?key=${key}`);

  export = (id: string, fileName: string) => FileUtil.download(this.getUrl(`export?id=${id}`), 'get', fileName);
}

export default new ModelService(API_URL, 'process/model');
