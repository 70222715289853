import { Badge, Tag } from 'antd';
import React from 'react';
import { TagProps } from 'antd/es/tag';
import { isBlank } from '@/common/common';
import Decimal from 'decimal.js';
import DictUtil from './DictUtil';

export declare interface FormatOption {
  type?: string; // 字典类型，不要用错了
  tagProps?: (value: string) => TagProps;
  pattern?: string;
  separator?: string;
  precision?: number;
  zeroEmpty?: boolean;
  scale?: number;
  trim?: boolean;
}

export const TagFormat = (value?: string, type?: string, props?: TagProps) => {
  const colors = ['green', 'blue', 'orange', 'red', 'magenta', 'volcano', 'gold', 'lime', 'cyan', 'geekblue', 'purple'];
  if (isBlank(value)) {
    return <></>;
  }

  const list = value?.toString().split(',');
  return (
    <>
      {list?.map(v => {
        const num: number = parseInt(v)! % colors.length;
        return (
          <Tag key={v} color={colors[num] || 'green'} {...props}>
            {DictUtil.getLocaledNameByValue(type, v)}
          </Tag>
        );
      })}
    </>
  );
};

export const ProcessStatusFormat = (status = 0, type: string) => {
  const tag = ['default', 'processing', 'success'];
  return (
    <Badge status={(tag[status] || 'default') as any} text={DictUtil.getLocaledNameByValue(type, status.toString())} />
  );
};

class FormatUtil {
  formatter = {
    number(value = '', separator = ',', precision = 2, zeroEmpty = false, scale: number) {
      const val = isBlank(value) ? '0' : value.toString();
      let isError = false;
      let num = '0';
      try {
        num = new Decimal(val.replaceAll(',', '')).mul(scale).toFixed(precision, Decimal.ROUND_HALF_UP);
      } catch (e) {
        isError = true;
      }

      if (isError) {
        return '';
      }

      const decimal = new Decimal(num);
      if (decimal.equals(0) && zeroEmpty) {
        return '';
      }
      const integer = num.replace('-', '').split('.')[0].split('').reverse();
      const rest = num.split('.')[1];
      const list = [];
      for (let i = 0; i < integer.length; i++) {
        if (i > 0 && i % 3 === 0) {
          list.push(separator);
        }
        list.push(integer[i]);
      }

      const prefix = decimal.lessThan(0) ? '-' : '';
      return prefix + (precision > 0 ? `${list.reverse().join('')}.${rest}` : list.reverse().join(''));
    },
    percent: (value: string, precision = 2) => {
      const val = isBlank(value) ? '0' : value.toString();
      const num = new Decimal(val.toString().replaceAll(',', '')).mul(100).toFixed(precision, Decimal.ROUND_HALF_UP);

      return new Decimal(num).equals(0) ? '' : `${num}%`;
    }
  };

  format(
    value: string,
    formatter = 'text',
    { type, separator, precision, zeroEmpty = true, tagProps, trim, scale = 1 }: FormatOption = {}
  ) {
    const currentValue = value!;
    let val = value;
    switch (formatter) {
      case 'number':
      case 'currency':
        return this.formatter.number(currentValue, separator, precision, zeroEmpty, scale);
      case 'date':
        return value;
      case 'switch':
        return zeroEmpty && isBlank(value) ? (
          <></>
        ) : (
          <Badge status={value ? 'success' : 'error'} text={DictUtil.getLocaledNameByValue(type, value ? '1' : '0')} />
        );
      case 'select':
        return TagFormat(currentValue, type, tagProps?.(currentValue) || {});
      case 'percent':
        if (!isBlank(value) && value.toString().endsWith('%')) {
          val = this.unformat(value, 'percent', {});
        }
        return this.formatter.percent(val, precision);
      case 'text':
      default:
        return trim && value ? value.trim() : value;
    }
  }

  number = (value: string): number => parseFloat(this.unformat(value, 'number'));

  unformat = (value: string, formatter = 'text', { separator = ',' }: FormatOption = {}) => {
    let unformatValue;

    switch (formatter) {
      case 'number':
      case 'currency':
        unformatValue = (isBlank(value) ? '0' : value?.toString()).replaceAll(separator, '');
        break;
      case 'text':
        unformatValue = value && value.toString();
        break;
      case 'percent':
        if (isBlank(value)) {
          unformatValue = '0';
        } else if (value.toString().endsWith('%')) {
          unformatValue = new Decimal(value.replace('%', '')).div(100).toString();
        } else {
          unformatValue = value;
        }
        break;
      case 'date':
      default:
        unformatValue = value;
    }
    return unformatValue;
  };

  upperCase = (currency: string, currentType: 'rmb' | 'others' = 'rmb') => {
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(currency)) return '数据非法';
    if (parseInt(currency) === 0) {
      return '零';
    }

    let unit = `万仟佰拾亿仟佰拾万仟佰拾圆${currentType === 'rmb' ? '角' : '拾'}分`;
    let str = '';

    let money = `${currency}00`;
    const p = money.indexOf('.');
    if (p >= 0) {
      money = money.substring(0, p) + money.substr(p + 1, 2);
    }

    unit = unit.substr(unit.length - money.length);
    for (let i = 0; i < money.length; i++) {
      const j = money.charAt(i);
      str += '零壹贰叁肆伍陆柒捌玖'.charAt(parseInt(j)) + unit.charAt(i);
    }

    return str
      .replace(/零([仟百拾角])/g, '零')
      .replace(/零佰/g, '')
      .replace(/零仟/g, '')
      .replace(/零百万/g, '')
      .replace(/(零)+/g, '零')
      .replace(/零([万亿圆])/g, '$1')
      .replace(/(亿)万|壹(拾)/g, '$1$2')
      .replace(/^圆零?|零分/g, '')
      .replace(/拾$/g, '拾分')
      .replace(/圆$/g, '圆整');
  };
}

export default new FormatUtil();
