import React, { memo } from 'react';
import { TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { WrappedFormItemProps } from '../WrappedFormItem';

const WrappedTime: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => {
  const { column, value, onChange } = props;
  const { editOption } = column;
  const { pattern = 'HH:mm:ss', disabled } = editOption;
  const mapProps = {
    value: value ? (dayjs(value, pattern) as any) : undefined,
    onChange: (mValue: Dayjs, value: string | string[]) => onChange?.(value as string),
    disabled,
    format: pattern
  }!;

  return <TimePicker {...mapProps} style={{ width: '100%' }} />;
};

export default memo(WrappedTime, ({ value }, nextProps) => value === nextProps.value);
