import React from 'react';
import { DeleteOutlined, IdcardOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import RoleService, { SysRole, SysRoleQuery } from '@/service/system/SysRoleService';
import MenuService, { SysMenu } from '@/service/system/SysMenuService';
import RoleMenuService from '@/service/system/SysRoleMenuService';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import { clearChildren } from '@/common/common';
import SearchTree from '@/component/Tree/SearchTree';
import TableUtil from '@/component/Table/Util/TableUtil';
import { cloneDeep } from 'lodash-es';
import { ToolbarOptionItem } from '@/component/Toolbar';
import DictForm from './Form';
import columns from './columns';

export declare interface RoleListState {
  query: SysRoleQuery;
  record?: SysRole;
  refresh: boolean;
}

export default class List extends React.PureComponent<any, RoleListState> {
  tableRef = React.createRef<EditableTable<SysRole>>();

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || ({} as SysRole);
    this.edit(Object.assign(record, { id: null }) as SysRole, '角色新增');
  };

  edit = (record: SysRole, title = '角色修改') => {
    Dialog.open({
      title,
      children: <DictForm record={cloneDeep(record)} />,
      width: 800,
      afterOk: this.getUtil().refresh
    });
  };

  delete = async () => {
    const record = this.tableRef.current?.util.getSelected()!;
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('角色删除', '确定要删除该角色吗？', async () => {
      const bean = await RoleService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  grant = async () => {
    const record = this.tableRef.current?.util.getSelected()!;
    if (!record) {
      return message.error('未选中要授权的角色');
    }

    const role: SysRole = record!;
    const roleMenuList = await RoleMenuService.listByRoleId(role.id);
    const menuIds = new Set<string>(roleMenuList?.map(({ menuId }) => menuId));

    Dialog.open({
      title: `角色授权-${role.name}`,
      width: '400px',
      styles: { body: { height: '400px', padding: 0, paddingTop: '5px', paddingBottom: '5px' } },
      children: (
        <SearchTree
          checkable
          checkStrictly
          checkCascade
          style={{ height: '400px', overflow: 'auto' }}
          defaultCheckedKeys={Array.from(menuIds)}
          loadTree={MenuService.findMenuList}
        />
      ),
      ok: (menus: SysMenu[]) => RoleMenuService.grant({ role, menuList: clearChildren(menus) })
    });
  };

  getUtil = (): TableUtil<SysRole> => this.tableRef.current?.util as TableUtil<SysRole>;

  onQuery = (query: SysRoleQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <IdcardOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.grant,
      code: 'grant',
      title: '授权'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<SysRole>
          ref={this.tableRef}
          service={RoleService}
          autoSave
          columns={columns}
          loadData={RoleService.findPage}
          onDoubleClick={record => this.edit(record)}
          isTree={false}
        />
      </>
    );
  }
}
