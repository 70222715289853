import React from 'react';
import OfficeService, { SysOffice, SysOfficeQuery } from '../../../service/system/SysOfficeService';
import columns from './columns';
import { EditableTable, SearchBar } from '../../../component';
import TableUtil from '../../../component/Table/Util/TableUtil';

export declare interface OfficeListState {
  query: Record<string, unknown>;
  record?: SysOffice;
  refresh: boolean;
}

export default class List extends React.PureComponent<any, OfficeListState> {
  tableRef = React.createRef<EditableTable<SysOffice>>();

  getUtil = (): TableUtil<SysOffice> => this.tableRef.current?.util as TableUtil<SysOffice>;

  onQuery = (query: SysOfficeQuery) => this.getUtil().reload(query);

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <EditableTable<SysOffice>
          ref={this.tableRef}
          service={OfficeService}
          autoSave
          columns={columns}
          isEdit
          isTree
          multiSelect="legacy"
          toolbar={{ expandNum: 8, isAdd: true, isReload: true, isSave: true, isDelete: true }}
          nonPagination={false}
          loadData={OfficeService.findTreePage}
        />
      </>
    );
  }
}
