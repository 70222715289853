import { BalanceStyle } from '@/service/template/BalanceStyleService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<BalanceStyle>[] = [
  {
    label: '编号',
    name: 'no',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    editOption: {},
    editRule: { required: true, maxLength: 50 }
  },
  {
    label: '名称',
    name: 'name',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { required: true, maxLength: 50 }
  },
  {
    label: '结算模式',
    name: 'mode',
    ellipsis: true,
    editable: true,
    width: 100,
    formatter: 'select',
    formatOption: { type: 'contract.style.mode' },
    editType: 'select',
    editOption: { type: 'contract.style.mode' },
    align: 'center',
    editRule: {}
  },
  {
    label: '费用类型',
    name: 'category',
    ellipsis: true,
    editable: true,
    width: 160,
    formatter: 'select',
    formatOption: { type: 'contract.style.category' },
    editType: 'select',
    editOption: { type: 'contract.style.category' },
    align: 'left',
    editRule: {}
  },
  {
    label: '序列号',
    name: 'serial',
    ellipsis: true,
    editable: false,
    width: 80,
    align: 'left',
    editOption: {},
    editRule: {}
  },
  {
    label: '可编辑',
    name: 'editable',
    ellipsis: true,
    editable: true,
    width: 100,
    formatter: 'switch',
    formatOption: { type: 'sys.bool' },
    editType: 'switch',
    editOption: { type: 'sys.bool' },
    align: 'center',
    editRule: {}
  },
  {
    label: '系数',
    name: 'ratio',
    ellipsis: true,
    hidden: true,
    editable: false,
    width: 100,
    formatter: 'select',
    formatOption: { type: 'contract.balance.ratio' },
    editType: 'select',
    editOption: { type: 'contract.balance.ratio' },
    align: 'center',
    editRule: {}
  },
  {
    label: '支付参数',
    name: 'param',
    ellipsis: true,
    editable: true,
    width: 120,
    align: 'right',
    editOption: {},
    editRule: {}
  },
  {
    label: '备注',
    name: 'remarks',
    ellipsis: true,
    hidden: false,
    editable: true,
    query: false,
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: { maxLength: 20 },
    align: 'left',
    width: 200
  }
];

export default columns;
