class MapUtil {
  private apiKey = 'bw2f1y9nHnQoeFa6dl6POYp7P4I9U3FU';

  public init = () => {
    console.log('map init');
  };

  public getApiKey() {
    return this.apiKey;
  }
}

export default new MapUtil();
