import { message } from 'antd';
import LocalStorageUtil from '@/util/LocalStorageUtil';
import DictService, { SysDict } from '../service/system/SysDictService';
import LocaleUtil from './LocaleUtil';

class DictUtil {
  private list: SysDict[] = [];

  private loaded = false;

  private storageKey = 'dict.list';

  public init = async () => {
    if (!this.loaded) {
      const { code, data, msg } = await DictService.findList({ pageSize: -1, current: 0 });
      if (code !== 200) {
        message.error(msg);
        const dictList = LocalStorageUtil.getItem(this.storageKey);
        if (dictList != null) {
          this.list = dictList as any;
          this.loaded = true;
        }
      } else {
        this.list = data;
        LocalStorageUtil.setItem(this.storageKey, data);
        this.loaded = true;
      }
      console.log('DICT INIT');
    }
  };

  get = (type = ''): SysDict[] => this.list.filter(t => t.type === type).sort((a, b) => a.sort - b.sort);

  getLocaledNameByValue = (type = '', value = '') => {
    const dict: SysDict = this.getByValue(type, value.toString());
    return LocaleUtil.get(`${dict?.type}.${dict?.value}`, dict?.name);
  };

  getByValue = (type: string, value: string) => this.get(type).filter(t => t.value === value.toString())[0];
}

export default new DictUtil();
