import { Http, WrappedHttp } from '@/common/http';
import { loadTreeDataWrapper } from '@/common/common';
import { AxiosRequestConfig } from 'axios';
import { Key } from 'react';

export declare interface BaseEntity<T extends BaseEntity<T>> {
  id: string;
  isNewRecord?: boolean;
  key?: string;
  name?: string;
  parentId?: string;
  parentIds?: string;
  level?: number;
  leaf?: boolean;
  sort?: number;
  children?: T[];
}

export declare interface BaseQuery {
  pageSize?: number;
  current?: number;
}

export declare interface ResultBean<T> {
  code: 200 | 400 | 500 | number;
  msg: string;
  useTime: number;
  data: T;
}

export declare interface BatchBeanWithQuery<T> {
  query?: Partial<T & BaseQuery>;
  list?: T[];
}

export declare interface TransformBean {
  action: string;
  query: BaseQuery;
  ids: string[];
}

export declare interface PageBean<T> {
  pageSize: number;
  pageNum: number;
  total: number;
  list: T[];
  summary?: T;
}

export interface BaseServiceProps {
  serviceName: string;
  url: string;
  http: Http;
  wrappedHttp: WrappedHttp;
}

export declare interface SelectOption<T, Q> {
  name?: string;
  validator?: (obj: T) => void;
  query?: Q;
  checkedKeys?: Key[];
}

export default class BaseService<T extends BaseEntity<T>, Q extends BaseQuery> {
  // eslint-disable-next-line react/static-property-placement
  public props: Readonly<BaseServiceProps>;

  constructor(serviceName: string, url: string) {
    this.props = {
      serviceName,
      url,
      http: new Http(),
      wrappedHttp: new WrappedHttp()
    };
  }

  public request<E>(type: keyof WrappedHttp, method: string, data?: any, options?: AxiosRequestConfig): Promise<E> {
    const { wrappedHttp, serviceName, url } = this.props;
    return wrappedHttp[type]?.(`${serviceName}/${url}/${method}`, data, options);
  }

  getUrl = (method?: string) => `${this.props.serviceName}/${this.props.url}/${method}`;

  get = (id: string): Promise<ResultBean<T>> => this.request('get', `${id}`);

  save = (entity: T) => (!entity.id ? this.create(entity) : this.update(entity));

  create = (entity: T): Promise<ResultBean<T>> => this.request('post', '', entity);

  update = (entity: T): Promise<ResultBean<T>> => this.request('put', `${entity.id}`, entity);

  findPage = (query: Q): Promise<ResultBean<PageBean<T>>> => this.request('post', 'find-page', query);

  findList = (query: Q = {} as any): Promise<ResultBean<T[]>> => this.request('post', 'find-list', query);

  findListPage = async (query: Q = {} as any): Promise<ResultBean<PageBean<T>>> => {
    const { data, ...rest } = await this.findList(query);
    return { data: { list: data, total: data.length }, ...rest } as any;
  };

  findTree = (query?: Q): Promise<ResultBean<PageBean<T>>> => this.request('post', 'find-tree', query);

  delete = (id: string): Promise<ResultBean<T>> => this.request('delete', `${id}`);

  saveBatch = (data: BatchBeanWithQuery<T>): Promise<ResultBean<T>> => this.request('post', `saveBatch`, data);

  translate = (data: TransformBean): Promise<ResultBean<T>> => this.request('post', `translate`, data);

  findAll = async (query: Q): Promise<ResultBean<PageBean<T>>> => {
    const bean = await this.findList(query);
    bean.data = { list: bean.data } as any;
    return bean as any;
  };

  findTreeData = loadTreeDataWrapper(this.findTree);
}
