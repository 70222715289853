import React, { memo } from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { WrappedFormItemProps } from '../WrappedFormItem';

const WrappedDate: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => {
  const { column, value, onChange } = props;
  const { editOption } = column;
  const { pattern = 'YYYY-MM-DD', type = 'date', disabled } = editOption;
  const mapProps = {
    value: value ? (dayjs(value, pattern) as any) : undefined,
    onChange: (mValue: Dayjs, value: string | string[]) => onChange?.(value as string),
    picker: type as any,
    disabled,
    format: pattern,
    placeholder: disabled ? '' : undefined
  }!;

  return <DatePicker {...mapProps} style={{ width: '100%' }} />;
};

export default memo(WrappedDate, ({ value }, nextProps) => value === nextProps.value);
