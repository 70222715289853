import React from 'react';
import { Result } from 'antd';

const NotFound = ({ href }: { href?: string }) => (
  <Result status="404" title="404" subTitle="对不起，您所访问的页面不存在!" extra={href} />
);

NotFound.defaultProps = {
  href: ''
};
export default NotFound;
