import React, { useState } from 'react';
import Loading from '@/component/Loading/Loading';
import { API_URL } from '@/service/url';
import { message } from 'antd';
import FileUtil from '@/util/FileUtil';
import { useMount } from 'ahooks';

interface PDFViewerProps {
  url: string;
  query?: Record<string, unknown>;
  method?: 'get' | 'post';
}
const PDFViewer: React.FC<PDFViewerProps> = ({ url, query, method = 'post' }: PDFViewerProps) => {
  const [blob, setBlob] = useState<Blob | null>(null);
  useMount(async () => {
    Loading.open();
    try {
      const { code, msg, data } = await FileUtil.getArrayBuffer(API_URL + url, method, query);
      if (code !== 200) {
        return message.error(msg);
      }

      setBlob(new Blob([data], { type: 'application/pdf;charset=UTF-8' }));
    } finally {
      Loading.close();
    }
  });

  if (!blob) {
    return <></>;
  }

  return <iframe width="100%" title={url} height="100%" src={window.URL.createObjectURL(blob)} />;
};

PDFViewer.defaultProps = {
  query: {},
  method: 'post'
};

export default PDFViewer;
