import React, { memo } from 'react';
import { Select } from 'antd';
import './Locale.less';
import LocaleUtil from '@/util/LocaleUtil';
import DictUtil from '@/util/DictUtil';

const Locale: React.FC = () => {
  const locales = DictUtil.get('locale.lang');
  const locale = LocaleUtil.getLanguage();
  const localeChange = async (locale: string) => {
    await LocaleUtil.setLocale(locale);
    window.location.href = '/';
  };

  if (!LocaleUtil.useLocale()) {
    return <></>;
  }

  return (
    <div className="locale-wrapper">
      <Select onChange={localeChange} defaultValue={locale}>
        {locales.map(({ value, name }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
export default memo(Locale);
