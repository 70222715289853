import React from 'react';
import { Card, Col, Row } from 'antd';

export declare interface VisaSignItemProps {
  title: string;
  auditorTitle: string;
  auditorName?: string;
  managerTitle: string;
  managerName?: string;
  date?: string;
}

export const VisaSignature = ({
  title,
  auditorTitle,
  auditorName = '',
  managerTitle,
  managerName = '',
  date = ''
}: VisaSignItemProps) => {
  const list = [
    { title: auditorTitle, name: auditorName },
    { title: managerTitle, name: managerName },
    { title: '日期', name: date }
  ];

  return (
    <Card
      title={title}
      style={{ width: '25%', height: '140px' }}
      headStyle={{ textAlign: 'center', fontWeight: 'bold' }}
    >
      {list.map(({ title, name }) => (
        <Row key={title}>
          <Col span={10} style={{ textAlign: 'right' }}>{`${title}：`}</Col>
          <Col span={14}>{name}</Col>
        </Row>
      ))}
    </Card>
  );
};
