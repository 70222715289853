import { SysOffice } from '@/service/system/SysOfficeService';
import http from '../../common/http';
import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { SysMenu } from './SysMenuService';
import { SysDict } from './SysDictService';
import { API_URL } from '../url';

export declare interface SystemConfig extends BaseEntity<SystemConfig> {
  menuList: SysMenu[];
  dictList: SysDict[];
  authorities: string[];
}

const config: SystemConfig = {
  id: 'config',
  menuList: [],
  dictList: [],
  authorities: []
};

export { config };

class SystemService extends BaseService<SystemConfig, BaseQuery> {
  me = async () => http.get('business/me');

  async getConfig() {
    const bean: ResultBean<SystemConfig> = await this.request<ResultBean<SystemConfig>>('get', 'config');
    Object.assign(config, bean.data);
    return bean;
  }

  getCurrentUser = () => this.request<ResultBean<any>>('get', 'current/user');

  getSmsCode = (mobile: string) => this.request<ResultBean<any>>('get', `sms?telephone=${mobile}`);

  getCurrentCompany = () => this.request<ResultBean<SysOffice>>('get', 'current/company');

  permissions = (): Promise<ResultBean<string[]>> => this.request('get', 'permissions');
}

export default new SystemService(API_URL, 'system');
