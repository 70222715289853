import React, { memo } from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import WrappedFormItem from './WrappedFormItem';
import { EditableColumnProps } from '../Table/EditableTable';
import './WrappedForm.less';

export declare interface NormalFormProps<T> extends FormProps {
  columns: EditableColumnProps<T>[];
  record?: T;
}

const NormalForm: React.FC<NormalFormProps<any>> = React.forwardRef(
  ({ columns, record = {}, labelWrap = true, ...rest }: NormalFormProps<any>, ref: React.Ref<FormInstance>) => (
    <Form
      initialValues={record}
      onValuesChange={values => Object.assign(record, values)}
      ref={ref}
      {...rest}
      labelWrap={labelWrap}
    >
      {columns.map(column => (
        <WrappedFormItem formRef={ref} column={column} key={column.name} record={record} />
      ))}
    </Form>
  )
);

export default memo(NormalForm);
