import React from 'react';
import { SysUser } from '@/service/system/SysUserService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import DictUtil from '@/util/DictUtil';
import OfficeService from '@/service/system/SysOfficeService';
import UserImage, { UserImageType } from '@/view/system/user/UserImage';

const columns: EditableColumnProps<SysUser>[] = [
  {
    label: '头像',
    name: 'picture',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editType: 'custom',
    editOption: {
      render: (props, user: SysUser) => <UserImage user={user} imageType={UserImageType.PHOTO} />,
      span: 20
    },
    span: 24,
    editRule: {}
  },
  {
    label: '公司',
    name: 'companyId',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editType: 'pop-select',
    editOption: {
      prefix: 'company',
      popup: OfficeService.select,
      disabled: true
    },
    editRule: { required: true }
  },
  {
    label: '部门',
    name: 'officeId',
    ellipsis: true,
    editable: true,
    width: 200,
    editType: 'pop-select',
    editOption: {
      prefix: 'office',
      popup: () => OfficeService.select({ department: true }),
      disabled: true
    },
    editRule: { required: false }
  },
  {
    label: '登录名',
    name: 'loginName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editOption: { disabled: true },
    editRule: { required: true, maxLength: 20 }
  },
  {
    label: '编号',
    name: 'no',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: { disabled: true },
    editRule: { maxLength: 20 }
  },
  {
    label: '姓名',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '签字',
    name: 'signature',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editType: 'custom',
    editOption: {
      render: (props, user: SysUser) => <UserImage user={user} imageType={UserImageType.SIGNATURE} />,
      span: 20
    },
    span: 24,
    editRule: {}
  },
  {
    label: '地址',
    name: 'address',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '电话',
    name: 'phone',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '手机',
    ellipsis: true,
    editable: true,
    name: 'mobile',
    editOption: {},
    width: 100
  },
  {
    label: '邮箱',
    ellipsis: true,
    editable: true,
    name: 'email',
    editOption: {},
    width: 100
  },
  {
    label: '照片',
    ellipsis: true,
    editable: false,
    name: 'photo',
    editOption: {},
    width: 100
  },
  {
    label: '类别',
    ellipsis: true,
    editable: false,
    name: 'type',
    formatter: 'select',
    editType: 'select',
    formatOption: { type: 'user.type' },
    editOption: { type: 'user.type' },
    width: 100
  },
  {
    label: '状态',
    ellipsis: true,
    editable: false,
    name: 'status',
    editOption: {},
    render: (value = '') => DictUtil.getByValue('user.status', value),
    width: 100
  }
];

export default columns;
