import SelectUtil from '@/util/SelectUtil';
import BaseService, { BaseEntity, BaseQuery, BaseServiceProps, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';

export interface SysArea extends BaseEntity<SysArea> {
  name: string;
  code: string;
  icon: string;
  href: string;
  permission: string;
  status: boolean;
}

export type SysAreaQuery = Partial<SysArea & BaseQuery>;

export type SysAreaServiceProps = BaseServiceProps;

class SysAreaService extends BaseService<SysArea, SysAreaQuery> {
  select = ({ query, name = '区域', validator }: SelectOption<SysArea, SysAreaQuery> = {}) =>
    SelectUtil.pop({
      name,
      loadTree: () => this.findTreeData(query),
      validator
    });
}

export default new SysAreaService(API_URL, 'sys-area');
