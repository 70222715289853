import React, { ReactNode } from 'react';
import { ConfigProvider, message, Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { ResultBean } from '@/service/base/BaseService';
import { createDomRoot } from '@/common/common';
import LocaleUtil from '@/util/LocaleUtil';
import './Dialog.less';
import Title from '@/component/Dialog/Title';
import classNames from 'classnames';
import { Handler, SPHandler } from '@/common/types';
import { themeToken } from '@/theme';
import ReactDOM from 'react-dom/client';

const confirm = (title: string, content: ReactNode, ok?: () => Promise<ResultBean<any>> | void, type?: string) => {
  const root = createDomRoot();
  const r = ReactDOM.createRoot(root);
  const props = {
    title,
    ok,
    rootDom: root,
    root: r,
    zoomMinEnable: false,
    zoomMaxEnable: false,
    type: type as any
  };
  r.render(
    <Dialog {...props}>
      <div>{content}</div>
    </Dialog>
  );
};

const success = (title: string, content: ReactNode, ok?: () => Promise<ResultBean<any>> | void) =>
  confirm(title, content, ok, 'success');
const warn = (title: string, content: ReactNode, ok?: () => Promise<ResultBean<any>> | void) =>
  confirm(title, content, ok, 'warn');
const error = (title: string, content: ReactNode, ok?: () => Promise<ResultBean<any>> | void) =>
  confirm(title, content, ok, 'error');

export declare interface DialogsOpenProps extends Omit<DialogsProps, 'ok'> {
  ok?: SPHandler<any, void>;
}

const open = ({ children, ok, ...rest }: Partial<DialogsOpenProps>) => {
  const root = createDomRoot();
  const r = ReactDOM.createRoot(root);
  r.render(
    <Dialog
      {...rest}
      rootDom={root}
      root={r}
      ok={async data => {
        await ok?.(data);
        return { code: 200, data } as ResultBean<any>;
      }}
    >
      {children}
    </Dialog>
  );
};

export declare interface DialogsProps extends ModalProps {
  secondConfirm?: boolean;
  ok?: SPHandler<any, Promise<ResultBean<any>> | void>;
  afterOk?: SPHandler<ResultBean<any>, void> | Handler<void>;
  cancel?: Handler<Promise<ResultBean<any>> | void>;
  rootDom: HTMLElement;
  root: ReactDOM.Root;
  children: ReactNode;
  zoomMinEnable?: boolean;
  zoomMaxEnable?: boolean;
  type?: 'success' | 'warn' | 'error';
}

export declare interface DialogsState {
  onOk?: () => void;
  onCancel?: () => void;
  visible: boolean;
  zoomMax: boolean;
  okText: ReactNode;
  cancelText: ReactNode;
  data?: any;
}

export default class Dialog extends React.PureComponent<DialogsProps, DialogsState> {
  static confirm = confirm;

  static success = success;

  static warn = warn;

  static error = error;

  static open = open;

  private readonly children: ReactNode;

  private ok?: (data?: any) => Promise<ResultBean<any>> | void = this.props.ok;

  private cancel?: () => Promise<ResultBean<any>> | void = this.props.cancel;

  state = {
    okText: this.props.okText || LocaleUtil.get('dialog.btn.ok.text'),
    cancelText: this.props.cancelText || LocaleUtil.get('dialog.btn.cancel.text'),
    visible: true,
    zoomMax: false,
    data: undefined
  };

  constructor(props: any) {
    super(props);
    const { children } = this.props;
    const type = (children as any)?.type;
    this.children =
      typeof type === 'function'
        ? React.cloneElement<any, any>(children as any, {
            setHandler: this.setHandler,
            setDialog: (props = {}) => this.setState(props),
            onOk: (data: any) => this.onSelect(data, this.onOk),
            onSelect: this.onSelect
          })
        : children;
  }

  setHandler = (ok: (data?: any) => Promise<ResultBean<any>>, cancel?: () => Promise<ResultBean<any>>) => {
    this.ok = ok;
    this.cancel = cancel;
  };

  close = () => {
    this.setState({ visible: false }, this.removeDom);
  };

  removeDom = () => {
    const { rootDom, root } = this.props;
    setTimeout(() => {
      try {
        root.unmount();
        document.body.removeChild(rootDom);
      } catch (e) {
        console.error(e);
      }
    }, 1000);
  };

  onOk = async () => {
    const { secondConfirm = false } = this.props;
    const { data } = this.state;
    if (secondConfirm) {
      return warn('提交确认', '确定要提交吗？', () => {
        this.onComplete(data);
        return Promise.resolve({ code: 200, msg: '成功' } as any);
      });
    }

    return this.onComplete(data);
  };

  onSelect = (data: any, handler: Handler<any>) => {
    this.setState({ data }, handler);
  };

  onComplete = async (data?: any) => {
    try {
      const bean = await this.ok?.(data);
      if (!bean || bean.code === 200) {
        this.close();
        this.props.afterOk?.(bean || ({ code: 200, msg: '成功' } as ResultBean<any>));
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        message.error(e.toString());
      } else {
        console.error(e);
        message.error('无法完成请求.');
      }
    }
  };

  onCancel = async () => {
    this.close();
    await this.cancel?.();
  };

  zoom = (zoomMax: boolean) => this.setState({ zoomMax });

  render() {
    const { rootDom, title, zoomMinEnable, zoomMaxEnable, styles = {}, type, ...rest } = this.props;
    const { visible, zoomMax, okText, cancelText } = this.state;

    const props = {
      title: (
        <Title
          title={title as any}
          type={type}
          zoomMin={zoomMinEnable}
          zoomMax={zoomMaxEnable}
          close={this.close}
          zoom={this.zoom}
          root={rootDom}
        />
      ),
      destroyOnClose: true,
      open: visible,
      maskClosable: false,
      children: this.children,
      onOk: this.onOk,
      closable: false,
      getContainer: rootDom,
      wrapClassName: classNames({ 'ant-modal-zoom-max': zoomMax }),
      onCancel: this.onCancel,
      okText,
      cancelText,
      styles: { ...styles }
    };

    return (
      <ConfigProvider
        locale={LocaleUtil.getAntdLocale()}
        theme={{
          ...themeToken
        }}
      >
        <Modal {...rest} {...props} />
      </ConfigProvider>
    );
  }
}
