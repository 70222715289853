import React, { useMemo } from 'react';
import { Radio } from 'antd';
import { isNull, USE_LOCALE } from '@/common/common';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { WrappedFormItemProps } from '../WrappedFormItem';
import DictUtil from '../../../util/DictUtil';
import LocaleUtil from '../../../util/LocaleUtil';

const WrappedRadio: React.FC<WrappedFormItemProps<any>> = React.forwardRef(
  (props: WrappedFormItemProps<any>, ref: React.Ref<any>) => {
    const { column, value, onChange } = props;
    const { editOption } = column;
    const { disabled, type } = editOption;
    const list = useMemo(() => DictUtil.get(type || ''), [type]);
    const mapProps = {
      value: isNull(value) ? value : value.toString(),
      onChange: ({ target: { value } }: RadioChangeEvent) => onChange?.(value),
      disabled
    };

    return (
      <Radio.Group {...mapProps} ref={ref}>
        {list.map(({ code, name, value }) => (
          <Radio value={value} key={value}>
            {USE_LOCALE && code ? LocaleUtil.get(code, name) : name}
          </Radio>
        ))}
      </Radio.Group>
    );
  }
);

export default WrappedRadio;
