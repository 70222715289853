import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import { cloneDeep } from 'lodash-es';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import TableUtil from '@/component/Table/Util/TableUtil';
import TableService, { Table } from '@/service/system/TableService';
import { EditableTable, WrappedForm } from '@/component';
import { ResultBean } from '@/service/base/BaseService';
import TableColumnService, { TableColumn } from '@/service/system/TableColumnService';
import tableColumnCols from '../tableColumn/columns';
import columns from './columns';

export declare interface TableFormProps {
  record: Table;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export default class TableForm extends React.PureComponent<TableFormProps, any> {
  formRef = React.createRef<FormInstance>();

  tableRef = React.createRef<EditableTable<TableColumn>>();

  columns = cloneDeep(columns);

  constructor(props: TableFormProps) {
    super(props);

    const { editOption = {} } = this.columns.find(({ name }) => name === 'name') as EditableColumnProps<Table>;
    editOption.onChange = (tableName: string) => this.getUtil().reload({ tableName, pageSize: 200 });
  }

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    await this.tableRef.current?.util.serialize();
    const bean = await TableService.saveBy({
      table: Object.assign(record, data),
      columnList: this.tableRef.current?.util.getDataSource()
    });

    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  getUtil = (): TableUtil<TableColumn> => this.tableRef.current?.util as TableUtil<TableColumn>;

  createAll = () => {
    this.getUtil().clearStore();
    this.getUtil().createAll();
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;

    const props = {
      columns: this.columns,
      record,
      ref: this.formRef,
      span: 8,
      isGrid: true,
      style: { padding: '10px 20px' }
    };

    return (
      <>
        <WrappedForm {...props} />
        <EditableTable<TableColumn>
          ref={this.tableRef}
          service={TableColumnService}
          autoSave={false}
          columns={tableColumnCols}
          isEdit
          toolbar={{ editOnly: true }}
          pageSize={200}
          query={record.isNewRecord ? {} : { tableId: record.id, tableName: record.name }}
          loadData={TableColumnService.findAll}
          loadComplete={this.createAll}
          isTree={false}
        />
      </>
    );
  }
}
