import { ContractItem } from '@/service/repository/ContractItemService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import ProjectService from '@/service/repository/ProjectService';
import SysOfficeService from '@/service/system/SysOfficeService';

const searchColumns: EditableColumnProps<ContractItem>[] = [
  {
    label: '项目',
    name: 'projectId',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 12,
    editType: 'pop-select',
    editOption: {
      prefix: 'project',
      popup: ProjectService.select,
      itemStyle: { width: '200px' }
    }
  },
  {
    label: '合同名称',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    span: 12,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '执行部门',
    name: 'executorId',
    code: 'executor.name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 12,
    editType: 'pop-select',
    editOption: {
      prefix: 'executor',
      popup: () => SysOfficeService.selectProprietor(true)
    },
    editRule: { maxLength: 50 }
  },
  {
    label: '施工单位',
    name: 'devCompanyId',
    code: 'dev.company',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    span: 12,
    editType: 'pop-select',
    editOption: {
      prefix: 'devCompany',
      popup: SysOfficeService.select
    },
    editRule: { maxLength: 50 }
  },
  {
    label: '合同编号',
    name: 'contractNo',
    code: 'contract.no',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    span: 12,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '合同金额开始',
    name: 'contractAmountStart',
    code: 'contractAmountStart',
    ellipsis: true,
    editable: true,
    query: true,
    width: 140,
    span: 12,
    align: 'left',
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: {}
  },
  {
    label: '合同金额结束',
    name: 'contractAmountEnd',
    code: 'contractAmountEnd',
    ellipsis: true,
    query: true,
    editable: false,
    width: 140,
    span: 12,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    align: 'left',
    editOption: {},
    editRule: {}
  },
  {
    label: '状态',
    name: 'status',
    code: 'status',
    ellipsis: true,
    editable: true,
    query: true,
    span: 12,
    formatter: 'select',
    formatOption: { type: 'contract.status' },
    align: 'center',
    width: 100,
    editType: 'select',
    editOption: { type: 'contract.status' },
    editRule: { maxLength: 50 }
  },
  {
    label: '签约日期开始',
    name: 'signDateStart',
    code: 'signDateStart',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    span: 12,
    editType: 'date',
    editOption: {},
    editRule: {}
  },
  {
    label: '签约日期截止',
    name: 'signDateEnd',
    code: 'signDateEnd',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    span: 12,
    editType: 'date',
    editOption: {},
    editRule: {}
  },
  {
    label: '招标类型',
    name: 'bidType',
    code: 'bid.type',
    ellipsis: true,
    editable: true,
    query: true,
    span: 12,
    width: 100,
    align: 'center',
    formatter: 'select',
    formatOption: { type: 'contract.bid.type' },
    editType: 'radio',
    editOption: { type: 'contract.bid.type', span: 21 },
    editRule: { maxLength: 50 }
  },
  {
    label: '招标模式',
    name: 'bidMode',
    code: 'bid.mode',
    ellipsis: true,
    editable: true,
    query: true,
    width: 100,
    span: 12,
    formatter: 'select',
    formatOption: { type: 'contract.bid.mode' },
    editType: 'radio',
    editOption: { type: 'contract.bid.mode', span: 21 },
    align: 'center',
    editRule: { maxLength: 50 }
  },
  {
    label: '批复类型',
    name: 'qualifyType',
    code: 'qualify.type',
    ellipsis: true,
    editable: true,
    query: false,
    width: 100,
    span: 12,
    formatOption: { type: 'contract.qualify.type' },
    align: 'center',
    editOption: {},
    editRule: { maxLength: 50 }
  }
];

export default searchColumns;
