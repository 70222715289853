import React from 'react';
import { SysMenu } from '@/service/system/SysMenuService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import WrappedSwitch from '@/component/Form/component/WrappedSwitch';

const columns: EditableColumnProps<SysMenu>[] = [
  {
    label: '名称',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editOption: {},
    editRule: { required: true, maxLength: 20 }
  },
  {
    label: '国际化编码',
    name: 'code',
    code: 'locale.code',
    ellipsis: true,
    editable: true,
    query: true,
    width: 220,
    editOption: {},
    editRule: { required: true, maxLength: 200 }
  },
  {
    label: '链接',
    name: 'href',
    code: 'href',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editOption: {},
    editRule: { maxLength: 200 }
  },
  {
    label: '图标',
    code: 'icon',
    ellipsis: true,
    editable: true,
    name: 'icon',
    width: 150,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '权限标志',
    ellipsis: true,
    editable: true,
    name: 'permission',
    code: 'permission',
    width: 150,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '排序号',
    ellipsis: true,
    editable: true,
    name: 'sort',
    code: 'sort',
    width: 100,
    editOption: {}
  },
  {
    label: '显示',
    name: 'status',
    ellipsis: true,
    editable: true,
    editType: 'switch',
    render: (status: boolean) => <WrappedSwitch disabled value={status} />,
    width: 100,
    editRule: { maxLength: 20 },
    editOption: {}
  }
];

export default columns;
