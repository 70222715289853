import { ContractMixDetail } from '@/service/repository/ContractMixDetailService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<ContractMixDetail>[] = [
  {
    label: '编号',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'id',
    formatter: 'text',
    formatOption: {},
    editType: 'pop-select',
    editOption: {},
    editRule: {
      maxLength: 19
    },
    width: 100
  },
  {
    label: '配合比',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'contractMixId',
    formatter: 'text',
    formatOption: {},
    editType: 'pop-select',
    editOption: {},
    editRule: {
      maxLength: 19
    },
    width: 100
  },
  {
    label: '配合比名称',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'contractMixName',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {
      maxLength: 100
    },
    width: 100
  },
  {
    label: '材料名称',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'materialId',
    formatter: 'text',
    formatOption: {},
    editType: 'pop-select',
    editOption: {},
    editRule: {
      maxLength: 10
    },
    width: 100
  },
  {
    label: '材料名称',
    ellipsis: true,
    editable: false,
    name: 'materialName',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: { disabled: true },
    editRule: {
      maxLength: 100
    },
    width: 100
  },
  {
    label: '单位',
    ellipsis: true,
    editable: false,
    name: 'unit',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {
      maxLength: 20
    },
    width: 100
  },
  {
    label: '数量',
    ellipsis: true,
    editable: false,
    name: 'num',
    formatter: 'number',
    formatOption: { precision: 4 },
    editType: 'text',
    editOption: { disabled: true },
    editRule: {
      required: true
    },
    align: 'right',
    width: 100
  },
  {
    label: '损耗系数1',
    ellipsis: true,
    editable: false,
    name: 'lossFactor1',
    formatter: 'number',
    formatOption: { precision: 4 },
    editType: 'text',
    editOption: { disabled: true },
    editRule: {
      required: true
    },
    align: 'right',
    width: 100
  },
  {
    label: '损耗系数2',
    ellipsis: true,
    editable: false,
    name: 'lossFactor2',
    formatter: 'number',
    formatOption: { precision: 4 },
    editType: 'text',
    editOption: { disabled: true },
    editRule: {
      required: true
    },
    align: 'right',
    width: 100
  },
  {
    label: '合同数量',
    ellipsis: true,
    editable: true,
    name: 'contractNum',
    formatter: 'number',
    formatOption: { precision: 4 },
    editType: 'text',
    editOption: {},
    editRule: { required: true },
    align: 'right',
    width: 100
  },
  {
    label: '合同损耗系数1',
    ellipsis: true,
    editable: true,
    name: 'contractLossFactor1',
    formatter: 'number',
    formatOption: { precision: 4 },
    editType: 'text',
    editOption: {},
    editRule: { required: true },
    align: 'right',
    width: 100
  },
  {
    label: '合同损耗系数2',
    ellipsis: true,
    editable: true,
    name: 'contractLossFactor2',
    formatter: 'number',
    formatOption: { precision: 4 },
    editType: 'text',
    editOption: {},
    editRule: { required: true },
    align: 'right',
    width: 100
  },
  {
    label: '创建人编号',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'createBy',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {},
    width: 100
  },
  {
    label: '创建人名称',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'createName',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {
      maxLength: 50
    },
    width: 100
  },
  {
    label: '创建日期',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'createDate',
    formatter: 'date',
    formatOption: {},
    editType: 'date',
    editOption: {},
    editRule: {},
    width: 100
  },
  {
    label: '更新人编号',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'updateBy',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {},
    width: 100
  },
  {
    label: '更新人名称',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'updateName',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {
      maxLength: 50
    },
    width: 100
  },
  {
    label: '更新日期',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'updateDate',
    formatter: 'date',
    formatOption: {},
    editType: 'date',
    editOption: {},
    editRule: {},
    width: 100
  },
  {
    label: '备注',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'remarks',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {
      required: true,
      maxLength: 20
    },
    width: 100
  },
  {
    label: '删除标志',
    ellipsis: true,
    hidden: true,
    editable: false,
    name: 'delFlag',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    editRule: {},
    width: 100
  }
];

export default columns;
