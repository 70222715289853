import SelectUtil from '@/util/SelectUtil';
import { Material, MaterialQuery } from '@/service/material/MaterialService';
import BaseService, { BaseEntity, BaseQuery, ResultBean, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';

export interface StockDetail extends BaseEntity<StockDetail> {
  companyId: string;
  companyName: string;
  stockId: string;
  stockName: string;
  materialId: string;
  materialName: string;
  serial: string;
  unit: string;
  no: string;
  name: string;
  type: number;
  category: number;
  price: number;
  num: number;
  alertNum: number;
  remarks: string;
}

export interface StockDetailAlertDTO {
  id: string;
  alertNum: number;
}

export declare type StockDetailQuery = Partial<StockDetail & BaseQuery>;

class StockService extends BaseService<StockDetail, StockDetailQuery> {
  select = ({ name = '材料', validator, query }: SelectOption<StockDetail, StockDetailQuery> = {}) =>
    SelectUtil.pop({
      name,
      loadTree: () => this.findTreeData(query),
      validator: material => {
        if (!material.isMaterial) {
          throw new Error(`没有选择${name}`);
        }

        validator?.(material);
      }
    });

  alert = (alert: StockDetailAlertDTO) => this.request<Promise<ResultBean<any>>>('post', 'alert', alert);
}

export default new StockService(API_URL, 'material/stock-detail');
