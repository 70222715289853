import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';
import { SysUser } from './SysUserService';
import { SysRole } from './SysRoleService';

export interface SysUserRole extends BaseEntity<SysUserRole> {
  roleId: string;
  roleName: string;
  userId: string;
  userName: string;
}

export interface SysUserRoleQuery extends BaseQuery {
  userId: string;
}

export interface SysUserRoleGrantVO {
  user: SysUser;
  roleList: SysRole[];
}

class SysUserRoleService extends BaseService<SysUserRole, SysUserRoleQuery> {
  listByUserId = async (userId: string) => {
    const { code, data } = await this.findList({ userId });
    return code === 200 ? data : null;
  };

  grant = (vo: SysUserRoleGrantVO): Promise<ResultBean<boolean>> => this.request('post', 'grant', vo);
}

export default new SysUserRoleService(API_URL, 'sys-user-role');
