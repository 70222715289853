import axios, { AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import qs from 'qs';
import { Base64 } from 'js-base64';
import './interceptor';
import loading from '@/component/Loading/Loading';
import { checkStatus, HTTP_STATUS } from './response';

const codeMessage: any = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
};
const responseStatus: any = (response: {
  status: number;
  data: Record<string, unknown>;
  statusText: string;
  headers: any;
}) => {
  const status = response && response.status;
  const contentType = response.headers['content-type'];
  if (status && status >= 200 && status < 300) {
    return response.data;
  }

  if (contentType === 'application/json' && response.data.code !== HTTP_STATUS.OK) {
    message.error(response?.data?.msg as any);
    return response;
  }

  if (!status) {
    return {};
  }

  if (status !== HTTP_STATUS.UNAUTHORIZED) {
    return response;
  }

  const error = codeMessage[status] || response.statusText;
  message.error(`请求错误 ${response.status}: ${error}`);
  throw response;
};

class Http {
  get = async (url: string, data: Record<string, unknown>, options?: AxiosRequestConfig, quietly?: boolean) => {
    const URL = `${url}`;
    return axios({
      method: 'get',
      url: URL,
      params: data,
      withCredentials: true,
      responseType: options?.responseType,
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      transformResponse: [data => JSON.parse(data)]
    })
      .then(response => checkStatus(responseStatus(response), quietly))
      .catch(response => responseStatus(response.status ? response : response.response));
  };

  post = async (url: string, data: Record<string, unknown>, options?: AxiosRequestConfig) =>
    axios({
      method: 'post',
      url: `${url}`,
      withCredentials: true,
      data: data || {},
      responseType: options?.responseType,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then(response => checkStatus(responseStatus(response)))
      .catch(response => responseStatus(response.status ? response : response.response));

  delete = async (url: string, data: string) => {
    const URL = data ? `${url}/${data}` : `${url}`;
    return axios({
      method: 'delete',
      url: URL,
      data: data || {},
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then(response => checkStatus(responseStatus(response)))
      .catch(response => responseStatus(response.status ? response : response.response));
  };

  put = async (url: string, data: Record<string, unknown>) => {
    const URL = `${url}`;
    return axios({
      method: 'put',
      url: URL,
      data: data || {},
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then(response => checkStatus(responseStatus(response)))
      .catch(response => responseStatus(response.status ? response : response.response));
  };

  postLogin = async (url: string, data: { username: string; password: string }) => {
    const URL = `${url}`;
    return axios({
      method: 'post',
      url: URL,
      data: qs.stringify(data) || {},
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })
      .then(response => checkStatus(responseStatus(response)))
      .catch(response => responseStatus(response.status ? response : response.response));
  };

  postLoginByMobile = async (url: string, data: { username: string; password: string }) => {
    const URL = `${url}`;
    return axios({
      method: 'post',
      url: URL,
      data: qs.stringify(data) || {},
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        Authorization: `SMS_CODE ${Base64.encode(`${data.username}:${data.password}`)}`
      }
    })
      .then(response => checkStatus(responseStatus(response)))
      .catch(response => responseStatus(response.status ? response : response.response));
  };

  postAuth = async (url: string, data: { username: string; password: string }) => {
    const URL = `${url}`;
    return axios({
      method: 'post',
      url: URL,
      data: qs.stringify(data) || {},
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        Authorization: 'Basic fdsfdsfdsfds'
      }
    })
      .then(response => checkStatus(responseStatus(response)))
      .catch(response => responseStatus(response.status ? response : response.response));
  };

  request = async (options: AxiosRequestConfig) =>
    axios({ withCredentials: true, ...options })
      .then(response => checkStatus(responseStatus(response)))
      .catch(response => responseStatus(response.status ? response : response.response));
}

class WrappedHttp {
  static http: Http = new Http();

  static msg = '执行中，请稍等...';

  get = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.get);

  post = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.post);

  put = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.put);

  delete = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.delete);

  postLogin = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.postLogin);

  postLoginByMobile = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.postLoginByMobile);

  postAuth = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.postAuth);

  request = loading.wrapped(WrappedHttp.msg, WrappedHttp.http.request);
}

export { Http, WrappedHttp };

export default new WrappedHttp();
