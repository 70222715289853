import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import { ResultBean } from '@/service/base/BaseService';
import { castTo } from '@/common/common';
import { WrappedForm } from '../../../component';
import columns from './columns';
import MaterialService, { Material } from '../../../service/material/MaterialService';

export declare interface MaterialFormProps {
  record: Material;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export declare interface MaterialFormState {}

export default class MaterialForm extends React.PureComponent<MaterialFormProps, MaterialFormState> {
  formRef = React.createRef<FormInstance>();

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await MaterialService.save(castTo<Material>(Object.assign(record, data)));
    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;

    const props = {
      columns,
      record,
      span: 24,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
