import React from 'react';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import LocaleService, { SysLocale, SysLocaleQuery } from '@/service/system/SysLocaleService';
import { DeleteOutlined, DownloadOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import LocaleUtil from '@/util/LocaleUtil';
import TableUtil from '@/component/Table/Util/TableUtil';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { buildUrl } from '@/service/url';
import FileUpload from '@/view/component/FileUpload/FileUpload';
import DictUtil from '@/util/DictUtil';
import columns from './columns';
import LocaleForm from './Form';

export declare interface LocaleListProps {
  onClick: (record: SysLocale, event: any) => void;
}

export declare interface LocaleListState {
  record?: SysLocale;
  query: Record<string, unknown>;
  refresh: boolean;
}

export default class List extends React.PureComponent<LocaleListProps, LocaleListState> {
  state = {
    query: {} as any,
    refresh: false
  };

  tableRef = React.createRef<EditableTable<SysLocale>>();

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || {};
    this.edit(LocaleUtil.get('locale.add.title'), { ...record, id: null } as any);
  };

  edit = (title: string, record: SysLocale) => {
    Dialog.open({
      title,
      children: <LocaleForm record={record} />,
      width: 600,
      afterOk: this.getUtil().refresh
    });
  };

  delete = async () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error(LocaleUtil.get('data.unselect.notice'));
    }

    Dialog.confirm(LocaleUtil.get('locale.delete.title'), LocaleUtil.get('data.delete.notice'), async () => {
      const bean = await LocaleService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  import = () => {
    const { lang } = this.state.query;
    if (!lang) {
      return message.error('未选择要导入的语言');
    }

    const name = DictUtil.getLocaledNameByValue('locale.lang', lang);
    const props = {
      url: buildUrl('api', '/repository/sys-locale/upload'),
      data: { lang }
    };

    Dialog.open({
      title: `国际化语言【${name}】配置-导入`,
      children: <FileUpload {...props} />,
      width: 500,
      afterOk: this.getUtil().refresh
    });
  };

  export = () => {
    const { lang } = this.state.query;
    if (!lang) {
      return message.error('未选择要导出的语言');
    }
    const name = DictUtil.getLocaledNameByValue('locale.lang', lang);
    LocaleService.export(`国际化【${name}】导出.xlsx`, { lang });
  };

  getUtil = (): TableUtil<SysLocale> => this.tableRef.current?.util as TableUtil<SysLocale>;

  onQuery = (query: SysLocaleQuery) => {
    this.setState({ query }, () => this.getUtil().reload(query));
  };

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'yellow',
      icon: <UploadOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.import,
      code: 'import',
      title: '导入'
    },
    {
      type: 'yellow',
      icon: <DownloadOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.export,
      code: 'export',
      title: '导出'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<SysLocale>
          ref={this.tableRef}
          service={LocaleService}
          autoSave
          columns={columns}
          onDoubleClick={record => this.edit(LocaleUtil.get('locale.modify.title'), record)}
          loadData={LocaleService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
