import React, { ReactNode } from 'react';
import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';

export declare interface WrappedUploadProps extends UploadProps {
  maxNum?: number;
  children?: ReactNode;
}

const WrappedUpload: React.FC<WrappedUploadProps> = ({ children, maxNum = 1, ...rest }: WrappedUploadProps) => (
  <Upload {...rest}>{maxNum ? children : <></>}</Upload>
);

export default WrappedUpload;
