import { Material } from '@/service/material/MaterialService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<Material>[] = [
  {
    label: '名字',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 150,
    editOption: {},
    editRule: { required: true, maxLength: 50 }
  },
  {
    label: '类型',
    name: 'type',
    code: 'type',
    ellipsis: true,
    editable: true,
    query: true,
    width: 80,
    formatter: 'select',
    formatOption: {
      type: 'material.type'
    },
    editType: 'radio',
    editOption: { type: 'material.type' },
    align: 'center',
    editRule: { required: true, maxLength: 50 }
  },
  {
    label: '单价',
    name: 'price',
    code: 'price',
    hidden: true,
    editable: false,
    width: 100,
    formatter: 'currency',
    align: 'right',
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '单位',
    name: 'unit',
    code: 'unit',
    ellipsis: true,
    editable: true,
    width: 80,
    align: 'left',
    editOption: {},
    editRule: { required: true, maxLength: 20 }
  },
  {
    label: '规格1',
    name: 'specField1',
    code: 'specField1',
    ellipsis: true,
    editable: true,
    width: 100,
    align: 'left',
    editOption: {},
    editRule: { required: true, maxLength: 20 }
  },
  {
    label: '规格2',
    name: 'specField2',
    code: 'specField2',
    ellipsis: true,
    editable: true,
    width: 100,
    align: 'left',
    editOption: {},
    editRule: { required: false, maxLength: 20 }
  },
  {
    label: '分类',
    name: 'category',
    code: 'category',
    hidden: true,
    editable: false,
    width: 100,
    align: 'center',
    formatter: 'select',
    formatOption: { type: 'material.category' },
    editType: 'radio',
    editOption: { type: 'material.category' },
    editRule: { required: true }
  },
  {
    label: '核销状态',
    name: 'status',
    code: 'status',
    hidden: true,
    editable: false,
    width: 100,
    align: 'center',
    editType: 'switch',
    editOption: { type: 'sys.bool' },
    formatter: 'switch',
    formatOption: { type: 'sys.bool' },
    editRule: { required: true }
  },
  {
    label: '备注',
    name: 'remarks',
    code: 'remark',
    ellipsis: true,
    editable: true,
    width: 150,
    align: 'left',
    editType: 'text',
    editOption: {},
    editRule: { maxLength: 50 }
  }
];

export default columns;
