import React from 'react';
import { ProcessExt } from '@/service/process/ProcessExtService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import WrappedSwitch from '@/component/Form/component/WrappedSwitch';

const columns: EditableColumnProps<ProcessExt>[] = [
  {
    label: '名字',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '键值',
    name: 'key',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '类型',
    name: 'type',
    ellipsis: true,
    editable: true,
    formatter: 'select',
    formatOption: { type: 'process.type' },
    editType: 'radio',
    editOption: { type: 'process.type' },
    width: 100,
    editRule: { required: true },
    align: 'center'
  },
  {
    label: '授权',
    name: 'permission',
    ellipsis: true,
    editable: true,
    formatter: 'select',
    formatOption: { type: 'proc.permission' },
    editType: 'radio',
    editOption: { type: 'proc.permission' },
    width: 100,
    editRule: { required: true },
    align: 'center'
  },
  {
    label: '启用',
    name: 'status',
    ellipsis: true,
    editable: true,
    formatter: 'switch',
    formatOption: { type: 'sys.bool' },
    editType: 'switch',
    editOption: { type: 'sys.bool' },
    render: (status: boolean) => <WrappedSwitch disabled value={status} />,
    width: 100,
    editRule: { required: true },
    align: 'center'
  },
  {
    label: '排序',
    name: 'sort',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: {},
    align: 'right'
  }
];

export default columns;
