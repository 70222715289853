import BaseService, { BaseEntity, BaseQuery } from '../base/BaseService';
import { API_URL } from '../url';

export interface BalanceStyle extends BaseEntity<BalanceStyle> {
  templateId: string;
  templateName: string;
  no: string;
  name: string;
  mode: number;
  serial: string;
  editable: boolean;
  param: string;
  ratio: string;
}

export declare type BalanceStyleQuery = Partial<BalanceStyle & BaseQuery>;

class BalanceStyleService extends BaseService<BalanceStyle, BalanceStyleQuery> {}

export default new BalanceStyleService(API_URL, 'template/balance-style');
