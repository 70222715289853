import React, { memo } from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { WrappedFormItemProps } from '../WrappedFormItem';

const { RangePicker } = DatePicker;

const WrappedDateRange: React.FC<WrappedFormItemProps<any>> = (props: WrappedFormItemProps<any>) => {
  const { column, onChange, record } = props;
  const { editOption } = column;
  const { pattern = 'YYYY-MM-DD', disabled, prefix = 'date' } = editOption;
  const days = [record[`${prefix}Start`], record[`${prefix}End`]];
  const mapProps = {
    value: days.map(day => (day ? (dayjs(day, pattern) as any) : undefined)),
    onChange: (values: Dayjs[], [start, end]: string[]) => {
      record[`${prefix}End`] = end;
      record[`${prefix}Start`] = start;
      onChange?.(start);
    },
    disabled,
    format: pattern
  };

  return <RangePicker {...(mapProps as any)} style={{ width: '100%' }} />;
};

export default memo(WrappedDateRange, ({ value }, nextProps) => value === nextProps.value);
