import SelectUtil from '@/util/SelectUtil';
import BaseService, { BaseEntity, BaseQuery, ResultBean, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';
import { Estimate } from './EstimateService';

export interface Project extends BaseEntity<Project> {
  companyId: string;
  companyName: string;
  designerId: string;
  designerName: string;
  no: string;
  name: string;
  type: string;
  approveNo: string;
  estimateAmount: number;
  budgetAmount: string;
  startDate: string;
  endDate: string;
}

export declare type ProjectQuery = Partial<Project & BaseQuery>;

export interface ProjectBatchBean {
  project: Project;
  estimateList?: Estimate[];
}

class ProjectService extends BaseService<Project, ProjectQuery> {
  saveBy = (bean: ProjectBatchBean) => this.request<ResultBean<any>>('post', 'save', bean);

  select = ({ name = '工程', validator, query }: SelectOption<Project, ProjectQuery> = {}) =>
    SelectUtil.pop({
      name,
      loadTree: () => this.findTreeData(query),
      validator
    });
}

export default new ProjectService(API_URL, 'repository/project');
