import React, { useEffect, useState } from 'react';
import SysUserService from '@/service/system/SysUserService';

declare interface SignatureProps {
  id?: string;
  name?: string;
}

const Signature: React.FC<SignatureProps> = ({ id, name }: SignatureProps) => {
  const [imgURL, setURL] = useState<string>('');
  useEffect(() => {
    const idStr = id!;
    if (id) {
      (async () => {
        const { code, data } = await SysUserService.getSignature(idStr);
        if (code === 200) {
          const reader = new FileReader();
          reader.readAsDataURL(new Blob([data], { type: 'image/jpeg' }));
          reader.onload = event => {
            setURL(event.target?.result as any);
          };
        }
      })();
    }
  });
  return imgURL === '' ? <>{name}</> : <img style={{ width: '120px', height: '40px' }} src={imgURL} alt="" />;
};

Signature.defaultProps = {
  id: undefined,
  name: ''
};

export default Signature;
