import React, { useCallback, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import CellList from '@/component/Form/component/WrappedTable/CellList';
import { FIELD_SPLITTER } from '@/component/Form/component/WrappedTable/Table';
import { useDebounce, useUpdateEffect } from 'ahooks';

export declare interface WrappedTableProps {
  columns: EditableColumnProps<any>[];
  record?: Record<string, unknown>;
  onChange: (index: number, record: Record<string, string>, formatedValue: string) => void;
  auto?: boolean;
  readonly?: boolean;
  formRef: React.RefObject<FormInstance>;
  rowNum: number;
}

const Row: React.FC<WrappedTableProps> = (props: WrappedTableProps) => {
  const { record = {}, rowNum, columns, onChange, formRef } = props;
  const [currentRecord, setRecord] = useState(record);
  const debounceRecord = useDebounce(currentRecord, { wait: 800 });
  const onValueChange = useCallback(
    (name: string, value: string) => {
      const [realName] = name.split('_');
      debounceRecord[realName] = value;
      setRecord({ ...debounceRecord });
    },
    [debounceRecord, setRecord]
  );

  const rowProps = {
    formRef,
    record,
    onChange: onValueChange,
    columns,
    rowNum
  };

  useUpdateEffect(() => {
    const formatedValue = columns
      .map(({ name }) => {
        const [n] = name.split('_');
        return currentRecord[n] || '';
      })
      .join(FIELD_SPLITTER);
    onChange(rowNum, { ...currentRecord } as any, formatedValue);
  }, [debounceRecord]);

  return <CellList {...rowProps} />;
};

export default Row;
