import BaseService, { BaseEntity, BaseQuery } from '../base/BaseService';
import { API_URL } from '../url';

export interface TableColumn extends BaseEntity<TableColumn> {
  tableId: string;
  tableName: string;
  name: string;
  jdbcType: string;
  javaType: string;
  fieldName: string;
  isNull: string;
  isEdit: boolean;
  isQuery: boolean;
  queryType: string;
  showType: string;
  dictType: string;
  editType: string;
  sort: number;
  comment: string;
  length: string;
  scale: string;
  align: string;
}

export declare type TableColumnQuery = Partial<TableColumn & BaseQuery>;

class TableColumnService extends BaseService<TableColumn, TableColumnQuery> {}

export default new TableColumnService(API_URL, 'table-column');
