import { TaskExt } from '@/service/process/TaskExtService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import ContractService from '@/service/repository/ContractService';

const columns: EditableColumnProps<TaskExt>[] = [
  {
    label: '合同名称',
    code: 'contractId',
    name: 'contractName',
    ellipsis: true,
    editable: true,
    query: true,
    hidden: true,
    width: 200,
    editType: 'pop-select',
    editOption: {
      prefix: 'contract',
      popup: ContractService.select
    },
    editRule: { required: true }
  },
  {
    label: '开始日期',
    code: 'begin.start.date',
    name: 'beginDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 160,
    editType: 'date',
    editOption: {},
    editRule: { required: true }
  },
  {
    label: '到',
    code: 'begin.end.date',
    name: 'endDate',
    ellipsis: true,
    editable: true,
    query: true,
    width: 160,
    editType: 'date',
    editOption: {},
    editRule: { required: true }
  }
];

export default columns;
