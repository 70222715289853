import BaseService, { BaseEntity, BaseQuery, SelectOption } from '../base/BaseService';
import { API_URL } from '../url';
import ContractItemService, { ContractItem } from './ContractItemService';

export interface Contract extends BaseEntity<Contract> {
  companyId: string;
  companyName: string;
  executorId: string;
  executorName: number;
  devCompanyId: string;
  devCompanyName: number;
  devManagerId: string;
  devManagerName: string;
  supervisorId: string;
  supervisorName: string;
  consultId: string;
  consultName: string;
  designCompanyId: string;
  designCompanyName: string;
  projectId: string;
  projectName: string;
  estimateId: string;
  estimateName: string;
  estimateApprovalId: string;
  estimateApprovalName: string;
  no: string;
  name: string;
  section: string;
  days: number;
  warrantyDate: string;
  signDate: string;
  signPlace: string;
  startDate: string;
  endDate: string;
  type: number;
  category: number;
  amount: number;
  currencyType: number;
  estimateAmount: number;
  contractAmount: number;
  executeAmount: number;
  taxAmount: number;
  serial: string;
  status: number;
  bidNo: number;
  bidFile: number;
  bidDate: string;
  bidMode: string;
  bidType: string;
  bidStartDate: string;
  bidResult: string;
  bidResultReply: string;
  bidNoticeNo: string;
  bidAmount: number;
  materialMarginAmount: number;
  electricMarginAmount: number;
  replyDoc: number;
  replyDate: string;
  applyDoc: string;
  applyDate: string;
  qualifyType: number;
  telephone: number;
  exchangeRate: number;
  scaleRate: number;
}

export declare type ContractQuery = Contract & BaseQuery;

class ContractService extends BaseService<Contract, ContractQuery> {
  select = async ({ name = '合同选择', query = {} as ContractQuery }: SelectOption<Contract, ContractQuery> = {}) => {
    const item: ContractItem = await ContractItemService.select({
      name,
      validator: item => {
        if (item.type !== 2) {
          throw new Error('只能选择合同');
        }
      },
      query: { ...(query as any), usePrefix: true }
    });

    const { data } = await this.get(item.id);
    return data;
  };
}

export default new ContractService(API_URL, 'repository/contract');
