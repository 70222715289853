import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import ProcessExtService, { ProcessExt } from '@/service/process/ProcessExtService';
import { WrappedForm } from '@/component';
import { ResultBean } from '@/service/base/BaseService';
import columns from './columns';

export declare interface ProcessExtFormProps {
  record: ProcessExt;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export declare interface ProcessExtFormState {}

export default class ProcessExtForm extends React.PureComponent<ProcessExtFormProps, ProcessExtFormState> {
  formRef = React.createRef<FormInstance>();

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await ProcessExtService.save(Object.assign(record, data) as ProcessExt);
    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;

    const props = {
      columns,
      record,
      span: 24,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
