import React from 'react';
import { DeleteOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import { ToolbarOptionItem } from '@/component/Toolbar';
import SiderAttachment from '@/view/component/SiderAttachment/List';
import ContractItemService, { ContractItem, ContractItemQuery } from '../../../service/repository/ContractItemService';
import columns from './columns';
import searchColumns from './model/searchColumns';
import { Dialog, EditableTable, SearchBar, Toolbar } from '../../../component';
import TableUtil from '../../../component/Table/Util/TableUtil';
import ContractAccount from '../contract/Account';
import ContractService, { Contract } from '../../../service/repository/ContractService';
import ContractForm from '../contract/Form';
import SystemService from '../../../service/system/SystemService';

export declare interface ContractItemListProps {
  onClick: (record: ContractItem, event: any) => void;
}

export declare interface ContractItemListState {
  query: Record<string, unknown>;
  record?: ContractItem;
  refresh: boolean;
}

export default class List extends React.PureComponent<ContractItemListProps, ContractItemListState> {
  tableRef = React.createRef<EditableTable<ContractItem>>();

  attachment = React.createRef<SiderAttachment>();

  state = {
    query: {},
    refresh: false
  };

  delete = async () => {
    const record = this.tableRef.current?.util.getSelected()!;
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    if (record.type !== 2) {
      return message.error('只能删除合同');
    }

    Dialog.confirm('合同删除', '确定要删除数据吗？', async () => {
      const bean = await ContractService.delete(record.businessId);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  add = async () => {
    const record = this.tableRef.current?.util.getSelected() || {};
    const {
      code,
      msg,
      data: { id, name }
    } = await SystemService.getCurrentCompany();
    if (code !== 200) {
      return message.error(msg);
    }

    Dialog.open({
      title: '合同台账-新增',
      children: (
        <ContractForm
          toolbar={false}
          contract={Object.assign({} as Contract, record, { id: null, companyName: name, companyId: id })}
        />
      ),
      width: 1200,
      styles: { body: { height: '600px', paddingTop: '5px', paddingBottom: '5px' } },
      afterOk: this.tableRef.current?.util.refresh
    });
  };

  modify = async (record: ContractItem) => {
    const { code, data, msg } = await ContractService.get(record.businessId);
    if (code !== 200) {
      return message.error(msg);
    }

    if (!data) {
      return message.error('合同不存在');
    }

    Dialog.open({
      title: `合同台账-${data.name}`,
      children: <ContractAccount contract={data} />,
      width: 1400,
      styles: { body: { paddingTop: '5px', paddingBottom: '5px', height: '700px', display: 'flex' } },
      afterOk: this.tableRef.current?.util.refresh
    });
  };

  onClick = (record?: ContractItem) => {
    const { businessId, name } = record || {};
    if (businessId && name) {
      setTimeout(() => {
        this.attachment.current?.reload({ businessId, businessName: name });
      }, 500);
    }
  };

  export = () => ContractItemService.export('合同台账.xlsx');

  getUtil = (): TableUtil<ContractItem> => this.tableRef.current?.util as TableUtil<ContractItem>;

  onQuery = (query: ContractItemQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <DownloadOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.export,
      code: 'export',
      title: '导出'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={searchColumns} onQuery={this.onQuery} formStyle={{ width: '1000px' }} />
        <Toolbar items={this.items} />
        <EditableTable<ContractItem>
          ref={this.tableRef}
          service={ContractItemService}
          isTree
          autoSave
          columns={columns}
          onClick={this.onClick}
          onDoubleClick={this.modify}
          loadData={ContractItemService.findTree}
          toolbar={{ expandNum: 8, isFilter: true }}
        />
        <SiderAttachment ref={this.attachment} />
      </>
    );
  }
}
