import React, { memo, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import DictUtil from '@/util/DictUtil';
import { SysDict } from '@/service/system/SysDictService';
import { WrappedFormItemProps } from '../WrappedFormItem';

const CheckboxGroup = Checkbox.Group;

const toChecked = (value = '', dictList: SysDict[]): CheckboxValueType[] => {
  const list = value.split(',');
  return dictList.filter(({ value }) => list.includes(value)).map(({ name }) => name);
};

const toValue = (checked: CheckboxValueType[] = [], dictList: SysDict[]) =>
  dictList
    .filter(({ name }) => checked.includes(name))
    .map(({ value }) => value)
    .join(',');

const WrappedCheckbox: React.FC<WrappedFormItemProps<any>> = React.forwardRef(
  (
    {
      column: {
        editOption: { type, disabled = false }
      },
      value,
      onChange
    }: WrappedFormItemProps<any>,
    ref: React.Ref<any>
  ) => {
    const list = useMemo(() => DictUtil.get(type || ''), [type]);
    const [checked, setChecked] = useState<CheckboxValueType[]>(toChecked(value, list));
    const mapProps = {
      value: checked,
      disabled,
      onChange: (checked: CheckboxValueType[]) => {
        setChecked(checked);
        onChange?.(toValue(checked, list));
      },
      options: list.map(({ name }) => name) as string[],
      ref
    };

    return <CheckboxGroup {...mapProps} />;
  }
);

export default memo(WrappedCheckbox, ({ value }, nextProps) => value === nextProps.value);
