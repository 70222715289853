import React from 'react';
import ContractService, { Contract } from '@/service/repository/ContractService';
import { Toolbar, WrappedForm } from '@/component';
import { FormInstance } from 'antd/lib/form';
import { Divider, message } from 'antd';
import { ResultBean } from '@/service/base/BaseService';
import { SaveOutlined } from '@ant-design/icons/lib';
import LocaleUtil from '@/util/LocaleUtil';
import Permission from '@/component/Permission/Permission';
import columns from './columns';

export declare interface ContractFormProps {
  contract: Contract;
  toolbar: boolean;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
  readonly?: boolean;
}

export declare interface ContractFormState {}

export default class ContractForm extends React.PureComponent<ContractFormProps, ContractFormState> {
  formRef = React.createRef<FormInstance>();

  save = async () => {
    const { contract } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await ContractService.save(Object.assign(contract, data));
    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    const { toolbar } = this.props;
    if (!toolbar) {
      this.props.setHandler?.(this.save);
    }
  }

  render() {
    const { contract, toolbar, readonly } = this.props;

    const props = {
      columns,
      record: contract,
      span: 6,
      readonly,
      isGrid: true,
      strict: true,
      ref: this.formRef,
      style: { padding: '10px 20px', height: '580px', overflow: 'auto' }
    };

    if (!toolbar) {
      return <WrappedForm {...props} mode="normal" />;
    }

    return (
      <>
        <Permission authorities="ROLE_SUPER_ADMIN,ROLE_PROPRIETOR_CONTRACT_MANAGER">
          <Toolbar>
            <Toolbar.Option type="primary" icon={<SaveOutlined />} onClick={this.save}>
              {LocaleUtil.get('save')}
            </Toolbar.Option>
          </Toolbar>
          <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />
        </Permission>
        <WrappedForm {...props} mode="normal" />
      </>
    );
  }
}
