import { message } from 'antd';
import { ResultBean } from '@/service/base/BaseService';
import SystemService from '@/service/system/SystemService';
import http from '../../common/http';
import LocalStorageUtil from '../../util/LocalStorageUtil';
import { API_URL } from '../url';

export declare interface User {
  id?: string;
  username: string;
  password: string;
  name?: string;
}

export declare interface SmsUser {
  mobile: string;
  code: string;
}

export declare interface Bearer {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  token_type: string;
  // eslint-disable-next-line camelcase
  refresh_token: string;
  // eslint-disable-next-line camelcase
  expires_in: number;
  scope: string;
  developer: string;
  code?: number;
  msg?: string;
  data?: number;
  error?: string;
}

export declare interface AuthResult {
  value: string;
  expireAt: string;
  sessionId: string;
  time: string;
  username: string;
  validSeconds: number;
}

export declare interface Authorization {
  // eslint-disable-next-line camelcase
  grant_type?: 'password' | 'authorization_code' | 'client_credentials' | 'refresh_token';
  authResult?: AuthResult;
  accessToken?: string;
  user?: User;
}

const authorization: Authorization = {};

export { authorization };

const cachedAuthInfo = (authResult: AuthResult) => {
  authorization.grant_type = 'password';
  authorization.accessToken = authResult.value;
  authorization.authResult = authResult;
  authorization.user = { username: authResult.username } as any;
  LocalStorageUtil.setItem('authorization', authorization, authResult.validSeconds);
};

class AuthService {
  refresh = async () => {
    const auth = LocalStorageUtil.getItem('authorization');
    if (auth) {
      Object.assign(authorization, auth);
    }

    console.log(authorization);

    const bean = await http.get(`${API_URL}/system/current/user`, {}, {}, true);
    if (bean.code === 200) {
      authorization.user = { username: bean.data.name } as any;
    }
    return bean.code === 200;
  };

  login = async (user: User) => {
    const bean: ResultBean<AuthResult> = await http.postLogin(`${API_URL}/login`, user);
    if (!bean || bean.code === 500) {
      throw new Error(bean.msg);
    }

    cachedAuthInfo(bean.data);
    return bean;
  };

  loginByMobile = async (user: SmsUser) => {
    const bean: ResultBean<AuthResult> = await http.postLoginByMobile(`${API_URL}/sms/login`, user);
    if (!bean || bean.code === 500) {
      throw new Error(bean.msg);
    }

    cachedAuthInfo(bean.data);
    return bean;
  };

  logout = async () => {
    const { code, msg } = await http.get(`${API_URL}/system/logout`, '', {}, false, '');
    if (code !== 200) {
      return message.error(msg);
    }

    message.success(msg);

    setTimeout(() => {
      LocalStorageUtil.removeItem('authorization');
      window.location.href = './';
    }, 1000);
  };
}

export default new AuthService();
