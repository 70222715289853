import React from 'react';
import { CheckOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons/lib';
import LocaleUtil from '@/util/LocaleUtil';
import { Button, message, Upload } from 'antd';
import { UploadProps } from 'antd/es/upload';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import FileUtil from '@/util/FileUtil';
import { ResultBean } from '@/service/base/BaseService';
import { Loading } from '@/component';
import UploadResultList from '@/view/system/uploadResult/List';

declare interface FileUploadProps extends UploadProps {
  getTemplate?: () => void;
  url: string;
  data: Record<string, unknown>;
  multiple?: boolean;
  maxNum?: number;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

declare interface FileUploadState {
  fileList: RcFile[];
}

export default class FileUpload extends React.PureComponent<FileUploadProps, FileUploadState> {
  state = {
    fileList: []
  };

  ref = React.createRef<UploadResultList>();

  beforeUpload = (file: RcFile, fileList: RcFile[]) => {
    const { multiple, maxNum = 10 } = this.props;
    let list: RcFile[];
    if (multiple) {
      list = fileList.length < maxNum ? fileList : fileList.slice(0, maxNum);
    } else {
      list = [file];
    }

    this.setState({ fileList: list });
    return false;
  };

  upload = async () => {
    const { fileList } = this.state;
    if (!fileList || fileList.length === 0) {
      return message.error('未选择要上传的文件');
    }
    const { data, url, onUploadProgress } = this.props;
    const bean = await FileUtil.upload(url, fileList, data, onUploadProgress || this.onUploadProgress);
    if (bean.code === 200) {
      message.success(bean.msg);
      this.ref.current?.reload();
    }
    return bean;
  };

  onUploadProgress = (event: ProgressEvent) => {
    const percent = event.loaded / event.total;
    Loading.setTip(`已上传 ${(percent * 100).toFixed(0).toString()}%`);
  };

  componentDidMount() {
    const { setHandler } = this.props;
    setHandler?.(this.upload);
  }

  render() {
    const { getTemplate, multiple = false, maxNum = 10, ...rest } = this.props;
    const { fileList } = this.state;
    const uploadProps: UploadProps = {
      withCredentials: true,
      multiple,
      beforeUpload: this.beforeUpload,
      fileList,
      onRemove: (file: UploadFile) => {
        const { fileList } = this.state;
        const list = fileList.filter((f: RcFile) => f.uid !== file.uid);
        this.setState({ fileList: list });
        return true;
      }
    };

    const button =
      (multiple && fileList.length < maxNum) || (!multiple && fileList.length < 1) ? (
        <Button icon={<UploadOutlined />} type="primary">
          选择文件
        </Button>
      ) : (
        <></>
      );

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          <Upload {...uploadProps} {...rest}>
            {button}
            {maxNum > 1 && multiple ? (
              <span style={{ marginLeft: '10px' }}>
                当前已选择
                <span style={{ color: 'red' }}>
                  {fileList.length}/{maxNum}
                </span>
                个文件。
              </span>
            ) : (
              <></>
            )}
          </Upload>
        </div>
      </div>
    );
  }
}
