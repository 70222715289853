import React, { MouseEventHandler } from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { config } from '@/service/system/SystemService';
import { SysMenu } from '@/service/system/SysMenuService';
import Locale from '@/component/Locale/Locale';
import LocaleUtil from '@/util/LocaleUtil';
import { UserOutlined } from '@ant-design/icons/lib';
import AuthService, { authorization } from '@/service/system/AuthService';
import Personal from '@/view/system/user/Personal';
import Dialog from '@/component/Dialog/Dialog';
import Password from '@/view/system/user/Password';
import { websiteTheme } from '@/theme';
import UserUtil from '@/util/UserUtil';
import { systemName } from '@/common/common';

export declare interface NavigationProps {
  setMenuList: any;
}

export default class Navigation extends React.PureComponent<NavigationProps, any> {
  href = '#';

  onClick = (target: any) => {
    const { setMenuList } = this.props;
    const menu: SysMenu = config.menuList.filter(t => t.id === target.key)[0];
    setMenuList(menu.children);
  };

  componentDidMount(): void {
    const { setMenuList } = this.props;
    setMenuList(config.menuList[0]?.children);
  }

  personal = () => {
    Dialog.open({
      children: <Personal />,
      width: 1000,
      title: '个人信息'
    });
  };

  password = () => {
    Dialog.open({
      children: <Password />,
      title: '密码修改'
    });
  };

  renderItem(onClick: MouseEventHandler<any>, title: string) {
    return (
      <a onClick={onClick} href={this.href}>
        {title}
      </a>
    );
  }

  render() {
    return (
      <>
        <span
          style={{
            fontSize: '20px',
            color: 'white',
            fontWeight: 'bold',
            marginRight: '40px',
            marginBottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
          }}
        >
          {LocaleUtil.get('title', systemName)}
        </span>
        <Menu
          style={{ flex: 1 }}
          mode="horizontal"
          theme={websiteTheme}
          onClick={this.onClick}
          items={config.menuList.map(({ id, name, code }) => ({ key: id, label: LocaleUtil.get(code, name) }))}
        />
        <div style={{ display: 'flex', justifyItems: 'flex-end', marginRight: '30px' }}>
          <Dropdown
            overlayStyle={{ marginRight: '10px' }}
            menu={{
              items: [
                { key: '1', label: this.renderItem(this.personal, '个人信息') },
                { key: '2', label: this.renderItem(this.password, '修改密码') },
                { key: '3', label: this.renderItem(AuthService.logout, '退出登录') }
              ]
            }}
          >
            <a
              style={{ marginRight: '10px' }}
              className="ant-dropdown-link"
              href={this.href}
              onClick={e => e.preventDefault()}
            >
              <Avatar size={28} style={{ marginRight: '2px' }} icon={<UserOutlined />} />
              {UserUtil.getUser()?.name || ''}
            </a>
          </Dropdown>
          <Locale />
        </div>
      </>
    );
  }
}
