import React from 'react';
import dictService, { SysDict } from '@/service/system/SysDictService';
import { WrappedForm } from '@/component';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import { ResultBean } from '@/service/base/BaseService';
import { castTo } from '@/common/common';
import columns from './columns';

export declare interface DictListProps {
  record: SysDict;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export default class DictForm extends React.PureComponent<DictListProps, any> {
  formRef = React.createRef<FormInstance>();

  save = async () => {
    const { record } = this.props;
    const data = await this.formRef.current?.validateFields();
    const bean = await dictService.save(castTo<SysDict>(Object.assign(record, data)));
    if (bean.code === 200) {
      message.success(bean.msg);
    }

    return bean;
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { record } = this.props;

    const props = {
      columns,
      record,
      span: 12,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
