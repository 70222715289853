import React, { CSSProperties, MouseEvent } from 'react';
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined
} from '@ant-design/icons/lib';

declare interface TitleProps {
  title: string;
  zoom?: (zoomMax: boolean) => void;
  close?: () => void;
  root: HTMLElement;
  zoomMin?: boolean;
  zoomMax?: boolean;
  type?: 'success' | 'warn' | 'error';
  onStyleChange?: (style: CSSProperties) => void;
}

declare interface TitleState {
  max: boolean;
  clientX: number;
  clientY: number;
}

export default class Title extends React.PureComponent<TitleProps, TitleState> {
  state = {
    max: false,
    clientX: 0,
    clientY: 0
  };

  title = React.createRef<HTMLDivElement>();

  max = () => {
    this.setState({ max: true });
    this.props.zoom?.(true);
  };

  normal = () => {
    this.setState({ max: false });
    this.props.zoom?.(false);
  };

  onMouseDown = ({ clientX, clientY }: MouseEvent<HTMLDivElement>) => {
    const modal: HTMLElement = this.props.root.getElementsByClassName('ant-modal-content')?.[0]! as HTMLElement;
    const x = clientX;
    const y = clientY;
    const left = modal.offsetLeft;
    const top = modal.offsetTop;
    modal.style.transform = 'translateZ(0)';

    const moveTo = (cx: number, cy: number) => {
      modal.style.transform = `translateX(${cx - x}px) translateY(${cy - y}px) translateZ(0)`;
    };

    document.onmousemove = ({ clientX, clientY }: any) => moveTo(clientX, clientY);
    document.onmouseup = ({ clientX, clientY }) => {
      modal.style.transform = 'unset';
      modal.style.left = `${clientX - (x - left)}px`;
      modal.style.top = `${clientY - (y - top)}px`;

      document.onmousemove = null;
      document.onmouseup = null;
    };
  };

  getIcon = () => {
    const { type } = this.props;
    const fontSize = '16px';
    const marginRight = '7px';
    const style: CSSProperties = {
      marginRight,
      color: '#52c41a',
      fontSize
    };
    switch (type) {
      case 'success':
        return <CheckCircleOutlined style={style} />;
      case 'warn':
        return <ExclamationCircleOutlined style={{ marginRight, color: '#faad14', fontSize }} />;
      case 'error':
        return <CloseCircleOutlined style={{ marginRight, color: '#ff4d4f', fontSize }} />;
      default:
        return <AppstoreOutlined style={style} />;
    }
  };

  render() {
    const { title, close, zoomMin = true, zoomMax = true } = this.props;
    const { max } = this.state;
    const style = { marginRight: '5px', fontSize: '16px', color: '#666', fontWeight: 100 };
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
        ref={this.title}
        onMouseDown={this.onMouseDown}
      >
        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
          {this.getIcon()}
          {title}
        </div>
        <div>
          {/* {zoomMin && max ? <FullscreenExitOutlined style={style} onClick={this.normal} /> : <></>} */}
          {/* {zoomMax && !max ? <FullscreenOutlined style={style} onClick={this.max} /> : <></>} */}
          <CloseOutlined style={style} onClick={close} />
        </div>
      </div>
    );
  }
}
