import { Project } from '@/service/repository/ProjectService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import OfficeService from '@/service/system/SysOfficeService';

const columns: EditableColumnProps<Project>[] = [
  {
    label: '公司',
    name: 'companyName',
    ellipsis: true,
    editable: false,
    query: false,
    hidden: true,
    width: 200,
    span: 12,
    editType: 'pop-select',
    editOption: {
      prefix: 'company',
      span: 16,
      popup: OfficeService.select
    },
    editRule: { required: true }
  },
  {
    label: '名称',
    code: 'name',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    span: 12,
    editOption: {
      span: 16
    },
    editRule: { maxLength: 100, required: true }
  },
  {
    label: '编号',
    code: 'no',
    name: 'no',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 12,
    editOption: {},
    editRule: { maxLength: 20, required: true }
  },
  {
    label: '设计单位',
    code: 'designer.name',
    name: 'designerName',
    ellipsis: true,
    editable: true,
    width: 200,
    span: 12,
    editType: 'pop-select',
    editOption: {
      prefix: 'designer',
      span: 16,
      popup: () => OfficeService.select({ department: false, name: '设计单位', query: { type: 0 } })
    },
    editRule: { required: false }
  },
  {
    label: '类型',
    code: 'type',
    name: 'type',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 12,
    formatter: 'select',
    formatOption: { type: 'project.type' },
    editType: 'radio',
    editOption: { type: 'project.type' },
    editRule: { maxLength: 20, required: true },
    align: 'center'
  },
  {
    label: '批复部门及文号',
    code: 'approve.no',
    name: 'approveNo',
    ellipsis: true,
    editable: true,
    hidden: true,
    width: 100,
    span: 12,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '概算金额',
    code: 'estimate.amount',
    name: 'estimateAmount',
    ellipsis: true,
    editable: true,
    width: 120,
    span: 12,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    align: 'right',
    editOption: {},
    editRule: { max: 10 ** 13, min: -(10 ** 13) }
  },
  {
    label: '预算金额',
    code: 'budget.amount',
    name: 'budgetAmount',
    ellipsis: true,
    editable: true,
    span: 12,
    width: 120,
    formatter: 'currency',
    formatOption: { zeroEmpty: true },
    editOption: {},
    editRule: { max: 10 ** 13, min: -(10 ** 13) },
    align: 'right'
  },
  {
    label: '开始日期',
    code: 'start.date',
    name: 'date',
    ellipsis: true,
    editable: true,
    span: 12,
    width: 100,
    formatter: 'date',
    editType: 'date',
    editOption: {},
    align: 'center'
  },
  {
    label: '结束日期',
    code: 'end.date',
    name: 'endDate',
    ellipsis: true,
    editable: true,
    span: 12,
    width: 100,
    formatter: 'date',
    editType: 'date',
    editOption: {},
    align: 'center'
  },
  {
    label: '合同工期（天）',
    code: 'contract.days',
    name: 'days',
    ellipsis: true,
    editable: true,
    span: 12,
    width: 100,
    formatter: 'number',
    formatOption: { precision: 0 },
    editOption: {},
    align: 'right'
  },
  {
    label: '投资概算',
    name: 'investEstimate',
    ellipsis: true,
    editable: true,
    width: 100,
    span: 24,
    editType: 'label',
    editOption: { hideLabel: true }
  }
];

export default columns;
