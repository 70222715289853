import { Dictionary } from '@/common/types';
import BaseService, { BaseEntity, BaseQuery, ResultBean } from '../base/BaseService';
import { API_URL } from '../url';
import { TaskExt } from './TaskExtService';

export interface Process extends BaseEntity<Process> {
  name: string;
  key: string;
  description: string;
  category: number;
  type: number;
}

export interface ProcessPermission {
  start: boolean;
  claim: boolean;
  readonly: boolean;
  submit: boolean;
  modify: boolean;
  approve: boolean;
  reject: boolean;
  delegate: boolean;
  rollback: boolean;
  needNextClaimer: boolean;
}

export interface TaskCondition {
  type: number;
  name: string;
  key: string;
  value: string;
  dstTaskDefKey: string;
}

export interface Act {
  task: TaskExt;
  href: string;
  business: BaseEntity<any>;
  historicList: any[];
  historic: Dictionary<TaskExt>;
  conditionList: TaskCondition[];
  permission: ProcessPermission;
}

export declare type ProcessQuery = Process & BaseQuery;

class ProcessService extends BaseService<Process, ProcessQuery> {
  build = (procDefKey: string, contractId?: string): Promise<ResultBean<Act>> => {
    let method = `create?procDefKey=${procDefKey}`;
    if (contractId !== undefined) {
      method += `&contractId=${contractId}`;
    }

    return this.request('get', method);
  };
}

export default new ProcessService(API_URL, 'process/process');
