import React, { ChangeEvent, useCallback, useState } from 'react';
import { Input } from 'antd';
import { WrappedFormItemProps } from '../WrappedFormItem';
import LocaleUtil from '../../../util/LocaleUtil';

declare interface WrappedVerifyCodeProps extends WrappedFormItemProps<any> {
  url: string;
  size?: number;
}

const WrappedVerifyCode: React.FC<WrappedVerifyCodeProps> = (props: WrappedVerifyCodeProps) => {
  const { url, column, record = {}, size = 4, value, onChange } = props;
  const { name, editOption, align, code, label } = column;
  const { itemStyle, hidePlaceHolder } = editOption;
  const [currentURL, setUrl] = useState(url);
  const refresh = useCallback(() => setUrl(`${url}?d=${Math.random()}`), [url]);

  const mapProps = {
    id: record.id ? `${name}_${record.id}` : undefined,
    placeholder: hidePlaceHolder ? undefined : LocaleUtil.get(code, label),
    value,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    style: {
      marginRight: '3px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
      textAlign: align,
      ...itemStyle
    },
    maxLength: size
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Input {...mapProps} />
      <img
        src={currentURL}
        style={{ cursor: 'pointer' }}
        onClick={refresh}
        title="看不清楚，点击刷新"
        alt={currentURL}
      />
    </div>
  );
};

WrappedVerifyCode.defaultProps = {
  size: 4
};

export default WrappedVerifyCode;
