import { EditableColumnProps } from '@/component/Table/EditableTable';
import { SysUserRole } from '@/service/system/SysUserRoleService';

const columns: EditableColumnProps<SysUserRole>[] = [
  {
    label: '角色名称',
    name: 'roleName',
    code: 'roleName',
    ellipsis: true,
    editable: true,
    query: true,
    hidden: false,
    width: 200,
    editOption: {},
    editRule: {}
  },
  {
    label: '用户名称',
    name: 'userName',
    code: 'userName',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: {},
    editRule: { maxLength: 50 }
  }
];

export default columns;
