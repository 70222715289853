import React, { memo, useMemo, useState } from 'react';
import { Cascader } from 'antd';
import { useMount } from 'ahooks';
import { WrappedFormItemProps } from '../WrappedFormItem';
import LocaleUtil from '../../../util/LocaleUtil';

const WrappedCascader = (props: WrappedFormItemProps<any>) => {
  const { value, column, onChange } = props;
  const { code, label, editOption } = column;
  const { getOptions, disabled, hidePlaceHolder } = editOption;
  const [list, setList] = useState<any[]>([]);
  const mapProps = useMemo(
    () => ({
      placeholder: hidePlaceHolder ? undefined : LocaleUtil.get(code, label),
      value: value?.toString()?.split(','),
      onChange: (value: any[] | any) => {
        onChange?.(value instanceof Array ? value?.join(',') : value);
      },
      checkable: true,
      disabled,
      options: list
    }),
    [hidePlaceHolder, code, label, value, onChange, disabled, list]
  );

  useMount(async () => {
    setList((await getOptions?.({})) || []);
  });

  return <Cascader {...mapProps} allowClear />;
};

export default memo(WrappedCascader, ({ value }, nextProps) => value === nextProps.value);
