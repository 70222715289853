import { EditableColumnProps } from '../../Table/EditableTable';
import validator from './index';

export default (column: EditableColumnProps<any>) => {
  const { minLength = -1 } = column.editRule || {};
  const { label } = column;

  return {
    validator: (rule: any, value: string) => {
      const msg = validator.message('minLength', label, minLength);
      return value && value?.length < minLength ? Promise.reject(msg) : Promise.resolve();
    }
  };
};
