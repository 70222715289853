import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import TemplateService, { Template, TemplateQuery } from '@/service/template/TemplateService';
import { Dialog, EditableTable, SearchBar, Toolbar } from '@/component';
import TableUtil from '@/component/Table/Util/TableUtil';
import { ToolbarOptionItem } from '@/component/Toolbar';
import { cloneDeep } from 'lodash-es';
import TemplateForm from './Form';
import columns from './columns';

export declare interface TemplateListState {
  query: Record<string, unknown>;
  record?: Template;
  refresh: boolean;
}

export default class List extends React.PureComponent<any, TemplateListState> {
  tableRef = React.createRef<EditableTable<Template>>();

  add = () => {
    const record = this.tableRef.current?.util.getSelected() || { type: 0 };
    this.edit('配合比新增', { ...record, id: null } as any);
  };

  modify = () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要修改的数据');
    }

    this.edit('模板新增', record);
  };

  edit = (title: string, record: Template) => {
    Dialog.open({
      title,
      children: <TemplateForm record={cloneDeep(record)} />,
      width: 1200,
      styles: { body: { height: '700px', paddingBottom: 0 } },
      afterOk: this.getUtil().refresh
    });
  };

  delete = async () => {
    const record = this.tableRef.current?.util.getSelected();
    if (!record) {
      return message.error('未选中要删除的数据');
    }

    Dialog.confirm('模板删除', '确定要删除数据吗', async () => {
      const bean = await TemplateService.delete(record.id);
      if (bean.code === 200) {
        message.success(bean.msg);
        await this.getUtil().reload();
      }

      return bean;
    });
  };

  getUtil = (): TableUtil<Template> => this.tableRef.current?.util as TableUtil<Template>;

  onQuery = (query: TemplateQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <PlusOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.add,
      code: 'add',
      title: '添加'
    },
    {
      type: 'primary',
      icon: <DeleteOutlined />,
      permission: 'ROLE_SUPER_ADMIN,ROLE_ADMIN',
      onClick: this.delete,
      code: 'delete',
      title: '删除',
      danger: true
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={columns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<Template>
          ref={this.tableRef}
          service={TemplateService}
          autoSave
          columns={columns}
          loadData={TemplateService.findPage}
          onDoubleClick={record => this.edit('模板修改', record)}
          isTree={false}
        />
      </>
    );
  }
}
