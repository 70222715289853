import BaseService, { BaseEntity, BaseQuery } from '../base/BaseService';
import { API_URL } from '../url';

export interface ContractMixDetail extends BaseEntity<ContractMixDetail> {
  contractMixId: string;
  contractMixName: string;
  materialId: string;
  materialName: string;
  unit: string;
  num: number;
  lossFactor1: number;
  lossFactor2: number;
  contractNum: number;
  contractLossFactor1: number;
  contractLossFactor2: number;
}

export declare type ContractMixDetailQuery = Partial<ContractMixDetail & BaseQuery>;

class ContractMixDetailService extends BaseService<ContractMixDetail, ContractMixDetailQuery> {}

export default new ContractMixDetailService(API_URL, 'repository/contract-mix-detail');
