import { SysUser } from '@/service/system/SysUserService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import OfficeService from '@/service/system/SysOfficeService';

const columns: EditableColumnProps<SysUser>[] = [
  {
    label: '公司',
    name: 'companyName',
    code: 'company',
    ellipsis: true,
    editable: true,
    query: true,
    width: 200,
    editType: 'pop-select',
    editOption: {
      prefix: 'company',
      popup: () => OfficeService.select({ department: false, query: { type: 0 } }),
      onChange: (companyId, prevId, ref, record) => {
        if (!companyId || companyId !== prevId) {
          ref.current?.setFieldsValue({ officeId: null, officeName: null });
          delete record.officeId;
          delete record.officeName;
        }
      }
    },
    editRule: { required: true }
  },
  {
    label: '部门',
    name: 'officeName',
    code: 'department',
    ellipsis: true,
    editable: true,
    width: 100,
    editType: 'pop-select',
    editOption: {
      prefix: 'office',
      popup: ({ companyId }: SysUser) => {
        if (!companyId) {
          throw new Error('请先选择公司');
        }
        return OfficeService.select({ department: true, query: { parentIds: companyId, type: 1 } });
      }
    },
    editRule: { required: false }
  },
  {
    label: '登录名',
    name: 'loginName',
    code: 'login.name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 80,
    editOption: {},
    editRule: { required: true, maxLength: 20, minLength: 2 }
  },
  {
    label: '编号',
    name: 'no',
    code: 'no',
    ellipsis: true,
    editable: true,
    width: 60,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '姓名',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 80,
    editOption: {},
    editRule: { maxLength: 20, required: true, minLength: 2 }
  },
  {
    label: '签字',
    name: 'signature',
    code: 'signature',
    hidden: true,
    ellipsis: true,
    editable: false,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '角色',
    name: 'roleName',
    code: 'role.name',
    hidden: false,
    ellipsis: true,
    editable: false,
    width: 300,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '地址',
    name: 'address',
    code: 'address',
    ellipsis: true,
    editable: true,
    width: 100,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '电话',
    name: 'phone',
    code: 'phone',
    ellipsis: true,
    editable: true,
    width: 80,
    editOption: {},
    editRule: { maxLength: 20 }
  },
  {
    label: '手机',
    ellipsis: true,
    editable: true,
    name: 'mobile',
    code: 'mobile',
    editOption: {},
    width: 80
  },
  {
    label: '邮箱',
    ellipsis: true,
    editable: true,
    name: 'email',
    code: 'email',
    editOption: {},
    width: 100
  },
  {
    label: '照片',
    hidden: true,
    ellipsis: true,
    editable: false,
    name: 'photo',
    code: 'photo',
    editOption: {},
    width: 100
  },
  {
    label: '类别',
    ellipsis: true,
    editable: true,
    name: 'type',
    code: 'type',
    formatter: 'select',
    editType: 'checkbox',
    formatOption: { type: 'user.type' },
    editOption: { span: 20, type: 'user.type' },
    align: 'center',
    span: 24,
    width: 160
  },
  {
    label: '状态',
    ellipsis: true,
    editable: true,
    name: 'status',
    code: 'status',
    formatter: 'select',
    editType: 'radio',
    formatOption: { type: 'user.status' },
    editOption: { span: 20, type: 'user.status' },
    editRule: { required: true },
    align: 'center',
    span: 24,
    width: 100
  }
];

export default columns;
