import { SysRole } from '@/service/system/SysRoleService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import OfficeService from '@/service/system/SysOfficeService';
import DictUtil from '../../../util/DictUtil';

const columns: EditableColumnProps<SysRole>[] = [
  {
    label: '公司名称',
    name: 'companyName',
    code: 'company',
    ellipsis: true,
    editable: false,
    query: true,
    hidden: true,
    width: 200,
    editType: 'pop-select',
    editOption: {
      prefix: 'company',
      popup: OfficeService.select
    },
    editRule: {}
  },
  {
    label: '名字',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '英文名称',
    name: 'enName',
    code: 'en.name',
    ellipsis: true,
    editable: true,
    width: 400,
    editOption: {},
    editRule: { maxLength: 50 }
  },
  {
    label: '备注',
    name: 'remarks',
    code: 'comment',
    ellipsis: true,
    editable: true,
    width: 400,
    editType: 'textarea',
    editOption: { itemStyle: { height: '80px', resize: 'none' } },
    editRule: { maxLength: 50 }
  },
  {
    label: '状态',
    ellipsis: true,
    editable: false,
    name: 'status',
    code: 'status',
    render: (value = '') => DictUtil.getByValue('user.status', value),
    editOption: {},
    width: 100
  }
];

export default columns;
