import { SysDict } from '@/service/system/SysDictService';
import { EditableColumnProps } from '@/component/Table/EditableTable';

const columns: EditableColumnProps<SysDict>[] = [
  {
    label: '标签',
    name: 'name',
    code: 'name',
    ellipsis: true,
    editable: true,
    sorter: false,
    query: true,
    span: 12,
    formatOption: { trim: true },
    editOption: { span: 14 },
    editRule: { required: true },
    width: 200
  },
  {
    label: '编码',
    key: 'dict.type',
    name: 'type',
    code: 'type',
    sorter: false,
    ellipsis: true,
    editable: true,
    query: true,
    span: 12,
    formatOption: { trim: true },
    editOption: { span: 14 },
    editRule: { required: true },
    width: 200
  },
  {
    label: '键值',
    name: 'value',
    code: 'value',
    sorter: false,
    ellipsis: true,
    editable: true,
    span: 12,
    editOption: { span: 14 },
    editRule: {},
    width: 60
  },
  {
    label: '国际化编码',
    key: 'dict.code',
    name: 'code',
    code: 'code',
    sorter: false,
    ellipsis: true,
    editable: true,
    query: true,
    hidden: false,
    span: 12,
    formatOption: { trim: true },
    editOption: { span: 14 },
    width: 100
  },

  {
    label: '扩展字段1',
    name: 'external1',
    code: 'external1',
    ellipsis: true,
    editable: true,
    sorter: false,
    span: 12,
    width: 100,
    editOption: { span: 14 }
  },
  {
    label: '扩展字段1描述',
    name: 'external1Desc',
    code: 'external1.desc',
    ellipsis: true,
    editable: true,
    sorter: false,
    span: 12,
    width: 100,
    editOption: { span: 14 }
  },
  {
    label: '扩展字段2',
    name: 'external2',
    code: 'external2',
    ellipsis: true,
    editable: true,
    sorter: false,
    span: 12,
    width: 100,
    editOption: { span: 14 }
  },
  {
    label: '扩展字段2描述',
    name: 'external2Desc',
    code: 'external2.desc',
    ellipsis: true,
    editable: true,
    sorter: false,
    span: 12,
    width: 100,
    editOption: { span: 14 }
  },
  {
    label: '描述',
    name: 'description',
    code: 'description',
    ellipsis: true,
    editable: true,
    sorter: false,
    span: 12,
    width: 100,
    editOption: { span: 14 }
  },
  {
    label: '排序号',
    name: 'sort',
    code: 'sort',
    ellipsis: true,
    editable: true,
    sorter: false,
    span: 12,
    width: 100,
    align: 'left',
    editOption: { span: 14 },
    editRule: {}
  }
];

export default columns;
