import React from 'react';
import { FormInstance } from 'antd/lib/form';
import TaskExtService, { TaskDelegateDTO, TaskExt } from '@/service/process/TaskExtService';
import { WrappedForm } from '@/component';
import { ResultBean } from '@/service/base/BaseService';
import delegateColumns from './model/delegateColumns';

export declare interface TaskExtFormProps {
  task: TaskExt;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => void) => void;
}

export default class Delegate extends React.PureComponent<TaskExtFormProps, any> {
  formRef = React.createRef<FormInstance>();

  save = async () => {
    const {
      task: { taskId }
    } = this.props;
    const data = await this.formRef.current?.validateFields();
    return TaskExtService.delegate({ taskId, ...data } as TaskDelegateDTO);
  };

  componentDidMount(): void {
    this.props.setHandler?.(this.save);
  }

  render() {
    const { task } = this.props;

    const props = {
      columns: delegateColumns,
      record: task,
      span: 24,
      isGrid: true,
      ref: this.formRef,
      style: { padding: '10px 20px' }
    };

    return <WrappedForm {...props} />;
  }
}
