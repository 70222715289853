import TableService, { Table } from '@/service/system/TableService';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import { Dialog } from '@/component';
import SearchTree from '@/component/Tree/SearchTree';
import React from 'react';
import { createGetOptions } from '@/common/common';

const columns: EditableColumnProps<Table>[] = [
  {
    label: '模块名称',
    ellipsis: true,
    editable: true,
    name: 'moduleName',
    formatter: 'text',
    formatOption: {},
    editType: 'text',
    editOption: {},
    align: 'center',
    width: 120,
    editRule: { required: true }
  },
  {
    label: '表名',
    name: 'name',
    ellipsis: true,
    editable: true,
    query: true,
    width: 300,
    editType: 'pop-select',
    editOption: {
      popup: async () =>
        new Promise<any>(resolve => {
          Dialog.open({
            title: '请选择业务表',
            children: <SearchTree type="directory" isFilter loadTree={TableService.tableList} />,
            styles: { body: { height: '600px', padding: 0, overflow: 'hidden' } },
            ok: table => {
              if (!table) {
                throw new Error('没有选择业务表');
              }
            },
            afterOk: bean => resolve(bean?.data)
          });
        }),
      onSelect: (record, data) => {
        if (data && record) {
          record.name = data.name;
        }
      },
      getOptions: createGetOptions('name', 'name')
    },
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '类型',
    ellipsis: true,
    editable: true,
    name: 'type',
    formatter: 'select',
    formatOption: { type: 'table.type' },
    editType: 'select',
    editOption: { type: 'table.type' },
    align: 'center',
    width: 120,
    editRule: { required: true }
  },
  {
    label: '编辑方式',
    ellipsis: true,
    editable: true,
    name: 'editType',
    formatter: 'select',
    formatOption: { type: 'table.edit.type' },
    editType: 'select',
    editOption: { type: 'table.edit.type' },
    align: 'center',
    width: 150,
    editRule: { required: true }
  },
  {
    label: '生成方式',
    ellipsis: true,
    editable: true,
    name: 'genType',
    formatter: 'select',
    formatOption: { type: 'table.gen.type' },
    editType: 'select',
    editOption: { type: 'table.gen.type' },
    align: 'center',
    width: 150,
    editRule: { required: true }
  },
  {
    label: '包名',
    name: 'packageName',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '类名',
    name: 'entityName',
    ellipsis: true,
    editable: true,
    width: 200,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  },
  {
    label: '强制生成',
    name: 'force',
    ellipsis: true,
    editable: true,
    width: 200,
    editType: 'switch',
    editOption: {},
    editRule: {}
  },
  {
    label: '说明',
    ellipsis: true,
    editable: true,
    name: 'comment',
    width: 300,
    editOption: {},
    editRule: { maxLength: 50, required: true }
  }
];

export default columns;
