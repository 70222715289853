import React, { memo, useMemo } from 'react';
import Table from '@/component/Form/component/WrappedTable/Table';
import { EditableColumnProps } from '@/component/Table/EditableTable';
import { WrappedFormItemProps } from '../../WrappedFormItem';

const WrappedTable: React.FC<WrappedFormItemProps<any>> = ({
  column: {
    editOption: { tableOption = {} as any, disabled, setRef }
  },
  value,
  onChange
}: WrappedFormItemProps<any>) => {
  const { columns, ...rest } = tableOption;
  const list = useMemo(
    () =>
      columns.map((column: EditableColumnProps<any>) => {
        if (disabled) {
          column.editOption.disabled = true;
        }

        return column;
      }),
    [columns, disabled]
  );

  const props = {
    value,
    onChange,
    readonly: disabled,
    columns: list,
    ...rest,
    setRef
  } as any;

  return <Table {...props} />;
};

export default memo(WrappedTable, ({ value }, nextProps) => value === nextProps.value);
