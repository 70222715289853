import React from 'react';
import { ResultBean } from '@/service/base/BaseService';
import StockDetailService, { StockDetail, StockDetailQuery } from '../../../service/material/StockDetailService';
import columns from './model/columns';
import { EditableTable, SearchBar } from '../../../component';
import TableUtil from '../../../component/Table/Util/TableUtil';

export declare interface StockDetailListProps {
  onSelect: (stockDetail: StockDetail) => void;
  setHandler?: (ok: () => Promise<ResultBean<any>>, cancel?: () => Promise<ResultBean<any>>) => void;
  onOk?: (record?: StockDetail) => void;
}

export declare interface StockDetailListState {
  query: Record<string, unknown>;
  record?: StockDetail;
  refresh: boolean;
}

export default class StockDetailSelect extends React.PureComponent<StockDetailListProps, StockDetailListState> {
  tableRef = React.createRef<EditableTable<StockDetail>>();

  state = {
    query: {},
    refresh: false
  };

  componentDidMount() {
    this.props.setHandler?.(async (record?: StockDetail) => {
      const data = record || this.getUtil().getSelected()!;
      if (!data) {
        throw new Error('请选择物资');
      }

      this.props.onSelect(data);
      return { code: 200 } as any;
    });
  }

  getUtil = (): TableUtil<StockDetail> => this.tableRef.current?.util as TableUtil<StockDetail>;

  onQuery = (query: StockDetailQuery) => this.getUtil().reload(query);

  render() {
    return (
      <>
        <SearchBar<StockDetail> groupNum={3} formStyle={{ width: '600px' }} columns={columns} onQuery={this.onQuery} />
        <EditableTable<StockDetail>
          ref={this.tableRef}
          service={StockDetailService}
          columns={columns}
          onDoubleClick={this.props.onOk}
          loadData={StockDetailService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
