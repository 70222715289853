import React, { useState } from 'react';
import {
  FilePdfOutlined,
  UploadOutlined,
  FilePptOutlined,
  PlaySquareOutlined,
  FileWordOutlined
} from '@ant-design/icons/lib';
import WrappedUpload, { WrappedUploadProps } from '@/component/Upload/WrappedUpload';
import { API_URL } from '@/service/url';
import { authorization } from '@/service/system/AuthService';
import { WrappedFormItemProps } from '@/component/Form/WrappedFormItem';
import { ResultBean } from '@/service/base/BaseService';
import SysAttachmentService, { Attachment } from '@/service/system/SysAttachmentService';
import Button from '@/component/Button/Button';
import { useMount } from 'ahooks';
import { Avatar, List } from 'antd';

const WrappedImage: React.FC<WrappedFormItemProps<any>> = ({
  value,
  onChange,
  column: {
    editOption: { disabled = false }
  }
}: WrappedFormItemProps<any>) => {
  const [values, setValues] = useState<string[] | undefined>(value?.split(','));
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  useMount(async () => {
    if (values) {
      const bean: ResultBean<Attachment[]> = await SysAttachmentService.findList({ ids: values });
      if (bean.code === 200) {
        setAttachments(bean.data);
      }
    }
  });

  const getIcon = (suffix: string) => {
    switch (suffix.toLowerCase()) {
      case 'pdf':
        return <FilePdfOutlined style={{ color: 'orangered', fontSize: '24px' }} />;
      case 'ppt':
      case 'pptx':
        return <FilePptOutlined style={{ color: 'orangered', fontSize: '24px' }} />;
      case 'doc':
      case 'docx':
        return <FileWordOutlined style={{ color: 'orangered', fontSize: '24px' }} />;
      default:
        return <PlaySquareOutlined style={{ color: 'orangered', fontSize: '24px' }} />;
    }
  };

  if (disabled) {
    return (
      <List
        dataSource={attachments}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<Avatar style={{ background: 'white' }} shape="square" icon={getIcon(item.suffix)} />}
              title={<div>{item.originName}</div>}
              description={
                <span style={{ fontSize: '10px' }}>{`上传时间：${item.createDate} 类型：${item.suffix} 大小：${(
                  item.size /
                  (1024 * 1024)
                ).toFixed(2)}M`}</span>
              }
            />
          </List.Item>
        )}
      />
    );
  }

  const uploadButton = (
    <div>
      <Button type="default" icon={<UploadOutlined />} style={{ marginTop: 8 }}>
        上传
      </Button>
    </div>
  );

  const onFileChange = async (info: any) => {
    if (info.file.status === 'done') {
      const result: ResultBean<Attachment[]> = info.file.response;
      if (result.code === 200) {
        const attachments = result.data;
        const attachmentIds = result.data.map((item: Attachment) => item.id);
        setValues(attachmentIds);
        setAttachments(attachments);
        onChange?.(attachmentIds?.join(','));
      }
    }
  };

  const uploadProps: WrappedUploadProps = {
    action: `${API_URL}/sys-attachment/temp-upload`,
    accept: 'file',
    data: {},
    showUploadList: true,
    withCredentials: true,
    name: 'file',
    maxNum: 1,
    maxCount: 1,
    headers: { xtoken: authorization.accessToken! },
    onChange: onFileChange
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
      <WrappedUpload {...uploadProps}>{disabled ? <></> : uploadButton}</WrappedUpload>
    </div>
  );
};

export default WrappedImage;
