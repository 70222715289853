import React from 'react';
import { CheckOutlined, UserSwitchOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import ProcessUtil from '@/util/ProcessUtil';
import { ToolbarOptionItem } from '@/component/Toolbar';
import Delegate from '@/view/process/task/Delegate';
import TaskExtService, { TaskExt, TaskExtQuery } from '../../../service/process/TaskExtService';
import columns from './model/columns';
import searchColumns from './model/searchColumns';
import { Dialog, EditableTable, SearchBar, Toolbar } from '../../../component';
import TableUtil from '../../../component/Table/Util/TableUtil';

export declare interface TaskExtListProps {}

export declare interface TaskExtListState {
  query: Record<string, unknown>;
  record?: TaskExt;
  refresh: boolean;
}

export default class List extends React.PureComponent<TaskExtListProps, TaskExtListState> {
  tableRef = React.createRef<EditableTable<TaskExt>>();

  state = {
    query: {},
    refresh: false
  };

  claim = () => {
    const record = this.tableRef.current?.util.getSelected()!;
    if (!record) {
      return message.error('未选中要修改的数据');
    }

    Dialog.success('任务签收', `确定要签收流程：${record.procName},任务：${record.taskName} 吗？`, async () => {
      const result = await TaskExtService.claim(record.taskId);
      if (result.code === 200) {
        message.success('任务签收成功');
        await this.getUtil().reload();
      }
      return result;
    });
  };

  delegate = async () => {
    const record = this.tableRef.current?.util.getSelected()!;
    if (!record) {
      return message.error('未选中要委托的任务');
    }

    Dialog.open({
      title: '任务委托',
      children: <Delegate task={record} />,
      width: 600,
      afterOk: this.getUtil().refresh
    });
  };

  getUtil = (): TableUtil<TaskExt> => this.tableRef.current?.util as TableUtil<TaskExt>;

  onQuery = (query: TaskExtQuery) => this.getUtil().reload(query);

  items: ToolbarOptionItem[] = [
    {
      type: 'primary',
      icon: <CheckOutlined />,
      onClick: this.claim,
      code: 'start',
      title: '签收'
    },
    {
      type: 'yellow',
      icon: <UserSwitchOutlined />,
      onClick: this.delegate,
      code: 'delegate',
      title: '委托'
    }
  ];

  render() {
    return (
      <>
        <SearchBar columns={searchColumns} onQuery={this.onQuery} />
        <Toolbar items={this.items} />
        <EditableTable<TaskExt>
          ref={this.tableRef}
          service={TaskExtService}
          autoSave
          columns={columns}
          onDoubleClick={({ businessId, businessNo }) => ProcessUtil.open(businessNo, businessId)}
          loadData={TaskExtService.findPage}
          isTree={false}
        />
      </>
    );
  }
}
